<div class="container">

  <app-normal-corporate-login-bar></app-normal-corporate-login-bar>
  
  <div class="row">
    <div class="col-md-12 col-12 mt-2">
      <ul class="nav nav-tabs custom-tabs" id="myTab" role="tablist" style="border-bottom: 0px;">
        <li class="nav-item">
          <a class="nav-link" href="/upcommingTrip" role="tab">UpComming Trip</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" href="/completedTrip" role="tab">Completed Trip</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/cancelTrip" role="tab">Cancel Trip</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="row mt-2">

    <div *ngFor="let data of this.completedRideData" class="col-md-4 col-12 mb-3">

      <mat-card style="overflow: hidden;">
        <mat-card-title>{{data.displayBookingId}}</mat-card-title>
        <mat-card-subtitle>{{data.tripType}}</mat-card-subtitle>
        <mat-card-content>
          <p>Pickup @ {{data.journeyPickupTime}}</p>
        </mat-card-content>
        <mat-card-actions align="end">
          <button class="btn btn-primary mr-1" (click)="invoiceDialogOpen(data.displayBookingId,'500ms', '500ms')">Invoice</button>
          <button class="btn btn-success" (click)="bookingInfo(data.displayBookingId)">Info</button>
        </mat-card-actions>
      </mat-card>

    </div>

    <div class="col-md-2 col-2">
      <button *ngIf="loadMoreCompleted" class="btn btn-warning" (click)="getCompletedRideFun(this.offset)">Load
        More</button>
    </div>

  </div>

  <ng-template let-data #invoiceDialog>

    <h2 mat-dialog-title>Invoice Request</h2>
    <p>Please enter email address to get invoice.</p>
    <hr>

    <form [formGroup]="invoiceForm">

      <div mat-dialog-content>

        <p>Booking Id : <b>{{this.bookingId}}</b></p>
        <p>Mobile Number : <b>{{this.mobileNumber}}</b></p>

        <mat-form-field class="example-full-width" appearance="fill" style="width: 100%;">

          <mat-label>Email</mat-label>
          <input type="email" matInput formControlName="emailFormControl" placeholder="Ex. pat@example.com">
          <mat-hint>Enter Valid Email Id!</mat-hint>

          <!-- <mat-error *ngIf="invoiceForm.emailFormControl.error">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="invoiceForm.emailFormControl.errors.required">
          Email is <strong>required</strong>
        </mat-error> -->

        </mat-form-field>

      </div>

      <div mat-dialog-actions align="end">
        <button mat-raised-button color="warn" (click)="closeInvoiceDialog()">Close</button>
        <button mat-raised-button color="primary" cdkFocusInitial (click)="webGetInvoiceFun()">Get Invoice</button>
      </div>

    </form>

  </ng-template>

</div>