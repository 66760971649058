import { Component, OnInit, Input, Output, ViewChild, TemplateRef } from '@angular/core';
import { Validators, FormControl, FormBuilder } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';

@Component({
  selector: 'app-completed-trip',
  templateUrl: './completed-trip.component.html',
  styleUrls: ['./completed-trip.component.css']
})
export class CompletedTripComponent implements OnInit {

  apiProperties : APiProperties = new APiProperties();

  constructor( private fb: FormBuilder, 
               private ows: OnewayWebapiService, 
               private titleService: Title,  
               private metaTagService: Meta,
               private dialog: MatDialog) { }

  requestData: any ={};
  corporateUser: any;
  ngOnInit(): void {
    console.log("completed Trip");
    this.corporateUser = sessionStorage.getItem('corporateUser');
    this.getWebAccessKeyFun();
  }

  webAccessKeyRes: any =[];
  webAccessKey: any;
  userName: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      this.userName = this.webAccessKeyRes.userName;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        this.offset = 5;
        this.getCompletedRideFun(0);
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  completedRideData = [];
  completedRideRes: any=[];
  offset: any;
  loadMoreCompleted;
  getCompletedRideFun(offset){

    if(this.corporateUser == '1'){
      this.requestData = {
        userName: this.userName,
        accessKey: this.webAccessKey,
        mobileNo: sessionStorage.getItem('corporateMobileNumber'),
        onlyCorporateBooking : 1,
        offSet: offset //offset value like 0, 5, 10, 15, 25, 30,....
      };
    } else {
      this.requestData = {
        userName: this.userName,
        accessKey: this.webAccessKey,
        mobileNo: sessionStorage.getItem('mobileNo'),
        offSet: offset //offset value like 0, 5, 10, 15, 25, 30,....
      };
    }
    console.log(this.requestData);

    this.ows.getCompletedRide(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.completedRideRes = data;

      if(this.completedRideRes.status == 1){
        this.offset = offset+5;
        console.log(this.offset); 
        for(var i=0; i<this.completedRideRes.bookingList.length; i++){
          this.completedRideData.push(this.completedRideRes.bookingList[i]);
        }
        console.log(this.completedRideData);

        if(this.completedRideRes.bookingList.length < 5){
          this.loadMoreCompleted = false;
        } else {
          this.loadMoreCompleted = true;
        }

      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("completedRideRes error :",error);
    });

  }

  invoiceForm = this.fb.group({
    emailFormControl: ['', [Validators.required, Validators.email]]
  });

  get f(){
    return this.invoiceForm.controls;
  }

  @ViewChild('invoiceDialog') invoiceDialog : TemplateRef<any>;
  invoiceDialogRef: any;

  bookingId: any;
  mobileNumber: any;
  invoiceDialogOpen(bookingId,enterAnimationDuration,exitAnimationDuration){

    this.bookingId = bookingId;

    if(this.corporateUser == '1'){ 
      this.mobileNumber = sessionStorage.getItem('corporateMobileNumber');
    }else{
      this.mobileNumber = sessionStorage.getItem('mobileNo');
    }

    this.invoiceDialogRef = this.dialog.open(this.invoiceDialog,
      { 
        enterAnimationDuration: enterAnimationDuration,
        exitAnimationDuration: exitAnimationDuration
      });
  }

  webGetInvoiceRes: any=[];
  webGetInvoiceFun(){

    this.requestData = {
      userName: this.userName,
      accessKey: this.webAccessKey,
      bookId: this.bookingId,
      consumerNumber: this.mobileNumber,
      emailId: this.invoiceForm.controls['emailFormControl'].value
    };
    console.log(this.requestData);
    //return false;

    return this.ows.webGetInvoice(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webGetInvoiceRes = data;

      if(this.webGetInvoiceRes.status == 1){
        alert(this.webGetInvoiceRes.message);
        this.invoiceDialogRef.close();
      } else {
        alert(this.webGetInvoiceRes.message);
        this.invoiceDialogRef.close();
      }

    },error =>{
      console.log("webGetInvoice error :",error);
    });

  }

  closeInvoiceDialog(){
    this.invoiceDialogRef.close();
  }


  bookingInfo(displayBookingId){
    var encodeBooking = btoa(displayBookingId);
    var bookingInfoURL = this.apiProperties.siteurlPHP+"/tripInfo/"+encodeBooking;
    window.open(bookingInfoURL, '_blank').focus();
  }

}