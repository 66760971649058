import { Component, OnInit, Input, Output } from '@angular/core';
declare var $: any; // Declare the jQuery variable
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { OnewayNodeService } from '../../service/oneway-node.service';
import { GoogleService } from '../../service/google.service';

@Component({
  selector: 'app-trip-faretype',
  templateUrl: './trip-faretype.component.html',
  styleUrls: ['./trip-faretype.component.css']
})
export class TripFaretypeComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();

  constructor(private fb: FormBuilder, 
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private ows: OnewayWebapiService, 
    private ons: OnewayNodeService,
    private gs: GoogleService,
    private titleService: Title,  
    private metaTagService: Meta) { 
        // $('#carTypeModal').modal({
        //   show: false
        // });

        this.generateDateOptions();
    }

  pickupCity: any;
  dropCity: any;
  carType: any;
  pickupDate: any;
  pickupTime: any;
  sessionSelectedTime: any;
  todayDateformatted: any;
  carTypeUpper: any;

  flexiBtn;
  flexiInProgressBtn;
  saverFullBtn;
  saverFullInProgressBtn;
  saverPartBtn;
  saverPartInProgressBtn;
  ngOnInit(): void {
    
    const userId = sessionStorage.getItem('userId');
    if(userId == "" || userId == null || userId == undefined){
      //this.router.navigate(['book/'+this.pickupCity+'/'+this.dropCity+'/'+this.carType]);
      this.router.navigate(['book/ahmedabad/vadodara/hatchback']);
    }

    this.pickupCity = sessionStorage.getItem('pickupCity');
    this.dropCity = sessionStorage.getItem('dropCity');
    this.carType = sessionStorage.getItem('carType');

    this.carTypeUpper = this.carType.toUpperCase();
    this.activeTab = this.carTypeUpper;
    console.log(this.activeTab);

    if(sessionStorage.getItem('pickupDate') == "" || sessionStorage.getItem('pickupDate') == null){
      const today = new Date();
      this.pickupDate = this.formatDate(today);
    } else {
      this.pickupDate = sessionStorage.getItem('pickupDate');
      console.log("else pickupDate :: "+this.pickupDate);
    }
    //console.log("pickupDate :: "+this.pickupDate);

    if(sessionStorage.getItem('pickupTime') == "" || sessionStorage.getItem('pickupTime') == null){  
      this.pickupTime = "";
      //this.sessionSelectedTime = "";
    } else {
      this.pickupTime = sessionStorage.getItem('pickupTime');
      //this.sessionSelectedTime = sessionStorage.getItem('pickupTime');
      console.log("else pickupTime :: "+this.pickupTime);
    }
    //console.log("pickupTime :: "+this.pickupTime);
    
    sessionStorage.setItem('walletChecked','0');
    sessionStorage.setItem('fullWalletPayment','0');
    
    sessionStorage.setItem('baseFare',"");
    sessionStorage.setItem('gst',"");

    this.flexiBtn = true;
    this.flexiInProgressBtn = false;

    this.saverFullBtn = true;
    this.saverFullInProgressBtn = false;

    this.saverPartBtn = true;
    this.saverPartInProgressBtn = false;

    this.getWebAccessKeyFun();
  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){

        this.webOnewayDetailFun(this.pickupDate);
        //this.fetchCarModelDetailsAsPerCarTypeFun();

      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }
  
  items = ['Item 1', 'Item 2', 'Item 3'];
  oneBasedIndices = Array.from({ length: this.items.length }, (_, i) => i + 1);

  activeTab: any;
  changeCabType(tab: string,webOnewayDetailData) {
    //alert(tab);
    this.activeTab = tab;
    this.carType = webOnewayDetailData.CarType;
    sessionStorage.setItem('carType',this.activeTab);
    console.log("Change Cab Type active Tab ::"+this.activeTab);

    // const today = new Date();
    // this.pickupDate = this.formatDate(today);
    // sessionStorage.setItem('pickupTime',"");

    // this.flexiFareBtn = false;
    // this.webOnewayDetailFun(this.pickupDate);
    window.location.reload();
  }

  onDateChange(event: any): void {
    const selectedDate = event.target.value;
    console.log('Selected Date:', selectedDate);

    sessionStorage.setItem('pickupDate',selectedDate);
    sessionStorage.setItem('pickupTime',"");

    this.webOnewayDetailFun(selectedDate);
    // You can perform any actions you need with the selected date here
    // For example, you can emit it to a parent component or invoke other functions.
  }

  saverModalShow(){

    const modal = document.getElementById('saverModal');

    
      modal.classList.toggle('bottom-modal');

      $('#saverModal').modal('show');
    

      // // Get a reference to the div where you want to trigger the modal
      // const openModalDiv = document.getElementById('flexiModal');
      // //alert(openModalDiv);

      // // Add a click event listener to the div to trigger the modal
      // openModalDiv.addEventListener('click', function() {
      //   // Trigger the modal using Bootstrap's JavaScript API
      //   $('#saverModal').modal('show');
      // });
  }

  carTypeBlk: any;
  carTypeId: any;
  routeId: any;
  fareId: any;
  webOnewayDetailRes: any;
  webOnewayDetailFun(bookDate: any){

    //console.log(bookDate);

    this.pickupDate = bookDate;

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      fromCityName : this.pickupCity,
      toCityName : this.dropCity
    };
    //console.log("webOnewayDetail Request :: " +this.requestData);

    this.ows.webOnewayDetail(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webOnewayDetailRes = data;
      //console.log("webOnewayDetail Response :: ", this.webOnewayDetailRes);

      if(this.webOnewayDetailRes.status == 1){
        this.carTypeBlk = true;
        
        var p;
        for(p=0; p<this.webOnewayDetailRes.listofvalue.length; p++){
           if(this.webOnewayDetailRes.listofvalue[p].CarType.toUpperCase() == this.carType.toUpperCase()){
              this.carTypeId = this.webOnewayDetailRes.listofvalue[p].carTypeId;
              this.routeId = this.webOnewayDetailRes.listofvalue[p].routeId;
              this.fareId = this.webOnewayDetailRes.listofvalue[p].fareId;
           }
        }

        this.saverFareTypeFetchFun();
        //this.fetchCarModelDetailsAsPerCarTypeFun();

      } else {
        this.carTypeBlk = false;
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webOnewayDetailRes error :",error);
    });

  }

  minimumAdvanceRequired: any;
  cancellationCharge: any;
  economySaver: any;
  minimumCancellationTime: any;
  saverFareTypeFetchRes: any=[];
  saverFareTypeFetchFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      routeId : this.routeId,
      carTypeId : this.carTypeId
    };
    console.log("saverFareTypeFetch Request ::"+JSON.stringify(this.requestData));

    this.ows.saverFareTypeFetch(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.saverFareTypeFetchRes = data;
      //console.log("saverFareTypeFetch Response ::"+this.saverFareTypeFetchRes);

      if(this.saverFareTypeFetchRes.status == 1){
        //this.carTypeBlk = true;
        //this.fetchCarModelDetailsAsPerCarTypeFun();
        
        this.cancellationCharge = this.saverFareTypeFetchRes.cancellationCharge;
        this.minimumAdvanceRequired = this.saverFareTypeFetchRes.minimumAdvanceRequired;
        this.economySaver = this.saverFareTypeFetchRes.economySaver;
        this.minimumCancellationTime = this.saverFareTypeFetchRes.minimumCancellationTime;

        this.routeWithDynamicTimeFareFun();

      } else {
        //this.carTypeBlk = false;
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("saverFareTypeFetchRes error :",error);
    });

  }

  formattedDateDisplay: any;
  flexiFare: any;
  saverFare: any;
  flexiFareBlk: any;
  minimumAdvanceRequiredDisplay: any;
  maximumCancellationCharge: any;
  dynamicAmount: any;
  routeWithDynamicTimeFareRes: any=[];
  routeWithDynamicTimeFareFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      pickupDate : this.pickupDate,
      routeId : this.routeId,
      cabType: this.carTypeId,
      pickupAddress : sessionStorage.getItem('pickupAddress'),
      pickupCityLat : sessionStorage.getItem('pickupAddressLat'),
      pickupCityLong : sessionStorage.getItem('pickupAddressLong'),
      dropAddress : sessionStorage.getItem('dropAddress'),
      dropCityLat : sessionStorage.getItem('dropAddressLat'),
      dropCityLong : sessionStorage.getItem('dropAddressLong')
    };
    console.log("routeWithDynamicTimeFare Request::"+ JSON.stringify(this.requestData));

    this.ows.routeWithDynamicTimeFare(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.routeWithDynamicTimeFareRes = data;
      //console.log("routeWithDynamicTimeFare Response::"+this.routeWithDynamicTimeFareRes);

      if(this.routeWithDynamicTimeFareRes.status == 1){
        this.flexiFareBlk = true;
        //this.fetchCarModelDetailsAsPerCarTypeFun();
        //console.log(this.routeWithDynamicTimeFareRes.dynamicFareTimeWise);
        console.log("check");
        console.log(sessionStorage.getItem('pickupTime'));

        if(sessionStorage.getItem('pickupTime') == "" || sessionStorage.getItem('pickupTime') == null){
          this.pickupTime = this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[0].time; 
          //this.sessionSelectedTime = this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[0].time;
        } else {
          this.pickupTime = sessionStorage.getItem('pickupTime');
          //this.sessionSelectedTime = sessionStorage.getItem('pickupTime');
        }
        sessionStorage.setItem('pickupTime',this.pickupTime);

        for(var ts=0 ; ts<this.routeWithDynamicTimeFareRes.dynamicFareTimeWise.length; ts++){
           
          //console.log(this.pickupTime);
          //console.log(this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[ts].time);

          if(this.pickupTime == this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[ts].time){
            console.log("if");
          //if(this.sessionSelectedTime == this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[ts].time){
            this.dynamicAmount = this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[ts].dynamicAmount;  
          } 
          // else {
          //   this.dynamicAmount = this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[0].dynamicAmount;
          // }

        }

        console.log(this.pickupTime);

        this.dateTimeFormat();
        this.minimumAdvanceMaximumCancellationCal(this.dynamicAmount);

        // this.minimumAdvanceRequiredDisplay = Math.ceil((this.dynamicAmount * this.minimumAdvanceRequired) / 100);
        // this.maximumCancellationCharge = Math.ceil((this.dynamicAmount * this.cancellationCharge) / 100);
        
        // //this.flexiFare = Math.ceil(this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[0].dynamicAmount);
        // this.flexiFare = Math.ceil(this.dynamicAmount);
        // this.saverFare = Math.ceil((this.dynamicAmount * this.economySaver) / 100);

      } else {
        this.flexiFareBlk = false;
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("routeWithDynamicTimeFareRes error :",error);
    });

  }

  selectedTime: any;
  onTimeChange(event: any): void {
    this.selectedTime = event.target.value;
    console.log('Selected Time:', this.selectedTime);

    if(typeof this.selectedTime === 'undefined'){
      this.pickupTime = this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[0].time;
      //this.sessionSelectedTime = this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[0].time;
    } else {
      this.pickupTime = this.selectedTime;
      //this.sessionSelectedTime = this.selectedTime;
    }

    console.log("onTimeChange pickupTime :: "+this.pickupTime);

    sessionStorage.setItem('pickupTime',this.pickupTime);
    //sessionStorage.setItem('pickupTime',this.sessionSelectedTime);

    for(var ts=0 ; ts<this.routeWithDynamicTimeFareRes.dynamicFareTimeWise.length; ts++){
       
      //console.log(this.pickupTime);
      //console.log(this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[ts].time);

      if(this.pickupTime == this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[ts].time){
      //if(this.sessionSelectedTime == this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[ts].time){
        this.dynamicAmount = this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[ts].dynamicAmount;  
      }

    }

    this.dateTimeFormat();
    this.minimumAdvanceMaximumCancellationCal(this.dynamicAmount);

    // this.minimumAdvanceRequiredDisplay = Math.ceil((this.dynamicAmount * this.minimumAdvanceRequired) / 100);
    // this.maximumCancellationCharge = Math.ceil((this.dynamicAmount * this.cancellationCharge) / 100);
    
    // //this.flexiFare = Math.ceil(this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[0].dynamicAmount);
    // this.flexiFare = Math.ceil(this.dynamicAmount);
    // this.saverFare = Math.ceil((this.dynamicAmount * this.economySaver) / 100);

  }

  beforeData;
  afterData;
  dateTimeFormat(){
    console.log("Date Time :: "+this.pickupDate+" "+this.pickupTime);
    //const dateTimePM =  this.pickupDate+" "+this.sessionSelectedTime;
    const dateTimePM =  this.pickupDate+" "+this.pickupTime;
    const currentDate = new Date(dateTimePM);
    const newDate = new Date(currentDate.getTime() - this.minimumCancellationTime * 60 * 1000);
    this.formattedDateDisplay = this.datePipe.transform(newDate, 'dd MMM - hh:mm a');
    console.log("Date Time Formatted :: "+this.formattedDateDisplay);

    const currentDateNow = new Date();
    console.log(currentDateNow);
    const differenceInMilliseconds = currentDate.getTime() - currentDateNow.getTime();
    const differenceInMinutes = Math.round(differenceInMilliseconds / 60000);

    console.log(differenceInMinutes);

    console.log(this.minimumCancellationTime);

    if(differenceInMinutes < this.minimumCancellationTime){
      this.beforeData = true;
      this.afterData = false;
    } else {
      this.beforeData = false;
      this.afterData = true;
    }

  }
  
  flexiFareBtn;
  minimumAdvanceMaximumCancellationCal(dynamicAmount){
    //console.log("new called");
    this.flexiFareBtn = false;
    this.minimumAdvanceRequiredDisplay = Math.ceil((dynamicAmount * this.minimumAdvanceRequired) / 100);
    this.maximumCancellationCharge = Math.ceil((dynamicAmount * this.cancellationCharge) / 100);
    
    //this.flexiFare = Math.ceil(this.routeWithDynamicTimeFareRes.dynamicFareTimeWise[0].dynamicAmount);
    this.flexiFare = Math.ceil(dynamicAmount);
    this.saverFare = Math.ceil((dynamicAmount * this.economySaver) / 100);
    this.flexiFareBtn = true;
  }

  carTypeDisplay: any;
  carModelDetailsAsPerCarTypeRes: any=[];
  fetchCarModelDetailsAsPerCarTypeFun(carTypeIdVal){

    this.requestData = {
      carTypeId : carTypeIdVal.carTypeId
    };
    //console.log("fetchCarModelDetailsAsPerCarType Request :: " +this.requestData);

    this.ons.fetchCarModelDetailsAsPerCarType(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.carModelDetailsAsPerCarTypeRes = data;
      //console.log("carModelDetailsAsPerCarTypeRes :", this.carModelDetailsAsPerCarTypeRes);

      if(this.carModelDetailsAsPerCarTypeRes.status == 1){
        //this.displayBookingDetails = false;
        $('#carTypeModal').modal('show');
        this.carTypeDisplay = carTypeIdVal.CarType;  
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("carModelDetailsAsPerCarTypeRes error :",error);
    });

  }

  isAssuredCab: any;
  frontDynamicAmount: any;
  dynamicWebBookRideOnewayRes: any=[];
  passengerName: any;
  passengerContactNo: any;
  dynamicWebBookRideOnewayFun(isAssuredCab,amountPaybleNow,flexiSaver){

    if(isAssuredCab == 0){
      this.isAssuredCab = 0;
      this.frontDynamicAmount = this.flexiFare;
    }

    if(isAssuredCab == 1){
      this.isAssuredCab = 1;
      this.frontDynamicAmount = this.saverFare;
    }

    sessionStorage.setItem('fareId',this.fareId);
    sessionStorage.setItem('routeId',this.routeId);
    sessionStorage.setItem('pickupDate',this.pickupDate);
    sessionStorage.setItem('amountPaybleNow',amountPaybleNow);
    sessionStorage.setItem('isAssuredCab',isAssuredCab);
    sessionStorage.setItem('flexiSaver',flexiSaver);

    const alternateName= sessionStorage.getItem('alternateName');
    if(alternateName == null || alternateName == undefined || alternateName == "") {
      this.passengerName = sessionStorage.getItem('passengerName');
    } else {
      this.passengerName = sessionStorage.getItem('alternateName');
    }

    const alternateNumber= sessionStorage.getItem('alternateNumber');
    if(alternateNumber == null || alternateNumber == undefined || alternateNumber == "") {
      this.passengerContactNo = sessionStorage.getItem('mobileNo');
    } else {
      this.passengerContactNo = sessionStorage.getItem('alternateNumber');
    }

    if(flexiSaver == 'flexi'){
      this.flexiBtn = false;
      this.flexiInProgressBtn = true;
    }

    if(flexiSaver == 'saverPart'){
      this.saverPartBtn = false;
      this.saverPartInProgressBtn = true;
    }
    
    if(flexiSaver == 'saverFull'){
      this.saverFullBtn = false;
      this.saverFullInProgressBtn = true;
    }

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      paymentMode : "0",
      booking_from : "3",
      tripType : "1",
      
      fareId : this.fareId,
      routeId : this.routeId,
      carTypeId : this.carTypeId,

      userId : sessionStorage.getItem('userId'),
      passengerName : this.passengerName,
      passengerContactNo : this.passengerContactNo,
      emailId : sessionStorage.getItem('customerEmailId'),

      gstNumber : sessionStorage.getItem('gstNumber'),
      gstCompanyName : sessionStorage.getItem('gstCompanyName'),
      
      bookUtcDate : this.pickupDate,
      bookUtcTime : this.pickupTime,

      pickupAddress : sessionStorage.getItem('pickupAddress'),
      // pickupCityLat : sessionStorage.getItem('pickupCityLat'),
      // pickupCityLong : sessionStorage.getItem('pickupCityLong'),
      pickupCityLat : sessionStorage.getItem('pickupAddressLat'),
      pickupCityLong : sessionStorage.getItem('pickupAddressLong'),

      dropAddress : sessionStorage.getItem('dropAddress'),
      // dropCityLat : sessionStorage.getItem('dropCityLat'),
      // dropCityLong : sessionStorage.getItem('dropCityLong'),
      dropCityLat : sessionStorage.getItem('dropAddressLat'),
      dropCityLong : sessionStorage.getItem('dropAddressLong'),

      clientTimeZone : "Asia",
      couponCode : "",

      paymentType : "0",
      isAssuredCab : this.isAssuredCab,
      frontDynamicAmount : this.frontDynamicAmount,
      
      frontAdvanceAmount: this.minimumAdvanceRequiredDisplay,
      frontCancellationCharge : this.maximumCancellationCharge,

      iswalletused : "0",
      walletAmount : "0"
    };
    console.log(this.requestData);
    //return false;

    this.ows.dynamicWebBookRideOneway(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.dynamicWebBookRideOnewayRes = data;
      console.log("dynamicWebBookRideOneway Response ::"+ JSON.stringify(this.dynamicWebBookRideOnewayRes));
      //return false;

      if(isAssuredCab == 1){
        $('#saverModal').modal('hide');
      }

      if(this.dynamicWebBookRideOnewayRes.status == 1){

        sessionStorage.setItem('bookingId', this.dynamicWebBookRideOnewayRes.bookingId);
        sessionStorage.setItem('displayBookingId', this.dynamicWebBookRideOnewayRes.displayBookingId);

        const jsonString = JSON.stringify(this.dynamicWebBookRideOnewayRes.bookingFareBreakup);
        sessionStorage.setItem('fareBreakup', jsonString);
        
        sessionStorage.setItem('totalAmountPayByCustomer', this.dynamicWebBookRideOnewayRes.totalAmountPayByCustomer);
        // sessionStorage.setItem('finalAmount', this.dynamicWebBookRideOnewayRes.finalAmount);
        // sessionStorage.setItem('gstAmount', this.dynamicWebBookRideOnewayRes.gstAmount);

        sessionStorage.setItem('couponCode','');
        sessionStorage.setItem('discountAmount','');
        sessionStorage.setItem('beforeCouponFinalAmount','');
        
        this.router.navigate(['tripPayment']);

      } else {

        sessionStorage.setItem('bookingId', "");
        sessionStorage.setItem('displayBookingId', "");
        sessionStorage.setItem('fareBreakup', "");

        alert(this.dynamicWebBookRideOnewayRes.message);
        //this.displayBookingDetails = false;
      }

      this.flexiBtn = true;
      this.flexiInProgressBtn = false;

      this.saverPartBtn = true;
      this.saverPartInProgressBtn = false;

      this.saverFullBtn = true;
      this.saverFullInProgressBtn = false;

    },error =>{
      console.log("dynamicWebBookRideOneway Error :: ",error);
    });

  }

  // pageRedirect(){
  //   this.router.navigate(['/tripPayment']);
  // }

  dateOptions: { value: string; label: any }[] = [];
  
  generateDateOptions(): void {
    const today = new Date();
    const threeMonthsAhead = new Date(today);
    threeMonthsAhead.setMonth(today.getMonth() + 3);

    while (today <= threeMonthsAhead) {
      const formattedDate = this.formatDate(today);
      const formattedLabel = this.formatLabel(today);
      this.dateOptions.push({ value: formattedDate, label: formattedLabel });
      today.setDate(today.getDate() + 1);
    }
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  formatLabel(date: Date): string {
    const options = { day: 'numeric', month: 'short', year: 'numeric' }  as Intl.DateTimeFormatOptions;;
    return date.toLocaleDateString(undefined, options);
  }

  // Create a method to open the modal
  // openModal() {
  //   $('#myModal').modal('show');
  // }

}