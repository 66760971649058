import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.css']
})
export class DateSelectorComponent implements OnInit {

  constructor() {
    this.generateDateOptions();
  }

  ngOnInit(): void {}

  dateOptions: { value: string; label: any }[] = [];

  generateDateOptions(): void {
    const today = new Date();
    const threeMonthsAhead = new Date(today);
    threeMonthsAhead.setMonth(today.getMonth() + 3);

    while (today <= threeMonthsAhead) {
      const formattedDate = this.formatDate(today);
      const formattedLabel = this.formatLabel(today);
      this.dateOptions.push({ value: formattedDate, label: formattedLabel });
      today.setDate(today.getDate() + 1);
    }
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  formatLabel(date: Date): string {
    const options = { day: 'numeric', month: 'short', year: 'numeric' }  as Intl.DateTimeFormatOptions;;
    return date.toLocaleDateString(undefined, options);
  }

}