<div class="row">
    
    <div class="col-md-6 col-6 mt-2 mb-0 text-left">
        <h1 class="mb-0">{{this.bookingTitle}}</h1>
        <hr>
    </div>

    <div class="col-md-6 col-6 mt-2 text-right">
        <button *ngIf="normalBookingBtn" class="btn btn-danger btnStyle mr-1" (click)="normalLoginFun()">
            Normal Booking
        </button>

        <!-- <button *ngIf="corporateBookingBtn" class="btn btn-danger btnStyle px-1 mr-1" (click)="corporateLoginFun()">
            Corporate Booking
        </button> -->

        <a href="/userWallet" *ngIf="corporateBookingBtn" class="btn btn-warning btnStyle px-1 mr-1">
            Wallet
        </a>

        <button *ngIf="corporateBookingBtn" class="btn btn-warning btnStyle px-1 mr-1" (click)="logoutUser('normalUser')">
            Logout
        </button>

        <button *ngIf="normalBookingBtn" class="btn btn-warning btnStyle px-1 mr-1" (click)="logoutUser('corporateUser')">
            Logout
        </button>

        <!-- <img src="assets/img/logout.png" *ngIf="corporateBookingBtn" (click)="logoutNormalUser()" style="width: 30px; height: 30px; cursor: pointer;"> -->

    </div>

</div>
