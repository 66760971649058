import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; // Import FormsModule

import { FeedbackFirstRideComponent } from './pages/feedback-first-ride/feedback-first-ride.component';
import { FeedbackInjourneyRideComponent } from './pages/feedback-injourney-ride/feedback-injourney-ride.component';
import { FeedbackCompletedRideComponent } from './pages/feedback-completed-ride/feedback-completed-ride.component';
import { CareerComponent } from './pages/career/career.component';
import { FeedbackCancelRideComponent } from './pages/feedback-cancel-ride/feedback-cancel-ride.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';
import { LoginComponent } from './pages/login/login.component';
//import { UpcommingTripComponent } from './pages/upcomming-trip/upcomming-trip.component';
import { TestComponent } from '../app/pages/test/test.component';
import { TestLoginComponent } from '../app/pages/test-login/test-login.component';
import { TestModalComponent } from '../app/pages/test-modal/test-modal.component';

import { TripDetailComponent } from '../app/pages/trip-detail/trip-detail.component';
import { PickupComponent } from '../app/pages/pickup/pickup.component';
import { DropComponent } from '../app/pages/drop/drop.component';
import { GstComponent } from '../app/pages/gst/gst.component';

import { TripFaretypeComponent } from '../app/pages/trip-faretype/trip-faretype.component';
import { TripPaymentComponent } from '../app/pages/trip-payment/trip-payment.component';
import { TripInfoComponent } from '../app/pages/trip-info/trip-info.component';

import { DateTimeViewComponent } from '../app/pages/date-time-view/date-time-view.component';
import { LoginBackupComponent } from '../app/pages/login-backup/login-backup.component';

import { UpcommingTripComponent } from '../app/pages/upcomming-trip/upcomming-trip.component';
import { CompletedTripComponent } from '../app/pages/completed-trip/completed-trip.component';
import { CancelTripComponent } from '../app/pages/cancel-trip/cancel-trip.component';
import { ModifyTimeComponent } from '../app/pages/modify-time/modify-time.component';

import { CorporateLoginComponent } from '../app/pages/corporate-login/corporate-login.component';
import { VisitorAddressVerifyComponent } from '../app/pages/visitor-address-verify/visitor-address-verify.component';

import { UserWalletComponent } from '../app/pages/user-wallet/user-wallet.component';
import { CouponsComponent } from '../app/pages/coupons/coupons.component';

const routes: Routes = [
  { path: '',   redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'loginBackup', component: LoginBackupComponent },
  { path: 'prefer/:id', component: FeedbackFirstRideComponent },
  { path: 'journey/:id', component: FeedbackInjourneyRideComponent },
  { path: 'feedback/:id', component: FeedbackCompletedRideComponent },
  { path: 'cancelRide/:id', component: FeedbackCancelRideComponent },
  { path: 'deleteAccount', component: DeleteAccountComponent },
  //{ path: 'career', component: CareerComponent },
  //{ path: 'upcommingTrip', component: UpcommingTripComponent },
  { path: 'test', component: TestComponent },
  { path: 'testLogin', component: TestLoginComponent },
  { path: 'testModal', component: TestModalComponent },

  { path: 'book/:pickupCity/:dropCity/:carType', component: TripDetailComponent },
  { path: 'pickup', component: PickupComponent },
  { path: 'drop', component: DropComponent },
  { path: 'gst', component: GstComponent },
  
  { path: 'tripFaretype', component: TripFaretypeComponent },
  { path: 'tripPayment', component: TripPaymentComponent },
  { path: 'tripInfo', component: TripInfoComponent },

  { path: 'dateTimeView', component: DateTimeViewComponent },

  { path: 'upcommingTrip', component: UpcommingTripComponent },
  { path: 'completedTrip', component: CompletedTripComponent },
  { path: 'cancelTrip', component: CancelTripComponent },
  
  { path: 'modifyTime', component: ModifyTimeComponent },

  { path: 'corporateLogin', component: CorporateLoginComponent },
  { path: 'visitorAddressVerify', component: VisitorAddressVerifyComponent },

  { path: 'userWallet', component: UserWalletComponent },
  { path: 'coupons', component: CouponsComponent }
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }