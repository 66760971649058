import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { OnewayPartnerEnrolApiService } from '../../service/oneway-partner-enrol-api.service';
import { GoogleService } from '../../service/google.service';
import { OnewayCustomerAddressService } from '../../service/oneway-customer-address.service';

@Component({
  selector: 'app-visitor-area',
  templateUrl: './visitor-area.component.html',
  styleUrls: ['./visitor-area.component.css']
})
export class VisitorAreaComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @Input() visitorCityId;
  @Input() visitorCityName;
  @Output('visitorAreaHide') visitorAreaHide = new EventEmitter();
  @ViewChild('newAddressInt', { static: false }) newAddressInt: ElementRef;
  //@ViewChild('visitorAreaCmp') visitorAreaCmp: ElementRef;

  constructor( private fb: FormBuilder, 
               private router: Router,
               private route: ActivatedRoute,
               private ows: OnewayWebapiService, 
               private opeas: OnewayPartnerEnrolApiService,
               private gs: GoogleService,
               private ocas: OnewayCustomerAddressService,
               private titleService: Title,  
               private metaTagService: Meta) { }

  placeHolderTitle;             
  ngOnInit(): void {
    //this.placeHolderTitle = "Search Area in "+this.visitorCityName;
    this.placeHolderTitle = "Search Area";
    console.log(this.visitorCityId);
    this.getWebAccessKeyFun();
  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        this.getAddressAreaMaster();
      } else { }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  addressAreaMasterRes: any=[];
  listofAddressDataBlk;
  areaNameList: any;
  noExstingDataBtn;
  getAddressAreaMaster(){

    this.requestData = {
      cityId : this.visitorCityId
    };
    console.log(this.requestData);

    this.ocas.getAddressAreaMaster(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.addressAreaMasterRes = data;
      console.log(this.addressAreaMasterRes);

      if(this.addressAreaMasterRes.status == 1){
        
        console.log(this.addressAreaMasterRes.recordList.length);
        if(this.addressAreaMasterRes.recordList.length > 0){
          //console.log(this.areaNameList);
          this.areaNameList = this.addressAreaMasterRes.recordList;
          this.listofAddressDataBlk = true;
          this.noExstingDataBtn = false;
          this.newAddressInt.nativeElement.focus();
        } else {
          this.listofAddressDataBlk = false;
          this.noExstingDataBtn = true;
        }

      } else {}

    },error =>{
      console.log("addressAreaMasterRes error :",error);
    });

  }

  searchTerm: string = '';
  search() {
    if(this.addressAreaMasterRes.status == 0){
      const searchTermVal = this.searchTerm.toLowerCase();
      //this.pickupAreaSearch(searchTermVal);
      this.listofAddressDataBlk = false;
    } else {

    this.areaNameList = this.addressAreaMasterRes.recordList.filter(item =>
      item.areaName.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
    console.log(this.areaNameList.length);

    if(this.areaNameList.length < 1){
      //console.log(this.areaNameList.length);
      //console.log(this.searchTerm.toLowerCase());
      const searchTermVal = this.searchTerm.toLowerCase();
      //this.pickupAreaSearch(searchTermVal);
      this.listofAddressDataBlk = false;
      this.noExstingDataBtn = true;
    } else {
      this.listofAddressDataBlk = true;
      this.noExstingDataBtn = false;
    }
  
    }  

  }

  saveAreaData(areaData){
    this.visitorAreaHide.emit({
      'areaId' : areaData.id,
      'areaName' : areaData.areaName
    });
  }

  saveNewAreaData(){
    const inputValue = this.newAddressInt.nativeElement.value;
    //console.log('Input Value:', inputValue);

    if(inputValue.length < 3){
      alert("Please enter valid area");
    } else { 
      this.visitorAreaHide.emit({
        'areaId' : '',
        'areaName' : inputValue
      });
    }

  }

  goBack(){
    this.visitorAreaHide.emit({
      'areaId' : '',
      'areaName' : ''
    });
  }

}