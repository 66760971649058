import { Component, OnInit } from '@angular/core';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';

@Component({
  selector: 'app-feedback-injourney-ride',
  templateUrl: './feedback-injourney-ride.component.html',
  styleUrls: ['./feedback-injourney-ride.component.css']
})
export class FeedbackInjourneyRideComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();

  constructor( private ows: OnewayWebapiService) { }

  ijFeedbackBtn;
  encodedBookId: any;
  ngOnInit(): void {

    var hrefURL = window.location.href;
    var hrefURLSplit = hrefURL.split("/journey/");
    this.encodedBookId = hrefURLSplit[1];

    this.getWebAccessKeyFun();
  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKeyRes :", this.webAccessKeyRes.accessKey);

      if(this.webAccessKeyRes.status == 1){
        this.bookingDetailsInjourneyFeedbackFun();
      } else {
        
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  bookingDetailsInjourneyFeedbackRes: any=[];
  fdInJourneyBlk: any;
  fdInJourneyErrorBlk: any;
  fdInJourneyErrorBlkMsg: any;
  bookIdVal: any;
  bookingDetailsInjourneyFeedbackFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      bookId : this.encodedBookId
    };
    //console.log(this.requestData);

    this.ows.bookingDetailsInjourneyFeedback(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.bookingDetailsInjourneyFeedbackRes = data;
      //console.log(this.bookingDetailsInjourneyFeedbackRes);
      
      if(this.bookingDetailsInjourneyFeedbackRes.status == 1){

        this.bookIdVal = this.bookingDetailsInjourneyFeedbackRes.bookId;
        this.fdInJourneyBlk = true;
        
        this.fdInJourneyErrorBlk = false;

        if(this.bookingDetailsInjourneyFeedbackRes.bookingStatus == 4){
          //this.bookingFeedbackInfoFun();
          window.location.href = "feedback/"+this.encodedBookId;
        } 

        if(this.bookingDetailsInjourneyFeedbackRes.bookingStatus == 3){
            this.ijFeedbackBtn = true;
        } else {
          this.ijFeedbackBtn = false;
        }

      } else {
        this.fdInJourneyBlk = false;
        this.ijFeedbackBtn = false;
        this.fdInJourneyErrorBlkMsg = this.bookingDetailsInjourneyFeedbackRes.message;
        this.fdInJourneyErrorBlk = true;
      }

    },error =>{
      console.log("bookingDetailsInjourneyFeedbackRes Error :",error);
    });

  }

  
  isCallbackRequestedInjourneyFeedbackRes: any=[];
  isCallbackRequestedInjourneyFeedbackFun(cbr){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      bookId : this.bookIdVal,
      callbackRequested : cbr
    };
    console.log(this.requestData);

    this.ows.isCallbackRequestedInjourneyFeedback(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.isCallbackRequestedInjourneyFeedbackRes = data;
      console.log(this.isCallbackRequestedInjourneyFeedbackRes);
      
      //this.fdInJourneyErrorBlk = true;
      //this.fdInJourneyErrorBlkMsg = this.isCallbackRequestedInjourneyFeedbackRes.message;
      
      alert(this.isCallbackRequestedInjourneyFeedbackRes.message);

      if(this.isCallbackRequestedInjourneyFeedbackRes.status == 1){
        this.ijFeedbackBtn = false;
      } else {
        this.ijFeedbackBtn = true;
      }

    },error =>{
      console.log("isCallbackRequestedInjourneyFeedbackRes Error :",error);
    });

  }

  //This Function not required on this page
  // bookingFeedbackInfoRes: any=[];
  // bookingFeedbackInfoFun(){

  //   this.requestData = {
  //     userName : this.apiProperties.webCompanyName,
  //     accessKey : this.webAccessKey,
  //     enCodeBookId : this.encodedBookId
  //   };
  //   console.log(this.requestData);

  //   this.ows.bookingFeedbackInfo(JSON.stringify(this.requestData)).subscribe((data : {})=>{
  //     this.bookingFeedbackInfoRes = data;
  //     console.log(this.bookingFeedbackInfoRes);
      
  //     //this.feedbackMessage = this.bookingFeedbackInfoRes.message;

  //     if(this.bookingFeedbackInfoRes.status == 1){
  //       // this.feedbackPending = true;
  //       this.ijFeedbackBtn = false;
  //       //window.location.href = "";

  //     } else {

  //       this.fdInJourneyBlk = true;
  //       this.ijFeedbackBtn = false;
      
  //     }

  //   },error =>{
  //     console.log("bookingFeedbackInfoRes Error :",error);
  //   });

  // }

}