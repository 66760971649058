import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { GoogleService } from '../../service/google.service';

interface City {
  name: string;
  description: string;
}

@Component({
  selector: 'app-drop',
  templateUrl: './drop.component.html',
  styleUrls: ['./drop.component.css']
})
export class DropComponent implements OnInit {
  @Input() webAccessKey;
  @Output('dropHide') dropHide = new EventEmitter();

  apiProperties : APiProperties = new APiProperties();

  constructor( private fb: FormBuilder, 
               private route: ActivatedRoute,
               private ows: OnewayWebapiService, 
               private gs: GoogleService,
               private titleService: Title,  
               private metaTagService: Meta) { }

  ngAfterViewInit() {
    const inputElement = document.querySelector('.search-input') as HTMLInputElement;
    inputElement.setAttribute('autocomplete', 'new-password'); // Use a non-standard value
  }

  pickupCity: any;
  dropCity: any;
  carType: any;
  placeHolderTitle: any;
  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.carType = params['carType'];
    });

    this.pickupCity = sessionStorage.getItem('pickupCity');
    this.dropCity = sessionStorage.getItem('dropCity');

    this.placeHolderTitle = "Drop Address in "+this.dropCity;

    this.getAddressListFun();
  }


  requestData: any={};
  getAddressListRes: any=[];
  listofAddressData: any=[];
  listofAddressDataBlk;
  getAddressListFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      cityName : this.dropCity,
	    mobileNo : sessionStorage.getItem('mobileNo')
    };
    console.log(this.requestData);

    this.ows.getAddressList(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.getAddressListRes = data;
      
      if(this.getAddressListRes.status == 1){
        this.listofAddressData = this.getAddressListRes.listofAddress;
        console.log(this.listofAddressData.length);
        this.listofAddressDataBlk = true;
      } else {}

    },error =>{
      console.log("getAddressListRes error :",error);
    });

  }

  searchResults: City[] = [];
  searchTerm: string = '';
  search() {

    if(this.getAddressListRes.status == 0){ 
      const searchTermVal = this.searchTerm.toLowerCase();
      this.dropAreaSearch(searchTermVal);
      this.listofAddressDataBlk = false;
    } else {

    this.listofAddressData = this.getAddressListRes.listofAddress.filter(item =>
      item.pickupAddress.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
    //console.log(this.listofAddressData.length);

    if(this.listofAddressData.length < 1){
      //console.log(this.listofAddressData.length);
      //console.log(this.searchTerm.toLowerCase());
      const searchTermVal = this.searchTerm.toLowerCase();
      this.dropAreaSearch(searchTermVal);

      this.listofAddressDataBlk = false;
    } else {
      this.listofAddressDataBlk = true;
      this.listofGoogleDataBlk = false;
    }

    }  

  }

  searchText: any;
  googleAutoRes: any=[];
  listofGoogleDataBlk;
  dropAreaSearch(searchTermVal){
    //console.log(x.target.value); 
    //this.searchText = searchText.target.value;

    this.searchText = searchTermVal;
    const location = sessionStorage.getItem('dropCityLat')+","+sessionStorage.getItem('dropCityLong');
    //console.log(location);
    
    return this.gs.googlePlaceAutoComplete(this.searchText,location).subscribe((data : {}) => {
      this.googleAutoRes = data;
      // console.log("areaSearch:"+JSON.stringify(this.googleAutoRes));
      // console.log(this.googleAutoRes.predictions[0].place_id);

      this.listofGoogleDataBlk = true;
      
    },error => console.log(error));

  }

  paceId: any;
  googlePlaceDetailRes: any=[];
  googlePlaceDetailFun(place_id,description,main_text,secondary_text){
    
    this.paceId = place_id;
    
    return this.gs.googlePlaceDetail(this.paceId).subscribe((data : {}) => {
      this.googlePlaceDetailRes = data;
      
      const locationLatLng = this.googlePlaceDetailRes.result.geometry.location;

      this.saveAddressMasterFun(description,main_text,secondary_text,locationLatLng);
      
    },error => console.log(error));

  }

  saveAddressMasterRes: any=[];
  saveAddressMasterFun(description,main_text,secondary_text,locationLatLng){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      cityId : sessionStorage.getItem('dropCityId'),
      userId : sessionStorage.getItem('userId'),
      pickupAddress : description,
      primaryAddress : main_text,
      secondaryAddress : secondary_text,
      pickupCityLat : locationLatLng.lat,
      pickupCityLong : locationLatLng.lng
    };
    console.log(this.requestData);
    //return false;

    this.ows.saveAddressMaster(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.saveAddressMasterRes = data;
      console.log(this.saveAddressMasterRes);
      
      if(this.saveAddressMasterRes.status == 1){
       
        const dropData = {
          'pickupAddress' : description,
          'pickCityLat' : locationLatLng.lat,
          'pickCityLong' : locationLatLng.lng
        }

        this.saveDropData(dropData);
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("saveAddressMasterRes error :",error);
    });

  }

  saveDropData(dropData){
    this.dropHide.emit({
      'dropAddress' : dropData.pickupAddress,
      'dropAddressLat' : dropData.pickCityLat,
      'dropAddressLong' : dropData.pickCityLong
    });
  }

  goBack(){
    this.dropHide.emit({
      'dropAddress' : '',
      'dropAddressLat' : '',
      'dropAddressLong' : ''
    });
  }

}