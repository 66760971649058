import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { OnewayPartnerEnrolApiService } from '../../service/oneway-partner-enrol-api.service';
import { GoogleService } from '../../service/google.service';
import { OnewayCustomerAddressService } from '../../service/oneway-customer-address.service';

@Component({
  selector: 'app-visitor-society',
  templateUrl: './visitor-society.component.html',
  styleUrls: ['./visitor-society.component.css']
})
export class VisitorSocietyComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @Input() visitorAreaId;
  @Output('visitorSocietyHide') visitorSocietyHide = new EventEmitter();
  @ViewChild('newSocietyInt', { static: false }) newSocietyInt: ElementRef;

  constructor( private fb: FormBuilder, 
               private router: Router,
               private route: ActivatedRoute,
               private ows: OnewayWebapiService, 
               private opeas: OnewayPartnerEnrolApiService,
               private gs: GoogleService,
               private ocas: OnewayCustomerAddressService,
               private titleService: Title,  
               private metaTagService: Meta) { }

  placeHolderTitle;
  noDatatoSearch;
  datatoSearch;             
  ngOnInit(): void {
    this.placeHolderTitle = "Search Society";
    this.getWebAccessKeyFun();
  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        if(this.visitorAreaId == "" || this.visitorAreaId == null){
          console.log(this.visitorAreaId);
          this.noDatatoSearch = true;
          this.datatoSearch = false;
          this.listofAddressDataBlk = false;
          this.noExstingDataBtn = true;
        } else {
          this.noDatatoSearch = false;
          this.datatoSearch = true;
          //this.listofAddressDataBlk = true;
          //this.noExstingDataBtn = false;
          this.getSocietyListByAreaId();
        }

        
      } else { }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  societyListByAreaIdRes: any=[];
  listofAddressDataBlk;
  societyNameList: any;
  noExstingDataBtn;
  getSocietyListByAreaId(){

    this.requestData = {
      areaId : this.visitorAreaId
    };
    console.log(this.requestData);

    this.ocas.getSocietyListByAreaId(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.societyListByAreaIdRes = data;
      console.log(this.societyListByAreaIdRes);

      if(this.societyListByAreaIdRes.status == 1){
        
        console.log(this.societyListByAreaIdRes.recordList.length);
        if(this.societyListByAreaIdRes.recordList.length > 0){
          //console.log(this.societyNameList);
          this.societyNameList = this.societyListByAreaIdRes.recordList;
          this.listofAddressDataBlk = true;
          this.noExstingDataBtn = false;
          this.newSocietyInt.nativeElement.focus();
        } else {
          this.listofAddressDataBlk = false;
          this.noExstingDataBtn = true;
        }

      } else {}

    },error =>{
      console.log("societyListByAreaIdRes error :",error);
    });

  }

  searchTerm: string = '';
  search() {
    if(this.societyListByAreaIdRes.status == 0){
      const searchTermVal = this.searchTerm.toLowerCase();
      //this.pickupAreaSearch(searchTermVal);
      this.listofAddressDataBlk = false;
    } else {

    this.societyNameList = this.societyListByAreaIdRes.recordList.filter(item =>
      item.societyName.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
    //console.log(this.societyNameList.length);

    if(this.societyNameList.length < 1){
      //console.log(this.societyNameList.length);
      //console.log(this.searchTerm.toLowerCase());
      const searchTermVal = this.searchTerm.toLowerCase();
      //this.pickupAreaSearch(searchTermVal);
      this.listofAddressDataBlk = false;
      this.noExstingDataBtn = true;
    } else {
      this.listofAddressDataBlk = true;
      this.noExstingDataBtn = false;
    }
  
    }  

  }

  saveSocietyData(societyData){
    this.visitorSocietyHide.emit({
      'societyId' : societyData.societyId,
      'societyName' : societyData.societyName
    });
  }

  saveNewSocietyData(){
    const inputValue = this.newSocietyInt.nativeElement.value;
    console.log('Input Value:', inputValue);

    if(inputValue.length < 3){
      alert("Please enter valid Society Name");
    } else { 
      this.visitorSocietyHide.emit({
        'societyId' : '',
        'societyName' : inputValue
      });
    }

  }

  goBack(){
    this.visitorSocietyHide.emit({
      'societyId' : '',
      'societyName' : ''
    });
  }

}