import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';

@Component({
  selector: 'app-gst',
  templateUrl: './gst.component.html',
  styleUrls: ['./gst.component.css']
})
export class GstComponent implements OnInit {

  apiProperties : APiProperties = new APiProperties();

  @Input() gstDataChild;
  @Output('GSTHide') GSTHide = new EventEmitter();
  
  constructor( private fb: FormBuilder, 
    private ows: OnewayWebapiService) { }

  ngOnInit(): void {
    this.getWebAccessKeyFun();
    console.log("passengerName :: " + sessionStorage.getItem('passengerName'));

    console.log(this.gstDataChild);

  
    this.gstNumberValidator();

    if(this.gstDataChild){
      this.gstForm.controls['gstCompanyName'].setValue(this.gstDataChild.gstCompanyName);
      this.gstForm.controls['gstNumber'].setValue(this.gstDataChild.gstNumber);
    }

  }

  gstForm = this.fb.group({
    gstCompanyName : ["",Validators.required],
    gstNumber : ["",[Validators.required,Validators.maxLength(15),this.gstNumberValidator()]]
  });

  gstNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const gstNumber = control.value;
      const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][0-9][A-Z][0-9]$/;
  
      if(!pattern.test(gstNumber)) {
        if(gstNumber == "" || gstNumber == null){
          return null;  
        } else {
          return { invalidGSTNumber: true };
        }
      }
  
      // GST number format is valid
      return null;
    };
  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        this.getLastTwoGstDetailsFun();
        
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  lastTwoGstDetailsBlk;
  getLastTwoGstDetailsRes: any = [];
  getLastTwoGstDetailsFun(){

    // this.mobileNumberSubmit = false;
    // this.mobileNumberInProcess = true;

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNo : sessionStorage.getItem('mobileNo')
    };
    console.log(this.requestData);
    //return false;

    this.ows.getLastTwoGstDetails(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.getLastTwoGstDetailsRes = data;
      console.log(this.getLastTwoGstDetailsRes);
      
      //this.feedbackMessage = this.cancellationReasonRes.message;
      if(this.getLastTwoGstDetailsRes.status == 1){
        this.lastTwoGstDetailsBlk = true;
      } else {
        
      }

    },error =>{
      console.log("getLastTwoGstDetailsRes Error :",error);
    });

  }

  gstDetailsListBlk;
  getGstDetailsListRes: any=[];
  getGstDetailsListFun(e){

    if(e.target.value.length == 0){
      this.lastTwoGstDetailsBlk = true;
      this.gstForm.controls['gstNumber'].setValue("");
    }

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      gstCompanyName : e.target.value
    };
    console.log(this.requestData);
    //return false;

    this.ows.getGstDetailsList(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.getGstDetailsListRes = data;
      console.log(this.getGstDetailsListRes);
      
      if(this.getGstDetailsListRes.status == 1){ 
          this.gstDetailsListBlk = true;
          this.lastTwoGstDetailsBlk = false;
      } else {
        
      }

    },error =>{
      console.log("getGstDetailsListRes Error :",error);
    });

  }

  gstDataDone: any;
  gstDetailDone(){
    if(this.gstForm.invalid){
      return false;
    }

    this.gstDataDone = {
      gstCompanyName: this.gstForm.controls['gstCompanyName'].value,
      gstNumber: this.gstForm.controls['gstNumber'].value
    }
    
    this.gstDetailSave(this.gstDataDone);
  }

  gstDataStoreTemp: any;
  gstDetailSave(gstData){
    sessionStorage.setItem('gstCompanyName',gstData.gstCompanyName);
    sessionStorage.setItem('gstNumber',gstData.gstNumber);

    this.gstForm.controls['gstCompanyName'].setValue(gstData.gstCompanyName);
    this.gstForm.controls['gstNumber'].setValue(gstData.gstNumber);

    this.goBack(gstData);

  }

  goBack(gstDataPass){
    this.GSTHide.emit(gstDataPass);
  }

}