<div class="container-fluid">

    <div class="container">

        <div class="row mt-3">

            <div class="col-md-6 col-12">

                <div class="row">

                    <div class="col-md-12 col-12">
                        <button class="btn back-button mb-2" (click)="goBack('')" style="padding: 0px;">
                            <img src="../../assets/img/back-arrow.png" style="height: 25px; width: 25px; margin-right: 50px;">
                        </button>
                        Add GST Detail
                    </div>

                    <div class="col-md-12 col-12 mb-2">
                        <div class="card shadow" style="border: none;">
                            <div class="card-body">

                                <form [formGroup]="gstForm">

                                    <div class="form-group">
                                        <input type="text" class="custom-input form-control" formControlName="gstCompanyName" 
                                                id="gstCompanyName" placeholder="GSTIN Holder Name" 
                                                (input)="getGstDetailsListFun($event)" autocomplete="off">
                                    </div>

                                    <div class="form-group">
                                        <input type="text" class="custom-input form-control" formControlName="gstNumber" 
                                               id="gstNumber" placeholder="GST Number">
                                    </div>

                                    <div *ngIf="gstForm.get('gstNumber').hasError('invalidGSTNumber')" class="text-danger form-group">
                                        Invalid GST Number (India)
                                    </div>
                                        
                                </form>

                            </div>

                        </div>
                    </div>

                </div>
                
                <div *ngIf="gstDetailsListBlk" class="row">
                    <div class="col-md-12 col-12">
                        
                        <div *ngFor="let data of getGstDetailsListRes.gstDetailsList" class="card shadow mb-2" 
                              style="border: none; cursor: pointer;"
                              (click)="gstDetailSave(data)">
                            <div class="card-body">
                                <p class="custom-label mb-1"> GST Number : {{data.gstNumber}}</p>
                                <p class="custom-label mb-0"> GSTIN Holder Name : {{data.gstCompanyName}}</p>
                            </div>
                        </div>

                    </div> 
                </div>

                <div *ngIf="lastTwoGstDetailsBlk" class="row">
                    <div class="col-md-12 col-12">
                        
                        <p class="mt-3 text-center" style="color: #098dce!important;">
                            Recent Used GST Detail
                        </p>

                        <div *ngFor="let data of getLastTwoGstDetailsRes.gstDetailsList" class="card shadow mb-2" 
                             style="border: none; cursor: pointer;"
                             (click)="gstDetailSave(data)">
                            <div class="card-body">
                                <p class="custom-label mb-1"> GST Number : {{data.gstNumber}}</p>
                                <p class="custom-label mb-0"> GSTIN Holder Name : {{data.gstCompanyName}}</p>
                            </div>
                        </div>
                    
                    </div> 
                </div>

                <div class="row">
                    <div class="form-group col-md-12 col-12 mb-0 text-right">
                        <button class="btn btn-secondary px-3 mr-2">Clear</button>
                        <button type="submit" class="btn btn-warning px-3" (click)="gstDetailDone()">Done</button>
                        <!-- <button type="submit" class="btn btn-warning py-3 px-5" style="border-radius: 50px;">Done</button> -->
                    </div>
                </div>

            </div>

        </div>

    </div>

</div>