import { Component, OnInit, Input, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { OnewayPartnerEnrolApiService } from '../../service/oneway-partner-enrol-api.service';
import { GoogleService } from '../../service/google.service';

@Component({
  selector: 'app-trip-detail',
  templateUrl: './trip-detail.component.html',
  styleUrls: ['./trip-detail.component.css']
})
export class TripDetailComponent implements OnInit {

  apiProperties : APiProperties = new APiProperties();

  constructor( private fb: FormBuilder, 
               private router: Router,
               private route: ActivatedRoute,
               private ows: OnewayWebapiService, 
               private opeas: OnewayPartnerEnrolApiService,
               private gs: GoogleService,
               private titleService: Title,  
               private metaTagService: Meta) { }


  tripDetail;
  otpVerificationCompleted;
  otpVerificationPending;
  inputName;
  inputMobile;
  isDisabled = true; // Declare isDisabled as a boolean variable
  isGSTboxEnabled;
  pickupCity: any;
  dropCity: any;
  carType: any;
  alternateBlk;
  ngOnInit(): void {
    this.getWebAccessKeyFun();
    this.getCountryFlagListFun();

    // sessionStorage.setItem('passengerName','Test51');
    // sessionStorage.setItem('mobileNo','3216549873');
    // sessionStorage.setItem('userId','103940');

    this.tripDetail = true;
    
    const userId = sessionStorage.getItem('userId');
    if(userId){

      this.otpVerificationCompleted = true;
      this.otpVerificationPending = false;
      this.isGSTboxEnabled = false;

      this.tripDetailForm.get('passengerName').disable();
      this.tripDetailForm.get('passengerContactNo').disable();
      //this.tripDetailForm.get('gstNumber').disable();

      this.tripDetailForm.controls['passengerName'].setValue(sessionStorage.getItem('passengerName'));
      this.tripDetailForm.controls['passengerContactNo'].setValue(sessionStorage.getItem('mobileNo'));

      this.tripDetailForm.controls['gstNumber'].setValue(sessionStorage.getItem('gstNumber'));
      //this.tripDetailForm.controls['gstCompanyName'].setValue(sessionStorage.getItem('gstCompanyName'));

      if(sessionStorage.getItem('gstNumber') != ""){
        this.gstDataChild = {
          gstNumber: sessionStorage.getItem('gstNumber'),
          gstCompanyName: sessionStorage.getItem('gstCompanyName')
        };
      } else {
        this.gstDataChild = {};
      } 

      if(sessionStorage.getItem('dropAddress') != ""){
        this.tripDetailForm.controls['dropAddress'].setValue(sessionStorage.getItem('dropAddress'));
      }

      if(sessionStorage.getItem('pickupAddress') != ""){
        this.tripDetailForm.controls['pickupAddress'].setValue(sessionStorage.getItem('pickupAddress'));
      }

      if(sessionStorage.getItem('alternateName') !=""){
        this.alternateBlk = true;
        this.tripDetailForm.controls['alternateName'].setValue(sessionStorage.getItem('alternateName'));
      }

      if(sessionStorage.getItem('alternateNumber') !=""){
        this.alternateBlk = true;
        this.tripDetailForm.controls['alternateNumber'].setValue(sessionStorage.getItem('alternateNumber'));
      }

      if(this.tripDetailForm.controls['alternateName'].value =="" || this.tripDetailForm.controls['alternateName'].value ==null){
        this.alternateBlk = false;
      }

      if(this.tripDetailForm.controls['alternateNumber'].value =="" || this.tripDetailForm.controls['alternateNumber'].value ==null){
        this.alternateBlk = false;
      }
      
    } else {
      this.otpVerificationCompleted = false;
      this.otpVerificationPending = true;
      this.isGSTboxEnabled = false;

      this.tripDetailForm.get('passengerName').enable();
      this.tripDetailForm.get('passengerContactNo').enable();

      this.tripDetailForm.get('pickupAddress').disable();
      this.tripDetailForm.get('dropAddress').disable();
      this.tripDetailForm.get('gstNumber').disable();
    }
       
    this.route.params.subscribe(params => {
      this.pickupCity = params['pickupCity'];
      this.dropCity = params['dropCity'];
      this.carType = params['carType'];
    });

    console.log("pickupCity ::"+this.pickupCity);
    console.log("dropCity ::"+this.dropCity);
    
    sessionStorage.setItem('pickupCity', this.pickupCity);      
    sessionStorage.setItem('dropCity', this.dropCity);
    sessionStorage.setItem('carType', this.carType);
    
    this.loginFormBlk = true;
    this.otpFormBlk = false;
    this.mobileNumberSubmit = true;
    this.mobileNumberInProcess = false;
     
    //console.log("Pickup City :: "+this.pickupCity);
  }

  tripDetailForm = this.fb.group({
    passengerName : [{value:"",disabled: true}],
    passengerContactNo : [{value:"",disabled: true}],
    pickupAddress : [{value:"",disabled: false}],
    pickupCityLat : [""],
    pickupCityLong : [""],
    dropAddress : [{value:"",disabled: false}],
    dropCityLat : [""],
    dropCityLong : [""],
    gstNumber : [{ value: '', disabled: false }],
    alternateName : [{ value: '', disabled: true }],
    alternateNumber : [{ value: '', disabled: true }]
  });

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  pickupCityResult: any;
  dropCityResult: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        //this.getCancellationReasonFun();

        this.readNotes();

        const pickupCity = this.pickupCity;
        const pickupCityResult = this.removeHyphensAndTitleCase(pickupCity);
        this.pickupCityResult = this.removeHyphensAndTitleCase(pickupCity);
        console.log(pickupCityResult); 

        const dropCity = this.dropCity;
        const dropCityResult = this.removeHyphensAndTitleCase(dropCity);
        this.dropCityResult = this.removeHyphensAndTitleCase(dropCity);
        console.log(dropCityResult);

        sessionStorage.setItem('pickupCity', pickupCityResult);      
        sessionStorage.setItem('dropCity', dropCityResult);

        this.getPickupCityListPriorityFun(pickupCityResult);
        this.getDropCityListNewFun(pickupCityResult,dropCityResult);

      } else { }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  readNotesRes: any =[];
  onewayInclude: any;
  onewayExclude: any;
  onewayTC: any;
  readNotes(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey
    };
    console.log(this.requestData);

    this.ows.readNotes(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.readNotesRes = data;
      console.log(this.readNotesRes);

      this.onewayInclude = this.readNotesRes.onewayInclude;
      this.onewayExclude = this.readNotesRes.onewayExclude;
      this.onewayTC = this.readNotesRes.onewayTC;
      console.log(this.onewayInclude);
        
    },error =>{
      console.log("readNotesRes error :",error);
    });

  }

  countryFlagListRes: any=[];
  countryFlagList;
  getCountryFlagListFun(){

    this.opeas.getCountryFlagList().subscribe((data : {})=>{
      this.countryFlagListRes = data;
      if(this.countryFlagListRes.status == 1){
        console.log(this.countryFlagListRes);
        //this.selectedCountry = this.countryFlagListRes.listOfCountry.find(country => country.countryCode === '91');
        this.selectedCountry = this.countryFlagListRes.listOfCountry[0];
        //console.log(this.selectedCountry);
        this.countryFlagList = true;
      } else { }

    },error =>{
      console.log("countryFlagListRes error :",error);
    });

  }

  //new added code
  countryListOpen = false; // Set it to false initially
  selectedCountry: any;

  toggleCountryList() {
    this.countryListOpen = !this.countryListOpen;
  }

  selectCountry(country: any) {
    this.selectedCountry = country;
    //console.log(this.selectedCountry);
    //this.countryListOpen = false;
  }
  //end new added code

  removeHyphensAndTitleCase(inputString) {
    // Split the string into words based on hyphens
    const words = inputString.split('-');
    
    // Capitalize the first letter of each word and join them with spaces
    const titleCaseString = words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  
    return titleCaseString;
  }

  pickupCityListPriorityRes: any=[];
  getPickupCityListPriorityFun(pickupCityResult){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
    };
    //console.log(this.requestData);

    this.ows.getPickupCityListPriority(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.pickupCityListPriorityRes = data;
      //console.log(this.pickupCityListPriorityRes);

      if(this.pickupCityListPriorityRes.status == 1){
        //console.log(this.pickupCityListPriorityRes["City Name"].length);
        
        for(var i=0; i<this.pickupCityListPriorityRes["City Name"].length;i++){
          
          if(pickupCityResult == this.pickupCityListPriorityRes["City Name"][i].cityName){

            //console.log(this.pickupCityListPriorityRes["City Name"][i].cityLatitude);

            const pickupCityId = this.pickupCityListPriorityRes["City Name"][i].cityId;
            const pickupCityLat = this.pickupCityListPriorityRes["City Name"][i].cityLatitude;
            const pickupCityLong = this.pickupCityListPriorityRes["City Name"][i].cityLongitude;

            sessionStorage.setItem('pickupCityId',pickupCityId);
            sessionStorage.setItem('pickupCityLat',pickupCityLat);
            sessionStorage.setItem('pickupCityLong',pickupCityLong);
          
          }
        }

      } else {}

    },error =>{
      console.log("pickupCityListPriorityRes error :",error);
    });

  }

  dropCityListNewRes: any=[];
  getDropCityListNewFun(pickupCityResult,dropCityResult){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      cityName : pickupCityResult
    };
    console.log(this.requestData);
    //return false;

    this.ows.getDropCityListNew(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.dropCityListNewRes = data;
      console.log(this.dropCityListNewRes);
      //return false;

      if(this.dropCityListNewRes.status == 1){

        //console.log("if");

        for(var i=0; i<this.dropCityListNewRes["City Name"].length;i++){

          //console.log("for");
          
          if(dropCityResult == this.dropCityListNewRes["City Name"][i].cityName){

            //console.log(this.dropCityListNewRes["City Name"][i].cityId);

            const dropCityId = this.dropCityListNewRes["City Name"][i].cityId;
            const dropCityLat = this.dropCityListNewRes["City Name"][i].cityLatitude;
            const dropCityLong = this.dropCityListNewRes["City Name"][i].cityLongitude;

            sessionStorage.setItem('dropCityId',dropCityId);
            sessionStorage.setItem('dropCityLat',dropCityLat);
            sessionStorage.setItem('dropCityLong',dropCityLong);
          
          } 
        }

        
        
      } else {}

      

    },error =>{
      console.log("dropCityListNewRes error :",error);
    });

  }

  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  isEmailInvalid: boolean = false;
  validateEmail(event: any) {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Check if the input matches the email pattern
    this.isEmailInvalid = !emailPattern.test(event.target.value);
  }

  loginForm = this.fb.group({
    passengerName: ["",Validators.required],
    mobileNumber: ["",[Validators.required,Validators.minLength(10),Validators.maxLength(10),Validators.pattern("^[0-9]*$")]],
    email: ["",Validators.required],
  });

  get f() { return this.loginForm.controls; }

  submitted = false;

  loginFormBlk;
  otpFormBlk;
  mobileNumberSubmit;
  mobileNumberInProcess;
  mobileNumberDisplay: any;
  loginRegistrationRes: any=[];
  hiddenInputValue: any;
  finalMobileNumber: any;
  finalMobileNumberPlus: any;
  getLoginRegistrationFun(){

    // if(this.loginForm.invalid){
    //   alert("Invalid Login Form");
    //   return false;
    // }

    this.submitted = true;

    // stop here if form is invalid
    if(this.loginForm.invalid) {
        return;
    }

    this.mobileNumberSubmit = false;
    this.mobileNumberInProcess = true;

    const hiddenInput = document.getElementById('countryCodeVal') as HTMLInputElement | null;
    // Check if the element exists
    if(hiddenInput) {
      this.hiddenInputValue = hiddenInput.value;
      
    } else {
      alert("Please select country code");
      return false;
    }

    this.finalMobileNumber = this.hiddenInputValue+this.loginForm.controls['mobileNumber'].value;
    this.finalMobileNumberPlus = "+"+this.hiddenInputValue+this.loginForm.controls['mobileNumber'].value;
    
    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNo : this.loginForm.controls['mobileNumber'].value,
      countryCode : this.hiddenInputValue,
      //mobileNo : this.finalMobileNumberPlus,
      passengerName : this.loginForm.controls['passengerName'].value,
      email : this.loginForm.controls['email'].value
    };
    console.log(this.requestData);
    //return false;

    this.ows.getLoginRegistration(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.loginRegistrationRes = data;
      //console.log(this.loginRegistrationRes);      
      //this.feedbackMessage = this.cancellationReasonRes.message;

      if(this.loginRegistrationRes.status == 1){
        this.mobileNumberDisplay = this.loginForm.controls['mobileNumber'].value;
        this.mobileNumberSubmit = false;
        this.mobileNumberInProcess = false;

        this.loginFormBlk = false;
        this.otpFormBlk = true;

      } else {
        alert(this.loginRegistrationRes.message);
        this.mobileNumberSubmit = true;
        this.mobileNumberInProcess = false;

        this.loginFormBlk = true;
        this.otpFormBlk = false;
      }

    },error =>{
      console.log("loginRegistrationRes Error :",error);
    });

  }

  otpForm = this.fb.group({
    otpNumber: ["",[Validators.required]],
  });

  mobileNo: any;
  otpVerificationRes: any = [];
  webOTPVerificationFun(){

    if(this.otpForm.invalid){
      return false;
    }

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNo : this.loginForm.controls['mobileNumber'].value,
      //mobileNo : this.finalMobileNumberPlus,
      otp : this.otpForm.controls['otpNumber'].value,
      loginFrom : ""
    };
    console.log(this.requestData);

    this.ows.webOTPVerification(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.otpVerificationRes = data;
      console.log(this.otpVerificationRes);

      if('status' in this.otpVerificationRes) {
        //console.log('Status key is available');

        if(this.otpVerificationRes.userId != ""){
          this.mobileNo = this.loginForm.controls['mobileNumber'].value;
          sessionStorage.setItem('mobileNo', this.loginForm.controls['mobileNumber'].value);
          //sessionStorage.setItem('mobileNo', this.finalMobileNumber);
          //sessionStorage.setItem('mobileNo', this.finalMobileNumberPlus);
          sessionStorage.setItem('userId', this.otpVerificationRes.userId);
          sessionStorage.setItem('customerEmailId', this.otpVerificationRes.customerEmailId);
          sessionStorage.setItem('passengerName', this.otpVerificationRes.passangerName);
          sessionStorage.setItem('countryCode', this.hiddenInputValue);
  
          this.getUserWalletAmountFun(this.otpVerificationRes);
  
          this.addUpdateWebUserFun(this.otpVerificationRes, this.loginForm.controls['mobileNumber'].value,this.hiddenInputValue);
          //this.addUpdateWebUserFun(this.otpVerificationRes, this.finalMobileNumber);
          //this.addUpdateWebUserFun(this.otpVerificationRes, this.finalMobileNumberPlus);
          
        }

      } else {
        //console.log('Status key is not available');
        sessionStorage.setItem('mobileNo', "");
        sessionStorage.setItem('userId', "");
        sessionStorage.setItem('customerEmailId', "");
        sessionStorage.setItem('passengerName', "");

        
        alert(this.otpVerificationRes.message);
        return;
      }

      //return;
      //this.feedbackMessage = this.cancellationReasonRes.message;

      window.location.reload();

    },error =>{
      console.log("otpVerificationRes Error :",error);
    });

  }

  addUpdateWebUserRes: any=[];
  addUpdateWebUserFun(otpVerificationRes, mobileNumber, hiddenInputValue){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNumber : mobileNumber,
      countryCode : hiddenInputValue,
      customerName : otpVerificationRes.passangerName,
      emailId : otpVerificationRes.customerEmailId
    };
    console.log(this.requestData);

    this.ows.addUpdateWebUser(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.addUpdateWebUserRes = data;
      console.log("addUpdateWebUser :: "+JSON.stringify(this.addUpdateWebUserRes));
      //return false;
      //this.Message = this.addUpdateWebUserRes.message;
      // if(this.addUpdateWebUserRes.status == 1){} 
      // else {}

      window.location.reload();

    },error =>{
      console.log("addUpdateWebUserRes Error :",error);
    });

  }

  resendOTPRes: any=[];
  resendOTPFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNo : this.loginForm.controls['mobileNumber'].value,
      //mobileNo : this.finalMobileNumberPlus,
    };
    console.log(this.requestData);

    this.ows.resendOTP(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.resendOTPRes = data;
      console.log("resendOTP :: "+JSON.stringify(this.resendOTPRes));
      //this.Message = this.resendOTPRes.message;

      alert(this.resendOTPRes.message);

      // if(this.resendOTPRes.status == 0){
      // } else {}

    },error =>{
      console.log("resendOTPRes Error :",error);
    });

  }

  userWalletAmountRes: any=[];
  getUserWalletAmountFun(otpVerificationRes){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      userId : otpVerificationRes.userId
    };
    console.log(this.requestData);

    this.ows.getUserWalletAmount(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.userWalletAmountRes = data;
      console.log("userWalletAmountRes :: "+JSON.stringify(this.userWalletAmountRes));
      //this.Message = this.userWalletAmountRes.message;

      if(this.userWalletAmountRes.status == 1){
        sessionStorage.setItem('userWalletAmount',this.userWalletAmountRes.wallteAmount);
      }
      // else {}

    },error =>{
      console.log("userWalletAmountRes Error :",error);
    });

  }

  pageRedirect(){
      this.router.navigate(['/tripFaretype']);
  }

  placeValidWebRes: any=[];
  isPlaceValidWeb(cityName,latitude,longitude,addressOf){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      cityName : cityName,
      latitude : latitude,
      longitude : longitude
    };
    console.log(this.requestData);

    this.ows.isPlaceValidWeb(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.placeValidWebRes = data;
      console.log(this.placeValidWebRes);
      //this.Message = this.resendOTPRes.message;
      //alert(this.placeValidWebRes.message);
      if(this.placeValidWebRes.hasOwnProperty('status')) {
        console.log('Status key is present in the JSON response');
        
        if(this.placeValidWebRes.status == 2){
            if(addressOf == 'pickup'){
              if(this.placeValidWebRes.hasOwnProperty('cityName')){
                const cityName = this.placeValidWebRes.cityName.toLowerCase();
                const urlRedirect = '/book/'+cityName+'/'+this.dropCityResult.toLowerCase()+'/'+this.carType;
                //alert(urlRedirect);
                sessionStorage.setItem('pickupCity',cityName);
                this.router.navigate([urlRedirect]).then(() => {
                  // Reload the page after navigation
                  window.location.reload();
                });
              }
            }

            if(addressOf == 'drop'){
              if(this.placeValidWebRes.hasOwnProperty('cityName')){
                const cityName = this.placeValidWebRes.cityName.toLowerCase();
                const urlRedirect = '/book/'+this.pickupCityResult.toLowerCase()+'/'+cityName+'/'+this.carType;
                //alert(urlRedirect);
                sessionStorage.setItem('dropCity',cityName);
                this.router.navigate([urlRedirect]).then(() => {
                  // Reload the page after navigation
                  window.location.reload();
                });
              }
            }
            
        } 

        if(this.placeValidWebRes.status == 0){
            alert(this.placeValidWebRes.dataMessage);
            if(addressOf == 'pickup'){
              this.tripDetailForm.controls['pickupAddress'].setValue("");
              this.tripDetailForm.controls['pickupCityLat'].setValue("");
              this.tripDetailForm.controls['pickupCityLong'].setValue("");
            }

            if(addressOf == 'drop'){
              this.tripDetailForm.controls['dropAddress'].setValue("");
              this.tripDetailForm.controls['dropCityLat'].setValue("");
              this.tripDetailForm.controls['dropCityLong'].setValue("");
            }
            return;
        }

        if(this.placeValidWebRes.status == 10){
          alert(this.placeValidWebRes.dataMessage);
          if(addressOf == 'pickup'){
            this.tripDetailForm.controls['pickupAddress'].setValue("");
            this.tripDetailForm.controls['pickupCityLat'].setValue("");
            this.tripDetailForm.controls['pickupCityLong'].setValue("");
          }

          if(addressOf == 'drop'){
            this.tripDetailForm.controls['dropAddress'].setValue("");
            this.tripDetailForm.controls['dropCityLat'].setValue("");
            this.tripDetailForm.controls['dropCityLong'].setValue("");
          }
          return;
        }
        
        //else {}

      } else {
        console.log('Status key is not present in the JSON response');
      }

    },error =>{
      console.log("placeValidWebRes Error :",error);
    });

  }

  pickup;
  pickupShow(){
    this.pickup = true;
    this.tripDetail = false;
  }

  pickupHide(pickupData){
    this.pickup = false;
    this.tripDetail = true;

    const keyToCheck = 'pickupAddress';

    //alert(typeof pickupData.pickupAddress);

    if(pickupData.hasOwnProperty(keyToCheck) && pickupData[keyToCheck] !== null && pickupData[keyToCheck] !== undefined && pickupData[keyToCheck] !== "") {
     
      this.tripDetailForm.controls['pickupAddress'].setValue(pickupData.pickupAddress);
      //this.tripDetailForm.controls['pickupCityLat'].setValue(pickupData.pickupAddressLat);
      //this.tripDetailForm.controls['pickupCityLong'].setValue(pickupData.pickupAddressLong);

      //alert("if");
      sessionStorage.setItem('pickupAddress',pickupData.pickupAddress);
      sessionStorage.setItem('pickupAddressLat',pickupData.pickupAddressLat);
      sessionStorage.setItem('pickupAddressLong',pickupData.pickupAddressLong);

      this.isPlaceValidWeb(this.pickupCityResult,pickupData.pickupAddressLat,pickupData.pickupAddressLong,"pickup");
      //this.isPlaceValidWeb(this.pickupCityResult,"23.026","72.600","pickup"); // Case 1
      //this.isPlaceValidWeb(this.pickupCityResult,"18.62618021944617","73.748","pickup"); // Case 2
      //this.isPlaceValidWeb(this.pickupCityResult,"23.012","72.522","pickup"); // Case 2
      //this.isPlaceValidWeb(this.pickupCityResult,"23.0117711","72.52322749999999","pickup"); // Case 3
    } 

  }

  drop;
  dropShow(){
    this.drop = true;
    this.tripDetail = false;
  }

  dropHide(dropData){
    this.drop = false;
    this.tripDetail = true;

    const keyToCheck = 'dropAddress';

    if(dropData.hasOwnProperty(keyToCheck) && dropData[keyToCheck] !== null && dropData[keyToCheck] !== undefined && dropData[keyToCheck] !== "") {
      this.tripDetailForm.controls['dropAddress'].setValue(dropData.dropAddress);
      //this.tripDetailForm.controls['dropCityLat'].setValue(dropData.dropAddressLat);
      //this.tripDetailForm.controls['dropCityLong'].setValue(dropData.dropAddressLong);

      sessionStorage.setItem('dropAddress',dropData.dropAddress);
      sessionStorage.setItem('dropAddressLat',dropData.dropAddressLat);
      sessionStorage.setItem('dropAddressLong',dropData.dropAddressLong);

      this.isPlaceValidWeb(this.dropCityResult,dropData.dropAddressLat,dropData.dropAddressLong,"drop");

    }

  }

  gst;
  GSTShow(){
    console.log(this.gstDataChild);
    //return false;
    this.gst = true;
    this.tripDetail = false;
    //this.tripDetail = true;
  }

  gstDataChild: any;
  GSTHide(gstDataPass){
    this.gst = false;
    this.tripDetail = true;
    //alert(gstDataPass);

    if(gstDataPass != ""){
      this.gstDataChild = gstDataPass;
      this.tripDetailForm.controls['gstNumber'].setValue(gstDataPass.gstNumber);
    }
  
  }

  passenger;
  passengerShow(){
    this.passenger = true;
    this.tripDetail = false;
  }

  passengerDataChild: any;
  passengerHide(passengerDataPass){
    this.passenger = false;
    this.tripDetail = true;

    if(passengerDataPass != ""){
      this.alternateBlk = true;
      this.passengerDataChild = passengerDataPass;
      this.tripDetailForm.controls['alternateName'].setValue(passengerDataPass.alternateName);
      this.tripDetailForm.controls['alternateNumber'].setValue(passengerDataPass.alternateNumber);
    }
    
  }

}