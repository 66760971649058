<div *ngIf="fdInJourneyBlk" class="row mt-2">

    <div class="col-md-4 col-12 mb-2">
        <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
            <div class="card-body">
                <p class="mb-0"><b>Booking ID: </b>{{this.bookingDetailsInjourneyFeedbackRes.displayBookingId}}</p>
            </div>
        </div>
    </div>

    <!-- <div class="col-md-4 col-12 mt-2 mb-2">
        <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
            <div class="card-body">
                <p class="mb-0"><b>Booking Status: </b>{{this.bookingDetailsInjourneyFeedbackRes.bookingStatusText}}</p>
            </div>
        </div>
    </div> -->

    <div class="col-md-4 col-12 mb-2">
        <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
            <div class="card-body">
                <p class="mb-0"><b>Route: </b>{{this.bookingDetailsInjourneyFeedbackRes.fromCityName}} - {{this.bookingDetailsInjourneyFeedbackRes.toCityName}}</p>
            </div>
        </div>
    </div>

</div>

<div class="row mb-2" *ngIf="fdInJourneyBlk">
    <div class="col-md-8 col-12">
        <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
            <div class="card-body">
                <div class="row">

                    <div class="col-md-12 col-12">
                        <!-- <p class="pClass">From: {{this.bookingDetailsInjourneyFeedbackRes.fromCityName}}</p> -->
                        <!-- <p class="pClass">To: {{this.bookingDetailsInjourneyFeedbackRes.toCityName}}</p> -->
                        <p class="pClass">Trip Started at {{this.bookingDetailsInjourneyFeedbackRes.journeyStartTime | date: 'd MMM - h:mm a'}}</p>
                    </div>

                    <div *ngIf="ijFeedbackBtn" class="col-md-12 col-12 mb-2 text-center">
                        <button class="btn btn-success shadow-sm mb-2 mr-2" (click)="isCallbackRequestedInjourneyFeedbackFun('2')"
                                style="background: #00800012; border: none; color: #21884F;">
                            Everything is Ok
                        </button>

                        <button class="btn btn-danger shadow-sm mb-2 mr-2" (click)="isCallbackRequestedInjourneyFeedbackFun('1')"
                                style="background: #ff00000d; border: none; color: red;">
                            Have issues, Please call back
                        </button>
                        
                    </div>

                </div>    
            </div>
        </div>
    </div>
</div>

<div class="row mt-3" *ngIf="fdInJourneyErrorBlk">
    <div class="col-md-12 col-12">
        <div class="alert alert-danger">{{this.fdInJourneyErrorBlkMsg}}</div>
    </div>
</div>