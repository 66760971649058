<mat-card>
  <mat-card-content>
    <div class="date-time-container">
      <!-- First row: Months -->
      <div class="month-item" *ngFor="let month of months; let i = index" [ngClass]="{
      'current-month': month.value === currentDate.getMonth(),
      'selected-month': i === selectedMonthIndex && month.value !== currentDate.getMonth()
    }" (click)="onMonthClick(month.value, i)">
        {{ month.label }}
      </div>
    </div>

    <div class="date-time-container">

      <!-- Second row: Days -->
      <div class="date-items-container">
        <div class="day-item" *ngFor="let day of selectedMonthDays" [ngClass]="{
                    'current-day': (day === currentDate.getDate() && selectedMonth === currentDate.getMonth()) && day !== selectedDate,
                    'selected-date': day === selectedDate
                  }" (click)="onDayClick(day)">
          <div class="date-number">{{ day }}</div>
          <div class="day-letter">{{ getDayFirstLetter(selectedMonth, day) }}</div>
        </div>
      </div>

    </div>
  </mat-card-content>
</mat-card>