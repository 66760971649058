import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.css']
})
export class TimeSelectorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getTimeOptions(): any[] {
    const timeOptions: any[] = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const hour12 = (hour % 12 === 0) ? 12 : hour % 12;
        const period = (hour < 12) ? 'AM' : 'PM';
        const time = `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
        timeOptions.push({ value: time, label: time });
      }
    }

    return timeOptions;
  }

}
