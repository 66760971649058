<div class="container-fluid">
    <div class="container">
      <div class="row mt-3">
        <div class="col-md-6 col-12">
          <div class="row">
            <div class="col-md-12 col-12 text-center">
              <div class="clickable-div" id="targetDiv" (click)="openModal()">Click Me for Modal</div>
            </div>
  
            <div class="red block">
            </div>
            
            <div class="col-md-12 col-12 blue block">
              <!-- Modal -->
              <div id="myModal" class="modal fade" role="dialog">
                <div class="modal-dialog">
  
                  <!-- Modal content-->
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                      <h4 class="modal-title">Modal Header</h4>
                    </div>
                    <div class="modal-body">
                      <p>Some text in the modal.</p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                  </div>
  
                </div>
              </div>
            </div>
  
            
          </div>
  
  
        </div>
      </div>
    </div>
  </div>