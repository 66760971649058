<div class="container-fluid">

    <div class="container">

        <div class="row mt-3">

            <div class="col-md-6 col-12">

                <div class="row">

                    <div class="col-md-12 col-12">
                        <div class="card shadow">
                            <div class="card-body">

                                <div class="form-row">
                                    <div class="col-md-12 col-12">

                                        <div class="search-header">
                                            <button class="btn back-button" (click)="goBack()" style="padding: 0px;">
                                                <img src="../../assets/img/back-arrow.png"
                                                    style="height: 25px; width: 25px; margin-right: 10px;">
                                            </button>

                                            <input *ngIf="noDatatoSearch" type="text" #newSocietyInt class="search-input" 
                                            placeholder="{{placeHolderTitle | titlecase}}" autocomplete="off">

                                            <input *ngIf="datatoSearch" type="text" #newSocietyInt [(ngModel)]="searchTerm" (input)="search()"
                                                class="search-input" placeholder="{{placeHolderTitle | titlecase}}"
                                                autocomplete="off">
                                        </div>

                                        <div *ngIf="listofAddressDataBlk" class="search-results">
                                            <div *ngFor="let result of societyNameList" class="result-item">
                                                <div class="map-marker"></div>
                                                <div class="result-details" (click)="saveSocietyData(result)"
                                                    style="cursor: pointer;">
                                                    <div class="result-description">{{ result.societyName }}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div *ngIf="noExstingDataBtn" class="col-md-12 col-12 text-right">
                                        <button class="btn btn-success py-1" (click)="saveNewSocietyData()">ADD</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>

</div>
