<div class="container-fluid">

    <div class="container" *ngIf="userVerificationCnt">

        <div class="row mt-3">
            <div class="col-md-6 col-12">

                <mat-card>

                    <mat-card-content>

                        <form [formGroup]='loginForm'>

                            <mat-form-field *ngIf="mobileNumberScreen" class="example-full-width" appearance="fill"
                                style="display: block;">
                                <mat-label>Mobile Number</mat-label>
                                <span matPrefix>+91 &nbsp;</span>
                                <input type="tel" matInput formControlName="mobileNumber" placeholder="1234567890"
                                    maxlength="10" (keypress)="keyPressForNumber($event)">
                            </mat-form-field>

                            <mat-card-actions *ngIf="mobileNumberScreen" align="end">
                                <button *ngIf="mobileNumberInProcess" mat-button>In Process...</button>
                                <button *ngIf="mobileNumberSubmit" mat-raised-button color="primary"
                                    (click)="getLoginRegistrationFun()">submit</button>
                            </mat-card-actions>

                            <mat-form-field *ngIf="otpScreen" class="example-full-width" appearance="fill" style="display: block;">
                                <mat-label>Enter OTP sent on + 91 - {{mobileNumberDisplay}}</mat-label>
                                <input matInput #message maxlength="6"  formControlName="otpNumber" placeholder="Ex. XXXXXX" (keypress)="keyPressForNumber($event)">
                            </mat-form-field>

                            <mat-card-actions *ngIf="otpScreen" align="end">
                                <button mat-raised-button color="primary" (click)="webOTPVerificationFun()">Verify</button>
                            </mat-card-actions>

                        </form>

                    </mat-card-content>

                </mat-card>

            </div>

        </div>

    </div>

    <div class="container" *ngIf="tripCnt">
        <div class="row">
            <div class="col-md-12 col-12">
                <mat-tab-group dynamicHeight>
                    
                    <mat-tab label="UpComming Trips"> 
                        <app-upcomming-trip *matTabContent></app-upcomming-trip>
                    </mat-tab>

                    <mat-tab label="Completed Trips"> 
                        <app-completed-trip *matTabContent></app-completed-trip>   
                    </mat-tab>
                    
                    <mat-tab label="Cancel Trip"> 
                        <app-cancel-trip *matTabContent></app-cancel-trip>
                    </mat-tab>

                  </mat-tab-group>
            </div>
        </div>
    </div>

</div>