import { Component, OnInit } from '@angular/core';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';

@Component({
  selector: 'app-feedback-completed-ride',
  templateUrl: './feedback-completed-ride.component.html',
  styleUrls: ['./feedback-completed-ride.component.css']
})
export class FeedbackCompletedRideComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();

  constructor( private ows: OnewayWebapiService) { }

  feedbackCompleted;
  feedbackPending;
  encodedBookId: any;
  bookIdVal: any;
  submitBtn;
  ngOnInit(): void {

    var hrefURL = window.location.href;
    var hrefURLSplit = hrefURL.split("/feedback/");
    this.encodedBookId = hrefURLSplit[1];
    this.bookIdVal = atob(this.encodedBookId);

    //console.log(this.encodedBookId);
    console.log(this.bookIdVal);
  
    this.feedbackPending = false;
    this.feedbackCompleted = false;

    this.submitBtn = false;

    this.getWebAccessKeyFun();
  }

  overallExperience: any;

  badBlk = false;
  badFun(){
    this.badBlk = true;
    this.excellentBlk = false;
    this.betterBlk = false;

    this.overallExperience = 1;
    this.submitBtn = true;
    this.likeService = [];
    this.improveService = [];

    this.hideOtherInputBlk();
  }

  excellentBlk = false;
  excellentFun(){
    this.excellentBlk = true;
    this.badBlk = false;
    this.betterBlk = false;
    
    this.overallExperience = 5;
    this.submitBtn = true;
    this.likeService = [];
    this.improveService = [];

    this.hideOtherInputBlk();
  }

  betterBlk = false;
  betterFun(){
    this.betterBlk = true;
    this.badBlk = false;
    this.excellentBlk = false;

    this.overallExperience = 3;
    this.submitBtn = true;
    this.likeService = [];
    this.improveService = [];

    this.hideOtherInputBlk();
  }

  excellentInt;
  betterInt;
  badInt;
  hideOtherInputBlk(){
    this.excellentInt = false;
    this.betterInt = false;
    this.badInt = false;
  }

  likeService: any=[];
  likeServiceFun(e,status){
    //this.likeService = [];
    //var m = document.getElementById(e);
    var index = this.likeService.indexOf(e);

    if(this.likeService.indexOf(e) == -1){
      this.likeService.push(e);
      document.getElementById(e+status).className = "btn btn-primary mr-2 mt-2";  
      //if(m != null){ m.className = "btn btn-primary mr-2";}
    } else {
      this.likeService.splice(index, 1);
      document.getElementById(e+status).className = "btn btn-outline-primary mr-2 mt-2";
      //if(m != null){ m.className = "btn btn-outline-primary mr-2"; }
    }
    console.log(this.likeService);
  }

  improveService: any=[];
  improveServiceFun(e,status){
    //this.improveService = [1,2,3,4,5];
    var index = this.improveService.indexOf(e);

    if(this.improveService.indexOf(e) == -1){
      this.improveService.push(e);
      document.getElementById(e+status).className = "btn btn-primary mr-2 mt-2";
    } else {
      this.improveService.splice(index, 1);
      document.getElementById(e+status).className = "btn btn-outline-primary mr-2 mt-2";
    }
    console.log(this.improveService);

  }


  //improveService: any=[];
  badServiceFun(e,status){
    //this.improveService = [1,2,3,4,5];
    var index = this.improveService.indexOf(e);

    if(this.improveService.indexOf(e) == -1){
      this.improveService.push(e);
      document.getElementById(e+status).className = "btn btn-primary mr-2 mt-2";

      if(e == '1'){
        document.getElementById(e+status).className = "btn btn-warning mr-2 mt-2";
        this.badInt = true;
        this.badBtn = 1;
      }

    } else {
      this.improveService.splice(index, 1);
      document.getElementById(e+status).className = "btn btn-outline-primary mr-2 mt-2";

      if(e == '1'){
        document.getElementById(e+status).className = "btn btn-outline-warning mr-2 mt-2";
        this.badInt = false;
        this.badBtn = 0;
      }

    }
    console.log(this.improveService);

  }

  //likeService: any=[];
  excellentServiceFun(e,status){
    //this.likeService = [];
    //var m = document.getElementById(e);
    var index = this.likeService.indexOf(e);

    if(this.likeService.indexOf(e) == -1){
      this.likeService.push(e);
      document.getElementById(e+status).className = "btn btn-primary mr-2 mt-2";  
      //if(m != null){ m.className = "btn btn-primary mr-2";}

      if(e == '1'){
        document.getElementById(e+status).className = "btn btn-warning mr-2 mt-2";
        this.excellentInt = true;
        this.excellentBtn = 1;
      }
    } else {
      this.likeService.splice(index, 1);
      document.getElementById(e+status).className = "btn btn-outline-primary mr-2 mt-2";
      //if(m != null){ m.className = "btn btn-outline-primary mr-2"; }

      if(e == '1'){
        document.getElementById(e+status).className = "btn btn-outline-warning mr-2 mt-2";
        this.excellentInt = false;
        this.excellentBtn = 0;
      }

    }
    console.log(this.likeService);

  }


  excellentService: any=[];
  excellentBtn: any;

  betterService: any=[];
  betterBtn: any;

  badService: any=[];
  badBtn: any;
  otherBtnFun(other){

    if(other == "excellentOther"){
      this.excellentInt = true;

      var index = this.excellentService.indexOf(other);
      if(this.excellentService.indexOf(other) == -1){
        this.excellentService.push(other);
        document.getElementById(other).className = "btn btn-warning mr-2 mt-2";
        this.excellentBtn = 1;
      } else {
        this.excellentService.splice(index, 1);
        document.getElementById(other).className = "btn btn-outline-warning mr-2 mt-2";
        this.excellentBtn = 0;
      }
    }

    if(other == "betterOther"){
      this.betterInt = true;

      var index = this.betterService.indexOf(other);
      if(this.betterService.indexOf(other) == -1){
        this.betterService.push(other);
        document.getElementById(other).className = "btn btn-warning mr-2 mt-2";
        this.betterBtn = 1;
      } else {
        this.betterService.splice(index, 1);
        document.getElementById(other).className = "btn btn-outline-warning mr-2 mt-2";
        this.betterBtn = 0;
      }
    }

    if(other == "badOther"){
      this.badInt = true;

      var index = this.badService.indexOf(other);
      if(this.badService.indexOf(other) == -1){
        this.badService.push(other);
        document.getElementById(other).className = "btn btn-warning mr-2 mt-2";
        this.badBtn = 1;
      } else {
        this.badService.splice(index, 1);
        document.getElementById(other).className = "btn btn-outline-warning mr-2 mt-2";
        this.badBtn = 0;
      }
    }

  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKeyRes :", this.webAccessKeyRes.accessKey);

      if(this.webAccessKeyRes.status == 1){
        this.bookingFeedbackInfoFun();
        //this.getFeedbackServiceReasonListFun();
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  bookingFeedbackInfoRes: any=[];
  bookingFeedbackInfoFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      enCodeBookId : this.encodedBookId
    };
    console.log(this.requestData);

    this.ows.bookingFeedbackInfo(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.bookingFeedbackInfoRes = data;
      console.log(this.bookingFeedbackInfoRes);
      
      this.feedbackMessage = this.bookingFeedbackInfoRes.message;

      if(this.bookingFeedbackInfoRes.status == 1){
        this.getFeedbackServiceReasonListFun();
        this.feedbackPending = true;
        this.feedbackCompleted = false;
      } else {
        this.feedbackPending = false;
        this.feedbackCompleted = true;

        //console.log(this.bookingFeedbackInfoRes.message);
        //(<HTMLInputElement>document.getElementById("successMsg")).value = this.bookingFeedbackInfoRes.message;
        //document.getElementById('successMsg').innerHTML = this.bookingFeedbackInfoRes.message;
      }

    },error =>{
      console.log("feedbackServiceReasonListRes Error :",error);
    });

  }

  feedbackServiceReasonListRes: any=[];
  feedbackServiceReasonListData: any=[];
  getFeedbackServiceReasonListFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      //bookId : 2322,
      bookId : this.bookIdVal
    };
    console.log(this.requestData);

    this.ows.getFeedbackServiceReasonList(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.feedbackServiceReasonListRes = data;
      this.feedbackServiceReasonListData = this.feedbackServiceReasonListRes.feedbackServiceReasonList;
      //console.log(this.feedbackServiceReasonListRes);

      if(this.feedbackServiceReasonListRes.status == 1){
        //this.fetchPickupCityListPriority();
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("feedbackServiceReasonListRes Error :",error);
    });

  }

  insertBookingFeedbackRes: any=[];
  likeOther: any;
  improveOther: any;
  feedbackMessage: any;
  insertBookingFeedbackFun(){

    //if(this.improveService.length == 0){ return false; }
    //console.log(this.betterBtn);

    if(this.overallExperience == 1){
      this.likeOther = "";
      if(this.badBtn == 1){
        this.improveOther = (<HTMLInputElement>document.getElementById("badInt")).value;

        if(this.improveOther == ""){
          alert("Enter Other Remarks");
          return false;
        }

      } else {
        this.improveOther = "";
      }
    }

    if(this.overallExperience == 5){
        if(this.excellentBtn == 1){
          this.likeOther = (<HTMLInputElement>document.getElementById("excellentInt")).value;

          if(this.likeOther == ""){
            alert("Enter Other Remarks");
            return false;
          }

        } else {
          this.likeOther = "";
        }

        this.improveOther = "";
    }

    if(this.overallExperience == 3){

      if(this.badBtn == 1){
          this.improveOther = (<HTMLInputElement>document.getElementById("badInt")).value;
          if(this.improveOther == ""){
            alert("Enter Other Remarks");
            return false;
          }
      } else {
        this.improveOther = "";
      }

      if(this.excellentBtn == 1){
        this.likeOther = (<HTMLInputElement>document.getElementById("excellentInt")).value;
        if(this.likeOther == ""){
          alert("Enter Other Remarks");
          return false;
        }
      } else {
        this.likeOther = "";
      }

    }

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      bookId : this.bookIdVal,
      overallExperience: this.overallExperience,
      likeService: this.likeService,
      improveService: this.improveService,
      likeOther: this.likeOther,
      improveOther: this.improveOther,
    };
    console.log(this.requestData);
    //return false;

    this.ows.insertBookingFeedback(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.insertBookingFeedbackRes = data;
      //console.log(this.insertBookingFeedbackRes);
      //alert(this.insertBookingFeedbackRes.message);

      this.feedbackMessage = this.insertBookingFeedbackRes.message;

      if(this.insertBookingFeedbackRes.status == 1){
        this.feedbackPending = false;
        this.feedbackCompleted = true;
        //document.getElementById("successMsg").innerHTML = this.insertBookingFeedbackRes.message;
      } else {
        this.feedbackPending = false;
        this.feedbackCompleted = true;
        //document.getElementById("successMsg").innerHTML = this.insertBookingFeedbackRes.message;
      }

    },error =>{
      console.log("insertBookingFeedbackRes Error :",error);
    });

  }

}