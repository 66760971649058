<div class="container">

    <div *ngIf="cancelRideBlk" class="row mt-2">

        <div class="col-md-4 col-12 mb-2">
            <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
                <div class="card-body">
                    <p class="mb-0"><b>Booking ID: </b>{{this.cancellationReasonRes.bookingId}}</p>
                </div>
            </div>
        </div>
    
        <div class="col-md-4 col-12">
            <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
                <div class="card-body">
                    <p class="mb-0"><b>Trip Type: </b>{{this.cancellationReasonRes.serviceType}}</p>
                </div>
            </div>
        </div>
    
        <div class="col-md-4 col-12 mb-2">
            <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
                <div class="card-body">
                    <p class="mb-0"><b>Route: </b>{{this.cancellationReasonRes.fromTo}}</p>
                </div>
            </div>
        </div>
    
    </div>

    <div *ngIf="cancelRideBlk" class="row mb-2">

        <div class="col-md-12 col-12">
            <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none; border-radius: 5px;">
                <div class="card-body">
        
                    <div class="row">
        
                        <div class="col-md-12 col-12 mt-3">
                            <p><b>Your Trip Was Cancelled !</b></p>
                            <p>We would like to understand this better, It will help us in improving Our Service.</p>
                    
                            <div class="row">
    
                                <div class="col-md-12 col-12 d-flex flex-wrap">
    
                                    <div *ngFor="let data of cancellationReasonRes.cancelReason; index as i">
                                        <button id="{{data.reasonId}}fdg" 
                                                class="btn btn-outline-primary mr-2 mt-2"
                                                (click)="cancelFeedbackFun(data.reasonId,'fdg')">
                                            {{data.reason}}
                                        </button>
                                    </div>
                                    
                                    <button type="button" class="btn btn-outline-primary mr-2 mt-2"
                                            id="otherfdg"
                                            (click)="cancelFeedbackFun('other','fdg')">
                                            other
                                    </button>
    
                                </div>
    
                                <div class="col-md-4 col-12" *ngIf="otherIdInt">
                                    <input type="text" id="otherIdInt" 
                                           class="form-control mt-2" 
                                           placeholder="Enter Reference Remarks" value="">
                                </div>
    
                            </div>
                    
                        </div>
    
                        <div class="col-md-12 col-12 text-right mt-3">
                            <button class="btn btn-warning" (click)="updateCancelReasonFun()">
                                Submit
                            </button>
                        </div>
        
                    </div>
        
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="cancelRideErrorBlk" class="row mt-3 mb-3">
        <div class="col-md-12 col-12">
            <div class="alert alert-success">{{this.feedbackMessage}}</div>
        </div>
    </div>

</div>