import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validator } from '@angular/forms';

@Component({
  selector: 'app-modify-time',
  templateUrl: './modify-time.component.html',
  styleUrls: ['./modify-time.component.css']
})
export class ModifyTimeComponent implements OnInit {
  @Input() pickupDatePass;
  @Input() pickupTimePass;
  @Output() changedDateStore = new EventEmitter();
  @Output() changedTimeStore = new EventEmitter();

  form: FormGroup;
  dateOptions: { value: string, label: string, selected: boolean }[] = [];
  timeOptions: { value: string, label: string }[] = [];

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.generateDateOptions();
    //this.generateTimeOptions(); 
    console.log(this.pickupDatePass);
    console.log(this.pickupTimePass);
  }

  modifyForm = this.fb.group({
    selectedDate: [""],
    selectedTime: [""]
  })

  generateDateOptions(): void {
    const currentDate = new Date();
    //const currentDate = new Date("2023-10-05");
    console.log(currentDate);

    const changeDate = new Date(currentDate.getTime() + 45 * 60000);

    if (changeDate.getHours() > 23) {
      changeDate.setDate(changeDate.getDate() + 1);
    }

    const futureDate = new Date(changeDate.getTime() + 60 * 24 * 60 * 60000);
    const diffDays = Math.ceil((futureDate.getTime() - changeDate.getTime()) / (24 * 60 * 60 * 1000));

    for (let i = 0; i <= diffDays; i++) {
      const currentDate = new Date(changeDate.getTime() + i * 24 * 60 * 60 * 1000);
      const formattedDate = this.formatDate(currentDate);

      this.dateOptions.push({
        value: this.formatDateForValue(currentDate),
        label: formattedDate,
        selected: this.isCurrentDate(currentDate)
      });
    }

    this.modifyForm.controls['selectedDate'].setValue(this.dateOptions[0].value);
    this.changedDateStoreBack(this.dateOptions[0].value);   
    this.generateTimeOptions();
  }

  formatDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
  }

  formatDateForValue(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
  }

  isCurrentDate(date: Date): boolean {
    const currentDate = new Date();
    //const currentDate = new Date("2023-10-05");
    console.log(currentDate);
    return date.toDateString() === currentDate.toDateString();
  }

  dateSelectedFun(e) {
    //alert(e.target.value);
    this.generateTimeOptions();
    this.changedDateStoreBack(e.target.value);
  }

  timeArray = [
    "12:00 AM", "12:15 AM", "12:30 AM", "12:45 AM",
    "1:00 AM", "1:15 AM", "1:30 AM", "1:45 AM",
    "2:00 AM", "2:15 AM", "2:30 AM", "2:45 AM",
    "3:00 AM", "3:15 AM", "3:30 AM", "3:45 AM",
    "4:00 AM", "4:15 AM", "4:30 AM", "4:45 AM",
    "5:00 AM", "5:15 AM", "5:30 AM", "5:45 AM",
    "6:00 AM", "6:15 AM", "6:30 AM", "6:45 AM",
    "7:00 AM", "7:15 AM", "7:30 AM", "7:45 AM",
    "8:00 AM", "8:15 AM", "8:30 AM", "8:45 AM",
    "9:00 AM", "9:15 AM", "9:30 AM", "9:45 AM",
    "10:00 AM", "10:15 AM", "10:30 AM", "10:45 AM",
    "11:00 AM", "11:15 AM", "11:30 AM", "11:45 AM",
    "12:00 PM", "12:15 PM", "12:30 PM", "12:45 PM",
    "1:00 PM", "1:15 PM", "1:30 PM", "1:45 PM",
    "2:00 PM", "2:15 PM", "2:30 PM", "2:45 PM",
    "3:00 PM", "3:15 PM", "3:30 PM", "3:45 PM",
    "4:00 PM", "4:15 PM", "4:30 PM", "4:45 PM",
    "5:00 PM", "5:15 PM", "5:30 PM", "5:45 PM",
    "6:00 PM", "6:15 PM", "6:30 PM", "6:45 PM",
    "7:00 PM", "7:15 PM", "7:30 PM", "7:45 PM",
    "8:00 PM", "8:15 PM", "8:30 PM", "8:45 PM",
    "9:00 PM", "9:15 PM", "9:30 PM", "9:45 PM",
    "10:00 PM", "10:15 PM", "10:30 PM", "10:45 PM",
    "11:00 PM", "11:15 PM", "11:30 PM", "11:45 PM",
  ];

  generateTimeOptions(): void {
    this.timeOptions = [];
    const currentDate = new Date();
    //const currentDate = new Date("2023-10-05");
    console.log(currentDate);
    
    const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
    const timeArray = this.timeArray;
    //console.log(timeArray.length);

    for (let i = 0; i < timeArray.length; i++) {
      const timeArrayMinutes = this.convertTimeToMinutes(timeArray[i]);
      if (this.modifyForm.controls['selectedDate'].value == this.currentDateFormat(currentDate)) {

        //if(timeArrayMinutes > currentTime + 15) { "Able to adjust time here"

        if(timeArrayMinutes > currentTime+45) {  
          this.timeOptions.push({
            value: this.convertTimeToValue(timeArray[i]),
            label: timeArray[i]
          });
        }
      } else {
        this.timeOptions.push({
          value: this.convertTimeToValue(timeArray[i]),
          label: timeArray[i]
        });
      }
    }

    this.modifyForm.controls['selectedTime'].setValue(this.timeOptions[0].value);
    this.changedTimeStoreBack(this.timeOptions[0].value);
  }

  convertTimeToMinutes(timeString: string): number {
    const [hoursString, minutesString] = timeString.split(':');
    let hours = parseInt(hoursString, 10);
    const minutes = parseInt(minutesString, 10);

    // Adjust for PM if necessary
    if (timeString.includes('PM') && hours < 12) {
      hours += 12;
    }
    // Convert to minutes
    const totalMinutes = hours * 60 + minutes;
    return totalMinutes;
  }

  convertTimeToValue(time: string): string {
    const [rawTime, period] = time.split(' ');
    const [hours, minutes] = rawTime.split(':');

    let hourValue = parseInt(hours, 10);
    if (period === 'PM' && hourValue !== 12) {
      hourValue += 12;
    } else if (period === 'AM' && hourValue === 12) {
      hourValue = 0;
    }

    return `${hourValue.toString().padStart(2, '0')}:${minutes}:00`;
  }

  currentDateFormat(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }

  timeSelectedFun(e) {
    //alert(e.target.value);
    this.changedTimeStoreBack(e.target.value);
  }


  changedDateStoreBack(eDate){
    this.changedDateStore.emit({
       newDate : eDate 
    });
  }

  changedTimeStoreBack(eTime){
    this.changedTimeStore.emit({
       newTime : eTime 
    });
  }

}