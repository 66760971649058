import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-test-login',
  templateUrl: './test-login.component.html',
  styleUrls: ['./test-login.component.css']
})

export class TestLoginComponent implements OnInit {
  loginForm: FormGroup;
  otpForm: FormGroup;
  showOtpForm = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z][a-zA-Z\s]*$/)]],
      mobile: ['', [Validators.required, Validators.pattern(/^\+91[0-9]{10}$/)]],
      email: ['', [Validators.required, Validators.email]]
    });

    this.otpForm = this.fb.group({
      otp: ['', Validators.required]
    });
  }

  submitLoginForm() {
    if(this.loginForm.valid) {
      // You can add logic here to send OTP to the provided mobile number or email.
      this.showOtpForm = true;
    }
  }

  submitOtpForm() {
    if (this.otpForm.valid) {
      // Add logic here to verify the OTP and complete the login process.
      alert('OTP Verified! You are now logged in.');
    }
  }
}

