<div class="container">
<div *ngIf="feedbackPending" class="row mt-2">

    <div class="col-md-4 col-12 mb-2">
        <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
            <div class="card-body">
                <p class="mb-0"><b>Booking ID: </b>{{this.bookingFeedbackInfoRes.displayBookId}}</p>
            </div>
        </div>
    </div>

    <!-- <div class="col-md-4 col-12 mt-1">
        <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
            <div class="card-body">
                <p class="mb-0"><b>Trip Type: </b>{{this.bookingFeedbackInfoRes.tripType}}</p>
            </div>
        </div>
    </div> -->

    <div class="col-md-4 col-12 mb-2">
        <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
            <div class="card-body">
                <p class="mb-0"><b>Route: </b>{{this.bookingFeedbackInfoRes.route}}</p>
            </div>
        </div>
    </div>

</div>

<div *ngIf="feedbackPending" class="row">
    <div class="col-md-12 col-12 mb-2">
        <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none; border-radius: 5px;">
            <div class="card-body">
    
                <div class="row">
    
                    <div class="col-md-12 col-12 mt-3">
                        <p>Your feedback is important because it helps us improve our services</p>
                        <p>How was your overall ride experience ?</p>
                    </div>
                    
                    <div class="col-md-12 col-12 text-center">
                        <button class="btn btn-secondary mr-2" (click)="badFun()">Bad</button>
                        <button class="btn btn-secondary mr-2" (click)="betterFun()">Good</button>
                        <button class="btn btn-secondary mr-2" (click)="excellentFun()">Great</button>
                        <hr>
                    </div>
                
                    <div *ngIf="badBlk" class="col-md-12 col-12 mt-3">
                        
                        <div class="row">
                            <div class="col-md-8 col-12">
                                <p>Where did we go wrong ?</p>
                            </div>

                            <div class="col-md-8 col-12 d-flex flex-wrap">
                                <div *ngFor="let data of feedbackServiceReasonListData; index as i">
                                    <button id="{{feedbackServiceReasonListData[i].feedbackServiceReasonId}}Bad" 
                                            *ngIf="feedbackServiceReasonListData[i].serviceType == 2" 
                                            class="btn btn-outline-primary mr-2 mt-2"
                                            (click)="badServiceFun(feedbackServiceReasonListData[i].feedbackServiceReasonId,'Bad')">
                                        {{feedbackServiceReasonListData[i].feedbackServiceReason}}
                                    </button>

                                    <!-- (click)="improveServiceFun(feedbackServiceReasonListData[i].feedbackServiceReasonId,'Bad')"> -->
                                </div> 
                                <button type="button" class="btn btn-outline-warning mr-2 mt-2"
                                        id="1Bad"
                                        (click)="badServiceFun(1,'Bad')">
                                        Other
                                </button>

                                <!-- <button type="button" class="btn btn-outline-warning mr-2 mt-2"
                                        id="excellentOther"
                                        (click)="badServiceFun(1,'badOther')">
                                        other
                                </button> -->
                            
                            </div>

                            <div class="col-md-4 col-12" *ngIf="badInt">
                                <input type="text" id="badInt" class="form-control mt-2" placeholder="Enter Remarks" value="">
                            </div>

                        </div>
                
                    </div>
                
                    <div *ngIf="excellentBlk" class="col-md-12 col-12">
                        
                        <div class="row">
                            <div class="col-md-8 col-12">
                                <p>What did you love ?</p>
                            </div>
                            
                            <div class="col-md-8 col-12 d-flex flex-wrap">
                                <div *ngFor="let data of feedbackServiceReasonListData; index as i">
                                    <button id="{{feedbackServiceReasonListData[i].feedbackServiceReasonId}}Excellent" 
                                            *ngIf="feedbackServiceReasonListData[i].serviceType == 1 || feedbackServiceReasonListData[i].serviceType == 3" 
                                            class="btn btn-outline-primary mr-2 mt-2"
                                            (click)="excellentServiceFun(feedbackServiceReasonListData[i].feedbackServiceReasonId,'Excellent')">
                                        {{feedbackServiceReasonListData[i].feedbackServiceReason}}
                                    </button>
                                </div>
                                <button type="button" class="btn btn-outline-warning mr-2 mt-2"
                                        id="1Excellent"
                                        (click)="excellentServiceFun(1,'Excellent')">
                                        Other
                                </button>

                            </div>

                             <div class="col-md-4 col-12" *ngIf="excellentInt">
                                <input type="text" id="excellentInt" class="form-control mt-2" placeholder="Enter Remarks" value="">
                             </div>

                        </div>
                
                    </div>
                
                    <div *ngIf="betterBlk" class="col-md-12 col-12 mt-3">

                        <div class="row">
                            <div class="col-md-8 col-12">
                                <p>What did you like ?</p>
                            </div>
                            
                            <div class="col-md-8 col-12 d-flex flex-wrap">
                                <div *ngFor="let data of feedbackServiceReasonListData; index as i">
                                    <button id="{{feedbackServiceReasonListData[i].feedbackServiceReasonId}}Excellent" 
                                            *ngIf="feedbackServiceReasonListData[i].serviceType == 1 || feedbackServiceReasonListData[i].serviceType == 3" 
                                            class="btn btn-outline-primary mr-2 mt-2"
                                            (click)="excellentServiceFun(feedbackServiceReasonListData[i].feedbackServiceReasonId,'Excellent')">
                                        {{feedbackServiceReasonListData[i].feedbackServiceReason}}
                                    </button>
                                </div>
                                <button type="button" class="btn btn-outline-warning mr-2 mt-2"
                                        id="1Excellent"
                                        (click)="excellentServiceFun(1,'Excellent')">
                                        Other
                                </button>

                            </div>

                             <div class="col-md-4 col-12" *ngIf="excellentInt">
                                <input type="text" id="excellentInt" class="form-control mt-2" placeholder="Enter Remarks" value="">
                             </div>

                        </div>

                        <div class="row mt-3">

                            <div class="col-md-8 col-12">
                                <p>Areas of Improvement</p>
                            </div>
                            
                            <div class="col-md-8 col-12 d-flex flex-wrap">

                                <div class="" *ngFor="let data of feedbackServiceReasonListData; index as i">
                                    <button id="{{feedbackServiceReasonListData[i].feedbackServiceReasonId}}Bad" 
                                            *ngIf="feedbackServiceReasonListData[i].serviceType == 2 || feedbackServiceReasonListData[i].serviceType == 3" 
                                            class="btn btn-outline-primary mr-2 mt-2"
                                            (click)="badServiceFun(feedbackServiceReasonListData[i].feedbackServiceReasonId,'Bad')">
                                            <!-- [ngClass]="invoiceNumberError ? 'btn btn-outline-primary' : 'btn btn-primary'" -->
                                            
                                        {{feedbackServiceReasonListData[i].feedbackServiceReason}}
                                    </button>
                                </div> 

                                <button type="button" class="btn btn-outline-warning mr-2 mt-2"
                                        id="1Bad"
                                        (click)="badServiceFun(1,'Bad')">
                                        Other
                                </button>
                            
                            </div>

                            <div class="col-md-4 col-12" *ngIf="badInt">
                                <input type="text" id="badInt" class="form-control mt-2" placeholder="Enter Remarks" value="">
                            </div>
                            
                        </div>
                
                    </div>
                
                    <div *ngIf="submitBtn" class="col-md-12 col-12 text-right mt-3">
                        <button class="btn btn-warning" (click)="insertBookingFeedbackFun()">
                            Submit
                        </button>
                    </div>
    
                </div>
    
            </div>
        </div>
    </div>
</div>

<div *ngIf="feedbackCompleted" class="row mt-3 mb-3">

    <div class="col-md-12 col-12">
        <div class="alert alert-danger">{{this.feedbackMessage}}</div>
    </div>
    
</div>

</div>