<div class="container-fluid">
    <div class="container">
  
      <div class="row mt-3">
  
        <div class="col-md-6 col-12">
  
          <div class="row login-custom py-0">
  
            <div class="col-md-12 col-12 px-0">
              
              <div class="card shadow">
                <div class="card-body p-2">
                   
                  <div class="row">
                    <div class="col-md-12 col-12">
                      <label>Mobile Number : <b>{{this.corporateMobileNumber}}</b></label>
                    </div>
                    <div *ngIf="corporateEmailBlk" class="col-md-12 col-12">
                      <label>Email Id : <b>{{this.corporateEmail}}</b></label>
                    </div>
                  </div>
                  
                  <form [formGroup]="otpForm">

                    <div class="form-group">
                      <input type="text" class="form-control" formControlName="otpNumber" (keypress)="keyPressForNumber($event)" placeholder="Enter OTP" required>
                    </div>
  
                    <div class="form-group text-right">
                      <button type="submit" class="btn btn-primary" (click)="corporateOTPVerificationFun()">Confirm</button>
                    </div>
  
                  </form>
                  
  
                </div>
              </div>
  
            </div>
  
          </div>
  
        </div>
  
      </div>
  
    </div>
  
  </div>
  
