import { Component, OnInit, Input, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';

@Component({
  selector: 'app-user-wallet',
  templateUrl: './user-wallet.component.html',
  styleUrls: ['./user-wallet.component.css']
})
export class UserWalletComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  constructor( private fb: FormBuilder, 
    private ows: OnewayWebapiService, 
    private titleService: Title,  
    private metaTagService: Meta) { }

  ngOnInit(): void {
    this.getWebAccessKeyFun();
  }

  requestData: any ={};
  webAccessKey: any;
  webAccessKeyRes: any =[];
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        this.getUserWalletAmountFun();
        this.walletTransactionHistory(0);
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  userWalletAmountRes: any=[];
  getUserWalletAmountFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      userId : sessionStorage.getItem('userId')
    };
    console.log(this.requestData);

    this.ows.getUserWalletAmount(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.userWalletAmountRes = data;
      console.log("userWalletAmountRes :: "+JSON.stringify(this.userWalletAmountRes));
      //this.Message = this.userWalletAmountRes.message;

      if(this.userWalletAmountRes.status == 1){
        sessionStorage.setItem('userWalletAmount',this.userWalletAmountRes.wallteAmount);
      }
      // else {}

    },error =>{
      console.log("userWalletAmountRes Error :",error);
    });

  }

  walletTransactionHistoryRes: any=[];
  offset: any;
  userWalletData = [];
  loadMoreBtn;
  walletTransactionHistory(offset){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      userId : sessionStorage.getItem('userId'),
      offset : offset //offset value like 0, 5, 10, 15, 25, 30,....
    };
    console.log(this.requestData);

    this.ows.walletTransactionHistory(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.walletTransactionHistoryRes = data;
      
      if(this.walletTransactionHistoryRes.status == 1){
        console.log(this.walletTransactionHistoryRes);  
        this.offset = offset+5;
        console.log(this.offset);

        for(var i=0; i<this.walletTransactionHistoryRes.transaction_history_list.length; i++){
          this.userWalletData.push(this.walletTransactionHistoryRes.transaction_history_list[i]);
        }
        console.log(this.userWalletData);
        
        if(this.walletTransactionHistoryRes.transaction_history_list.length < 5){
          this.loadMoreBtn = false;
        } else {
          this.loadMoreBtn = true;
        }

      } else {}

    },error =>{
      console.log("walletTransactionHistoryRes Error :",error);
    });

  }

}