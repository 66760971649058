<form [formGroup]='modifyForm'>

    <div class="row">

        <div class="col-md-12 col-12">
            <p class="mb-1">Select new date & time</p>
        </div>

        <div class="col-md-6 col-6">
            <select class="form-control" formControlName="selectedDate" (change)="dateSelectedFun($event)">
                <option value="" selected disabled>Select Date</option>
                <option *ngFor="let option of dateOptions" [value]="option.value">
                    {{option.label }}
                </option>
            </select>
        </div>

        <div class="col-md-6 col-6">
            <select class="form-control" formControlName="selectedTime" (change)="timeSelectedFun($event)">
                <option *ngFor="let option of timeOptions" [value]="option.value">
                    {{ option.label }}
                </option>
            </select>
        </div>
    </div>

</form>