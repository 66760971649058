<header class="headerBorder">
         <div class="headerTop">
             <div class="container">
    
                 <div class="row">
    
                        <div class="col-md-6 col-6 text-left">
                            <a class="logo" href="{{this.apiProperties.siteurlAngular}}">
                                <img align="middle" src="assets/img/onewayCabLogo.png" alt="best taxi booking service oneway.cab" title="best taxi booking service oneway.cab" />
                            </a>
                        </div>
                     
                        <!-- <div class="col-md-7 col-0 main_header">
                            $iphone = strpos($_SERVER['HTTP_USER_AGENT'],"iPhone");
                            $android = strpos($_SERVER['HTTP_USER_AGENT'],"Android");
                            $palmpre = strpos($_SERVER['HTTP_USER_AGENT'],"webOS");
                            $berry = strpos($_SERVER['HTTP_USER_AGENT'],"BlackBerry");
                            $ipod = strpos($_SERVER['HTTP_USER_AGENT'],"iPod");
        
                            if($android || $palmpre || $ipod || $berry || $iphone == true) { } 
                            else { "India's Leading OneWay Inter-City Cab Provider." }
                        </div> -->
                     
                        <div class="col-md-6 col-6 text-right mt-4 mb-2">
                            <a href="#" style="font-size:17px; color : #f1c40f;">
                                <img src="assets/img/callIcon.png" alt="oneway.cab taxi booking number" style="width:25px;height:25px;"> 08000 247 247
                            </a> 
                        </div>
    
                 </div>
    
             </div>
         </div>
         
</header>    
