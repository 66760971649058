<div class="container-fluid" style="background: #fff;">

    <div class="container">

        <div class="row mt-3">

            <div class="d-md-flex flex-md-row bg-secondary mb-3">

                <div class="col-md-4 col-12">
                    <div class="p-2 bg-info align-self-md-center">

                    </div>
                </div>

                <div class="col-md-8 col-12">
                    <img src="assets/img/career/mainC.jpg" style="width: 100%;">
                </div>
            
            </div>
        
        </div>

        <!-- <div class="row mt-3">
    
            <div class="col-md-12 col-12">
    
                <div class="d-md-flex flex-md-row bg-secondary mb-3">
                    
                    <div class="p-2 bg-info flex-fill">
                        <div class="row">
                            <div class="col-md-12 col-12">
                                <div class="card">
                                    <div class="card-body">
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-2 bg-primary flex-fill">Flex item 3</div>
                  </div>
            </div>
        </div> -->

        <div class="row mt-4">
            <div class="col-md-3 col-12">

                <h2 class="align-self-center headingPage" style="border-right: 2px solid #f1f1f1;">
                    About Us
                </h2>

            </div>

            <!-- <div class="d-flex bg-secondary"></div> -->

            <div class="col-md-9 col-12">
                <p class="plainTextPage">
                    Cognizant (Nasdaq: CTSH) is one of the world's leading professional services companies, transforming
                    clients' business, operating and technology models for the digital era. Our unique industry-based,
                    consultative approach helps clients envision, build and run more innovative and efficient
                    businesses.
                    Headquartered in the U.S., Cognizant is ranked 194 on the Fortune 500 and is consistently listed
                    among the most admired companies in the world.
                </p>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-12 col-12 text-center">
                <h2 class="align-self-center headingPage">
                    Life @ Info Edge
                </h2>
            </div>

        </div>

        <div class="row">
            <div class="col-md-8 col-12">
                <img src="assets/img/career/life1.jpg" style="width: 100%; height: 500px; border-radius: 3px;">
            </div>

            <div class="col-md-4 col-12">

            </div>
        </div>

    </div>

</div>