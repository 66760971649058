import { Injectable } from '@angular/core';
import { Observable,throwError,forkJoin } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { APiProperties } from '../../app/class/api-properties';

@Injectable({
  providedIn: 'root'
})
export class OnewayWebapiService {

  apiProperties : APiProperties = new APiProperties();
  
  constructor(private http:HttpClient) { }
  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  getWebAccessKey(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getAccessKey' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  bookingFeedbackInfo(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'bookingFeedbackInfo' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getFeedbackServiceReasonList(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getFeedbackServiceReasonList' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  insertBookingFeedback(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'insertBookingFeedback' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  bookingDetailsInjourneyFeedback(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'bookingDetailsInjourneyFeedback' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  isCallbackRequestedInjourneyFeedback(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'isCallbackRequestedInjourneyFeedback' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getFirstRideFeedbackReasonList(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getFirstRideFeedbackReasonList' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  insertFirstRideFeedback(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'insertFirstRideFeedback' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getCancellationReason(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getCancellationReason' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateCancelReason(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'updateCancelReason' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getLoginRegistration(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getLoginRegistration' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  webOTPVerification(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'webOTPVerification' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  addUpdateWebUser(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'addUpdateWebUser' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  resendOTP(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'resendOTP' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getUserWalletAmount(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getUserWalletAmount' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  walletTransactionHistory(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'walletTransactionHistory' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getLastTwoGstDetails(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getLastTwoGstDetails' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getGstDetailsList(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getGstDetailsList' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  
  getUpcommingRide(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getUpcommingRide' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getCancelRide(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getCancelRide' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getCompletedRide(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getCompletedRide' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getCancellationReasonNew(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getCancellationReasonNew' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  validateCancelRide(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'validateCancelRide' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  cancelRideRefund(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'cancelRideRefund' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  webGetInvoice(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'webGetInvoice' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  cancelRide(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'cancelRide' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  webOnewayDetail(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'webOnewayDetail' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  saverFareTypeFetch(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'saverFareTypeFetch' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  routeWithDynamicTimeFare(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'routeWithDynamicTimeFare' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  dynamicWebBookRideOneway(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'dynamicWebBookRideOneway' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  updateWebPaymentType(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'updateWebPaymentType' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  webWalletRedeemStatus(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'webWalletRedeemStatus' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getListCouponCode(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getListCouponCode' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  validateCouponCode(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'validateCouponCode' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  removeCouponCode(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'removeCouponCode' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getAddressList(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getAddressList' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  saveAddressMaster(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'saveAddressMaster' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getPickupCityListPriority(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getPickupCityListPriority' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getDropCityListNew(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'getDropCityListNew' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  bookingDetails(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'bookingDetails' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  readNotes(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'readNotes' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  } 

  webModifyPickupTime(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'webModifyPickupTime' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  corporateOTPVerification(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'corporateOTPVerification' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  companyGstDetails(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'companyGstDetails' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  isPlaceValidWeb(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'isPlaceValidWeb' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  validateCorporateEmail(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'validateCorporateEmail' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  validateCorporateEmailOtp(data : {}) : Observable<{}>{
    return this.http.post(this.apiProperties.webApiURL+'validateCorporateEmailOtp' , data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}