import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date-time-view',
  templateUrl: './date-time-view.component.html',
  styleUrls: ['./date-time-view.component.css'],
})
export class DateTimeViewComponent implements OnInit {
  months: { label: string; value: number }[] = [];
  selectedMonthDays: number[] = [];
  selectedMonth: number = new Date().getMonth();
  selectedMonthIndex: number = 0;
  currentDate: Date = new Date();
  firstDays: number[] = []; // Add this line
  selectedDate: number = new Date().getDate(); // Initialize with the current date

  constructor(private datePipe: DatePipe) {}

  
  ngOnInit() {
    this.generateMonths();
    this.selectedMonthIndex = this.months.findIndex(month => month.value === this.currentDate.getMonth()); // Set selected month index
    this.updateSelectedMonthDays(this.currentDate.getMonth()); // Update selected month days
  }
  
  generateMonths() {
    const currentDate = new Date();
    for (let i = 0; i < 3; i++) {
      const targetDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
      this.months.push({
        label: this.datePipe.transform(targetDate, 'MMMM'),
        value: targetDate.getMonth(),
      });
    }
  }
  
  updateSelectedMonthDays(monthValue: number) {
    const currentDate = new Date();
    const targetDate = new Date(currentDate.getFullYear(), monthValue, 1);
    const daysInMonth = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0).getDate();
    const startDate = monthValue === currentDate.getMonth() ? currentDate.getDate() : 1; // Start from the current date if it's the current month
    this.selectedMonthDays = Array.from({ length: daysInMonth - startDate + 1 }, (_, i) => i + startDate);
  }
  
  onMonthClick(monthValue: number, index: number) {
    this.selectedMonthIndex = index;
    this.selectedMonth = monthValue;
    this.updateSelectedMonthDays(monthValue);

    console.log(this.selectedMonth + 1);
  }

  getDayFirstLetter(month: number, day: number): string {
    const currentDate = new Date();
    const targetDate = new Date(currentDate.getFullYear(), month, day);
    const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    return days[targetDate.getDay()];
  }

  onDayClick(day: number) {
    this.selectedDate = day;
    this.currentDate.setDate(day); // Update the current date to the selected date
  
    const selectedMonth = this.currentDate.getMonth() + 1; // Adding 1 to the month value to correct for 0-based indexing
    const selectedDay = this.currentDate.getDate();
    const selectedYear = this.currentDate.getFullYear();
  
    console.log("Selected Date: " + this.selectedMonth+ " / "+selectedDay+" / "+selectedYear);
  }
  
  
      
}
