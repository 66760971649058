import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';

@Component({
  selector: 'app-feedback-first-ride',
  templateUrl: './feedback-first-ride.component.html',
  styleUrls: ['./feedback-first-ride.component.css']
})
export class FeedbackFirstRideComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();

  constructor( private ows: OnewayWebapiService, private titleService: Title,  private metaTagService: Meta) { }

  fdFirstRideBlk;
  fdFirstRideErrorBlk;
  submitBtn;
  encodedBookId: any;
  bookIdVal: any;
  ngOnInit(): void {
    var hrefURL = window.location.href;
    var hrefURLSplit = hrefURL.split("/prefer/");
    this.encodedBookId = hrefURLSplit[1];
    this.bookIdVal = atob(this.encodedBookId);

    //console.log(this.encodedBookId);
    console.log(this.bookIdVal);

    this.titleService.setTitle("OneWay.Cab : Help us understand you better.");
    this.metaTagService.updateTag({name: 'description', content: '' });

    this.getWebAccessKeyFun();
  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        this.bookingFeedbackInfoFun();
        this.getFirstRideFeedbackReasonListFun();
        
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  bookingFeedbackInfoRes: any=[];
  bookingFeedbackInfoFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      enCodeBookId : this.encodedBookId
    };
    console.log(this.requestData);

    this.ows.bookingFeedbackInfo(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.bookingFeedbackInfoRes = data;
      console.log(this.bookingFeedbackInfoRes);
      
      this.feedbackMessage = this.bookingFeedbackInfoRes.message;

      if(this.bookingFeedbackInfoRes.status == 1){
        this.fdFirstRideBlk = true;
        this.fdFirstRideErrorBlk = false;
      } else {
        this.fdFirstRideBlk = false;
        this.fdFirstRideErrorBlk = true;

        //console.log(this.bookingFeedbackInfoRes.message);
        //(<HTMLInputElement>document.getElementById("successMsg")).value = this.bookingFeedbackInfoRes.message;
        //document.getElementById('successMsg').innerHTML = this.bookingFeedbackInfoRes.message;
      }

    },error =>{
      console.log("feedbackServiceReasonListRes Error :",error);
    });

  }

  firstRideFeedbackReasonListRes: any=[];
  fdfrReasonList: any=[];
  fdGoogleReasonList: any=[];
  fdgArrayList = [];
  fdgArrayListVal: any;
  getFirstRideFeedbackReasonListFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      bookId : this.bookIdVal
    };
    console.log(this.requestData);

    this.ows.getFirstRideFeedbackReasonList(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.firstRideFeedbackReasonListRes = data;
      console.log(this.firstRideFeedbackReasonListRes);

      if(this.firstRideFeedbackReasonListRes.status == 1){

        this.fdFirstRideBlk = true;

        if(this.firstRideFeedbackReasonListRes.feedbackFirstRideReasonList.length > 0){
          this.fdfrReasonList = this.firstRideFeedbackReasonListRes.feedbackFirstRideReasonList;
        }

        if(this.firstRideFeedbackReasonListRes.feedbackGoogleReasonList.length > 0){
          this.fdGoogleReasonList = this.firstRideFeedbackReasonListRes.feedbackGoogleReasonList;

          //this.fdgArrayList = [];
          
          for(let i=0; i< this.fdGoogleReasonList.length; i++){
            //console.log(i);
            //console.log(this.fdGoogleReasonList[i].feedbackServiceReasonId);
            this.fdgArrayList.push(this.fdGoogleReasonList[i].feedbackServiceReasonId);

          }

          this.fdgArrayList.push(1);

          //console.log(this.fdgArrayListVal);
        }
        
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("firstRideFeedbackReasonListRes Error :",error);
    });

  }


  feedbackReason: any=[];
  fdfrMainBtn: any;
  feedbackReasonFun(e,status){
    
    console.log(e);

    var index = this.feedbackReason.indexOf(e);

    if(this.feedbackReason.indexOf(e) == -1){
      this.feedbackReason.push(e);
      document.getElementById(e+status).className = "btn btn-primary mr-2 mt-2";

      if(e == '1'){
        document.getElementById(e+status).className = "btn btn-warning mr-2 mt-2";
        this.fdfrMainInt = true;
        this.fdfrMainBtn = 1;
      }

    } else {
      this.feedbackReason.splice(index, 1);
      document.getElementById(e+status).className = "btn btn-outline-primary mr-2 mt-2";

      if(e == '1'){
        document.getElementById(e+status).className = "btn btn-outline-warning mr-2 mt-2";
        this.fdfrMainInt = false;
        this.fdfrMainBtn = 0;
      }
    }
    console.log(this.feedbackReason);

  }

  badService: any=[];
  fdfrBtn: any;
  fdfrMainInt;
  fdfrService: any=[];
  otherBtnFun(other){

    if(other == "fdfrMainOther"){
      this.fdfrMainInt = true;

      var index = this.fdfrService.indexOf(other);
      if(this.fdfrService.indexOf(other) == -1){
        this.fdfrService.push(other);
        document.getElementById(other).className = "btn btn-warning mr-2 mt-2";
        this.fdfrBtn = 1;
      } else {
        this.fdfrService.splice(index, 1);
        document.getElementById(other).className = "btn btn-outline-warning mr-2 mt-2";
        this.fdfrBtn = 0;
      }

    }

  }

  googleReason: any;
  googleReasonVal: any;
  fdfrOtherInt;
  fdfrOtherBtn: any;
  fdGoogleReasonFun(e,fdg){

    this.googleReasonVal = e;
    // if(e == 5){
    //   this.fdgArrayList.push(e);
    // }

    //console.log(this.fdgArrayList);
    
    this.fdgArrayListVal = this.fdgArrayList;
    var index = this.fdgArrayListVal.indexOf(e);

    console.log(this.fdgArrayListVal.indexOf(e));
    
    this.fdgArrayListVal.splice(index, 1);
    console.log(this.fdgArrayListVal);

    this.googleReason = [];
    this.googleReason.push(e);

      //console.log(this.googleReason.includes(e));

      if(e == '1'){
        this.fdfrOtherInt = true;
        this.fdfrOtherBtn = 1;
      } else {
        this.fdfrOtherInt = false;
        this.fdfrOtherBtn = 0;
      }

      if(this.googleReason.includes(e) == true){
        document.getElementById(e+fdg).className = "btn btn-primary mr-2 mt-2";

        // if(e == 1){
        //   document.getElementById(e+fdg).className = "btn btn-warning mr-2 mt-2";
        // }

        for(let m=0; m< this.fdgArrayListVal.length; m++){   
          document.getElementById(this.fdgArrayListVal[m]+fdg).className = "btn btn-outline-primary mr-2 mt-2";

          // if(e == 1){
          //   document.getElementById(e+fdg).className = "btn btn-outline-warning mr-2 mt-2";
          // }

        }

      } 

      this.fdgArrayListVal.push(e);
    
  }

  insertFirstRideFeedbackRes: any=[];
  likeOther: any;
  otherReason: any;
  otherReferenceReason: any;
  feedbackMessage: any;
  insertFirstRideFeedbackFun(){

    //if(this.improveService.length == 0){ return false; }
    //console.log(this.betterBtn);
    
    //if(this.fdfrMainBtn == 1 || this.feedbackReason.indexOf(5) != -1){
    if(this.fdfrMainBtn == 1){  
      this.otherReason = (<HTMLInputElement>document.getElementById("fdfrMainInt")).value;

      if(this.otherReason == ""){
        alert("Enter Other Remarks");
        return false;
      }

    } else {
      this.otherReason = "";
    }
    

    if(this.fdfrOtherBtn == 1){  
      this.otherReferenceReason = (<HTMLInputElement>document.getElementById("fdfrOtherInt")).value;

      if(this.otherReferenceReason == ""){
        alert("Enter Reference Remarks");
        return false;
      }

    } else {
      this.otherReferenceReason = "";
    }

    // if(this.fdfrBtn == 1){
    //   this.otherReason = (<HTMLInputElement>document.getElementById("fdfrMainInt")).value;
    // } else {
    //   this.otherReason = "";
    // } 

    // console.log(this.feedbackReason.indexOf(5));
    // return false;

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      bookId : this.bookIdVal,
      feedbackReason: this.feedbackReason,
      otherReason: this.otherReason,
      googleReason: this.googleReasonVal,
      otherReferenceReason: this.otherReferenceReason
    };
    console.log(this.requestData);
    //return false;

    this.ows.insertFirstRideFeedback(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.insertFirstRideFeedbackRes = data;
      console.log(this.insertFirstRideFeedbackRes);
      //alert(this.insertBookingFeedbackRes.message);
      this.feedbackMessage = this.insertFirstRideFeedbackRes.message;

      if(this.insertFirstRideFeedbackRes.status == 1){
        this.fdFirstRideBlk = false;
        this.fdFirstRideErrorBlk = true; 
      } else {
        this.fdFirstRideBlk = true;
        //this.fdFirstRideErrorBlk = true;
        alert(this.insertFirstRideFeedbackRes.message);
      }

    },error =>{
      console.log("insertFirstRideFeedbackRes Error :",error);
    });

  }

}