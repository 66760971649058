import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';

@Component({
  selector: 'app-feedback-cancel-ride',
  templateUrl: './feedback-cancel-ride.component.html',
  styleUrls: ['./feedback-cancel-ride.component.css']
})
export class FeedbackCancelRideComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();

  constructor( private ows: OnewayWebapiService, private titleService: Title,  private metaTagService: Meta) { }

  encodedBookId: any;
  bookIdVal: any;
  ngOnInit(): void {
    var hrefURL = window.location.href;
    var hrefURLSplit = hrefURL.split("/cancelRide/");
    this.encodedBookId = hrefURLSplit[1];
    this.bookIdVal = atob(this.encodedBookId);
    //console.log(this.encodedBookId);
    //console.log(this.bookIdVal);

    this.titleService.setTitle("OneWay.Cab : Help us understand you better.");
    this.metaTagService.updateTag({name: 'description', content: '' });

    this.getWebAccessKeyFun();
  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        this.getCancellationReasonFun();
        //this.getFirstRideFeedbackReasonListFun();
        
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  feedbackMessage: any;
  cancellationReasonRes: any=[];
  cancelRideBlk: any;
  buttons: any;
  cancelReasonList: any=[];
  getCancellationReasonFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      encriptId : this.encodedBookId
    };
    console.log(this.requestData);

    this.ows.getCancellationReason(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.cancellationReasonRes = data;
      console.log(this.cancellationReasonRes);
      
      this.feedbackMessage = this.cancellationReasonRes.message;

      if(this.cancellationReasonRes.status == 1){
        this.cancelRideBlk = true;
        this.cancelRideErrorBlk = false;

        // this.buttons = Array.from(document.getElementsByClassName('btn'));
        // console.log(this.buttons);

        this.cancelReasonList = this.cancellationReasonRes.cancelReason;
        for(let i=0; i< this.cancelReasonList.length; i++){
          this.fdgArrayList.push(this.cancelReasonList[i].reasonId);
        }
        this.fdgArrayList.push('other');

      } else {
        this.cancelRideBlk = false;
        this.cancelRideErrorBlk = true;
      }

    },error =>{
      console.log("cancellationReasonRes Error :",error);
    });

  }

  googleReason: any;
  googleReasonVal: any;
  fdfrOtherInt;
  fdfrOtherBtn: any;
  fdgArrayList = [];
  fdgArrayListVal: any;
  

  cancelReason: any;
  otherIdInt;
  cancelFeedbackFun(e,fdg){
     this.cancelReason = e;

     if(this.cancelReason == "other"){
      this.otherIdInt = true;
      this.fdfrOtherBtn = 1;
     } else {
      this.otherIdInt = false;
      this.fdfrOtherBtn = 0;
     }

     
    this.fdgArrayListVal = this.fdgArrayList;
    var index = this.fdgArrayListVal.indexOf(e);
    this.fdgArrayListVal.splice(index, 1);
    console.log(this.fdgArrayListVal);

     this.googleReason = [];
     this.googleReason.push(e);

     if(this.googleReason.includes(e) == true){
      document.getElementById(e+fdg).className = "btn btn-primary mr-2 mt-2";
      for(let m=0; m< this.fdgArrayListVal.length; m++){   
        document.getElementById(this.fdgArrayListVal[m]+fdg).className = "btn btn-outline-primary mr-2 mt-2";
      }
     }
     
     this.fdgArrayListVal.push(e);
  }

  otherReferenceReason: any;
  updateCancelReasonRes: any=[];
  cancelRideErrorBlk;
  updateCancelReasonFun(){

    if(this.fdfrOtherBtn == 1){  
      this.otherReferenceReason = (<HTMLInputElement>document.getElementById("otherIdInt")).value;

      if(this.otherReferenceReason == ""){
        alert("Enter Reference Remarks");
        return false;
      }

    } else {
      this.otherReferenceReason = this.cancelReason;
    }

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      bookId : this.cancellationReasonRes.bookingId,
      cancelReason: this.otherReferenceReason
    };
    console.log(this.requestData);
    //return false;

    this.ows.updateCancelReason(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.updateCancelReasonRes = data;
      console.log(this.updateCancelReasonRes);
      //alert(this.insertBookingFeedbackRes.message);
      this.feedbackMessage = this.updateCancelReasonRes.message;

      if(this.updateCancelReasonRes.status == 1){
        this.cancelRideBlk = false;
        this.cancelRideErrorBlk = true; 
      } else {
        this.cancelRideBlk = true;
        this.cancelRideErrorBlk = true;
        alert(this.updateCancelReasonRes.message);
      }

    },error =>{
      console.log("updateCancelReasonRes Error :",error);
    });

  }

}