<div *ngIf="tripDetail" class="container-fluid">

  <div class="container">

    <div class="row mt-3">
      
      <div class="col-md-3 col-0"></div>

      <div class="col-md-6 col-12">

        <div class="row trip-detail-custom py-3">

          <div class="col-md-12 col-12 text-center">

            <img *ngIf="this.carType == 'hatchback'" src="assets/img/hatchback.png" style="width: 65px; height: 25px;">
            <img *ngIf="this.carType == 'sedan'" src="assets/img/sedan.png" style="width: 65px; height: 25px;">
            <img *ngIf="this.carType == 'suv'" src="assets/img/suv.png" style="width: 65px; height: 25px;">

            <br>

            <!-- <p class="mt-2" style="font-size: 15px;">
              <i class="fa fa-rupee"></i> 1800
            </p> -->

            <p>{{this.pickupCity | titlecase}} to {{this.dropCity | titlecase}}</p>

          </div>

          <div class="col-md-12 col-12">
            <div class="card shadow">
              <div class="card-body">

                <form [formGroup]="tripDetailForm">

                  <div *ngIf="otpVerificationCompleted" class="form-row">
                    <div class="form-group col-md-6 col-6">
                      <label class="grey-label">Name</label>
                      <input type="text" class="custom-input form-control custom-height" 
                        formControlName="passengerName"
                        data-toggle="modal" data-target="#loginModal" readonly>
                    </div>
                    <div class="form-group col-md-6 col-6">
                      <label class="grey-label">Mobile No.</label>
                      <input type="text" class="custom-input form-control custom-height" 
                        formControlName="passengerContactNo" readonly>
                    </div>
                  </div>

                  <div *ngIf="otpVerificationPending" class="form-row">
                    <div class="form-group col-md-6 col-6">
                      <label class="grey-label">Name</label>
                      <input type="text" class="custom-input form-control custom-height yellow-border" id="inputName"
                        data-toggle="modal" data-target="#loginModal" readonly>
                    </div>
                    <div class="form-group col-md-6 col-6">
                      <label class="grey-label">Mobile No.</label>
                      <input type="text" class="custom-input form-control custom-height" id="inputMobile" 
                      data-toggle="modal" data-target="#loginModal" readonly>
                    </div>
                  </div>

                  <div class="form-group">
                    <input type="text" class="custom-input form-control" 
                      formControlName="pickupAddress" id="inputPickupAddress"
                      placeholder="Pickup From" (click)="pickupShow()">
                  </div>
                  <input type="hidden" formControlName="pickupCityLat">
                  <input type="hidden" formControlName="pickupCityLong">

                  <div class="form-group">
                    <input type="text" class="custom-input form-control" 
                       formControlName="dropAddress" id="inputDropAddress" 
                       placeholder="Drop Off At"
                      (click)="dropShow()">
                  </div>
                  <input type="hidden" formControlName="dropCityLat">
                  <input type="hidden" formControlName="dropCityLong">

                  <div class="form-group">
                    <input type="text" class="custom-input form-control" 
                      formControlName="gstNumber" id="inputGST"
                      placeholder="Use GST for Business Travel(Optional)" 
                      (click)="GSTShow()">
                  </div>

                  <div class="form-row">
                    <div class="col-md-12 col-12">
                      <p (click)="passengerShow()" style="cursor: pointer; color: #ffd500;"> 
                        + Add Passenger Detail (Optional)
                      </p>
                    </div>
                  </div>

                  <div *ngIf="alternateBlk" class="row mb-3">
                     <div class="col-md-6 col-6">
                        <label class="grey-label">Passenger Name</label>
                        <input type="text" formControlName="alternateName" class="custom-input form-control custom-height">
                     </div>
                     <div class="col-md-6 col-6">
                        <label class="grey-label">Passenger Mobile</label>
                        <input type="text" formControlName="alternateNumber" class="custom-input form-control custom-height">
                     </div>
                  </div>

                  <div class="form-row">

                    <div class="form-group col-md-12 col-12 mb-0">

                      <ul class="nav nav-tabs custom-tabs" id="myTab" role="tablist" style="border-bottom: 0px;">
                        <li class="nav-item">
                          <a class="nav-link active" id="form-tab" data-toggle="tab" href="#included" role="tab"
                            aria-controls="form" aria-selected="true">INCLUDED</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="tab2-tab" data-toggle="tab" href="#excluded" role="tab"
                            aria-controls="tab2" aria-selected="false">EXCLUDED</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="tab3-tab" data-toggle="tab" href="#note" role="tab"
                            aria-controls="tab3" aria-selected="false">NOTE</a>
                        </li>
                      </ul>

                      <div class="tab-content mt-2" id="myTabContent">
                        <div class="tab-pane fade show active" id="included" role="tabpanel" aria-labelledby="form-tab">
                          <ul class="customUl">
                            <li *ngFor="let includeValue of this.onewayInclude" class="customLi">{{ includeValue }}</li>
                          </ul>
                        </div>
                        <div class="tab-pane fade" id="excluded" role="tabpanel" aria-labelledby="tab2-tab">
                          <ul class="customUl">
                            <li *ngFor="let excludeValue of this.onewayExclude" class="customLi">{{ excludeValue }}</li>
                          </ul>
                        </div>
                        <div class="tab-pane fade" id="note" role="tabpanel" aria-labelledby="tab3-tab">
                          <ul class="customUl">
                            <li *ngFor="let onewayTCValue of this.onewayTC" class="customLi">{{ onewayTCValue }}</li>
                          </ul>
                        </div>
                      </div>

                    </div>

                    <div class="form-group col-md-12 col-12 mb-0 text-center">
                      <button type="submit" class="btn btn-warning py-3 px-5" style="border-radius: 50px;" (click)="pageRedirect()">
                        Select Pickup Date And Time
                      </button>
                    </div>

                  </div>

                </form>

              </div>

            </div>
          </div>

        </div>

      </div>

    </div>

  </div>

</div>

<app-pickup *ngIf="pickup" [webAccessKey]="webAccessKey" (pickupHide)="pickupHide($event)"></app-pickup>
<app-drop *ngIf="drop" [webAccessKey]="webAccessKey" (dropHide)="dropHide($event)"></app-drop>
<app-gst *ngIf="gst" (GSTHide)="GSTHide($event)" [gstDataChild]="gstDataChild"></app-gst>
<app-passenger *ngIf="passenger" [passengerDataChild]="passengerDataChild" (passengerHide)="passengerHide($event)"></app-passenger>

<div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-header pb-1 pt-1">
        <h2 class="modal-title mb-0">Login</h2>
        <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
      </div>

      <div class="modal-body">

        <form *ngIf="loginFormBlk" [formGroup]="loginForm">
          <div class="form-group">
            <input type="text" class="form-control" formControlName="passengerName" placeholder="Name"
            [ngClass]="{ 'is-invalid': submitted && f.passengerName.errors }">

            <div *ngIf="submitted && f.passengerName.errors" class="invalid-feedback">
              <div *ngIf="f.passengerName.errors['required']">Passenger Name is required</div>
            </div>

          </div>

          <div class="row mb-3">

              <div class="col-md-3 col-3" style="padding-right: 0px;">
                
                  <div (click)="toggleCountryList()" class="selected-item">

                        <div *ngIf="selectedCountry" style="padding-left: 5px; font-size: 11px;">
                          <!-- Display selected country in the dropdown -->
                          <img src="{{selectedCountry.countryFlag}}" alt="" />
                          {{selectedCountry.countryCode}}
                          <input type="hidden" id="allowDigit" value="{{selectedCountry.allowDigit}}">
                          <input type="hidden" id="countryCodeVal" value="{{selectedCountry.countryCode}}">
                        </div>

                        <ul *ngIf="countryListOpen || !selectedCountry" class="list" id="list">
                          <li *ngFor="let data of countryFlagListRes.listOfCountry" (click)="selectCountry(data)"
                              [class.selected]="data === selectedCountry">
                            <img src="{{data.countryFlag}}" alt="" />
                            {{data.countryCode}}
                            <input type="hidden" id="allowDigit" value="{{data.allowDigit}}">
                            <input type="hidden" id="countryCodeVal" value="{{data.countryCode}}">
                          </li>
                        </ul>
                  </div>
                    
              </div>

              <div class="col-md-9 col-9">
                <input type="tel" class="form-control" formControlName="mobileNumber" maxlength="10"
                (keypress)="keyPressForNumber($event)" placeholder="Mobile Number" 
                [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }">

                <div *ngIf="submitted && f.mobileNumber.errors" class="invalid-feedback">
                  <div *ngIf="f.mobileNumber.errors['required']">Mobile Number is required</div>
                </div>
              </div>

          </div>
          <div class="form-group">
            <input type="text" class="form-control" formControlName="email" placeholder="Email Id" (keypress)="validateEmail($event)"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors['required']">Email is required</div>
            </div>
          </div>
          <div *ngIf="isEmailInvalid" class="text-danger">
            Invalid email address.
          </div>
          <div class="form-group text-right">
            <label class="text-secondary skipText" data-dismiss="modal"> SKIP </label>
            <button *ngIf="mobileNumberSubmit" class="btn btn-success"
              (click)="getLoginRegistrationFun()">Submit</button>
            <button *ngIf="mobileNumberInProcess" class="btn btn-danger" (click)="getLoginRegistrationFun()">In
              Process...</button>
          </div>
        </form>

        <form *ngIf="otpFormBlk" [formGroup]="otpForm">
          <div class="form-group">
            <!-- <label for="name">Enter OTP</label> -->
            <input type="text" class="form-control" formControlName="otpNumber" placeholder="Enter OTP" required>
          </div>

          <div class="form-group text-right">
            <span class="mr-5" style="cursor: pointer;" (click)="resendOTPFun()">Resend OTP</span>
            <label class="text-secondary skipText" data-dismiss="modal"> SKIP </label>
            <button type="submit" class="btn btn-primary" (click)="webOTPVerificationFun()">Confirm</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>