<!-- horizontal-list.component.html -->
<div class="horizontal-list-container">
  <ng-container *ngFor="let dateTime of dateTimes">
    <div class="date-time-item">
      <p>Date: {{ dateTime.date }}</p>
      <p>Time: {{ dateTime.time }}</p>
    </div>
  </ng-container>
</div>


<div class="container-fluid">
  <div class="container">
    <div class="row mt-3">
      <div class="col-md-6 col-12">
        <div class="row">
          <div class="col-md-12 col-12 text-center">
            <i class="fa fa-credit-card" aria-hidden="true"></i>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>