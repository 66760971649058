<div *ngIf="visitorDetail" class="container-fluid">

  <div class="container">

    <div class="row mt-3">

      <div class="col-md-6 col-12">

        <div class="row trip-detail-custom py-3">

          <div class="col-md-12 col-12">
            <div class="card shadow">
              <div class="card-body">

                <div class="row">
                  <div class="col-md-12 col-12 mb-2 text-center">
                    <h3 class="mb-0 font-weight-bold">Address Confirmation</h3>
                    <hr>
                  </div>
                </div>

                <form *ngIf="verificationPending" [formGroup]="visitorAddressForm">

                  <div class="row">

                    <!-- <div class="col-md-6 col-6 mb-2">
                      <input type="text" class="custom-input form-control custom-height" formControlName="visitorName"
                        placeholder="Name">
                    </div> -->

                    <div class="col-md-12 col-12 mb-2">
                      <label class="inputLabel" style="background: #008bd2; color: #ffd500; font-size: 14px;">Logged in as</label>
                      <input type="text" class="inputText" formControlName="visitorNumber" placeholder="Mobile Number" style="padding: 10px 10px 10px 120px !important;" readonly />
                    </div>

                    <div class="col-md-12 col-12 mb-2" (click)="visitorCityShow()" (keypress)="visitorCityShow()">
                      <label class="inputLabel">City Name</label>
                      <input type="text" #visitorCityMain id="visitorCity" class="inputText" formControlName="visitorCityName" placeholder="Enter City Name" [ngClass]="{'error-input': visitorCityBorder }" autofocus />
                      
                      <input type="hidden" formControlName="visitorCityId">
                      <input type="hidden" formControlName="visitorCityLat">
                      <input type="hidden" formControlName="visitorCityLong">
                    </div>

                    <div class="col-md-12 col-12 mb-2" (click)="visitorAddressShow()">
                      <label class="inputLabel">Address</label>
                      <input type="text" #visitorAddressMain class="inputText" formControlName="visitorAddress" placeholder="Enter Address" [ngClass]="{'error-input': visitorAddressBorder }" />
                    </div>

                    <div class="col-md-12 col-12 mb-2" (click)="visitorAreaShow()">
                      <label class="inputLabel">Area</label>
                      <input type="text" #visitorAreaMain class="inputText" formControlName="visitorAreaName" placeholder="Enter Area" [ngClass]="{'error-input': visitorAreaBorder }" />

                      <input type="hidden" formControlName="visitorAreaId">
                    </div>

                    <div class="col-md-12 col-12 mb-2" (click)="visitorSocietyShow()">
                      <label class="inputLabel">Society</label>
                      <input type="text" #visitorSocietyMain class="inputText" formControlName="visitorSocietyName" placeholder="Society Name" [ngClass]="{'error-input': visitorSocietyBorder }" />

                      <input type="hidden" formControlName="visitorSocietyId">
                    </div>

                    <div class="col-md-6 col-6 mb-2 pr-2">
                      <label class="inputLabel">Tower Name</label>
                      <input type="text" #visitorTowerMain class="inputText" formControlName="visitorTowerName" placeholder="Tower Name" />
                    </div>

                    <div class="col-md-6 col-6 mb-2 pl-1">
                      <label class="inputLabel">Flat Number</label>
                      <input type="text" class="inputText" formControlName="visitorFlatNumber" placeholder="Flat Number" 
                      (keypress)="keyPressForNumber($event)" />
                    </div>

                    <div class="col-md-12 col-12 mb-2 text-right">
                      <button class="btn btn-success" style="width: 50%;" (click)="saveUserSocietyDetails()"> Save </button>
                    </div>

                  </div>

                </form>

                <div *ngIf="verificationDone" class="row">
                  <div class="col-md-12 col-12 mb-2">
                    <h3 class="mb-0 text-success">Visitor address verification done successfully.</h3>
                  </div>
                  <div class="col-md-12 col-12 text-right">
                    <button class="btn btn-warning" (click)="visitorAddressFormReset()">Close</button>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>

      </div>

    </div>

  </div>

</div>

<div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-header pb-1 pt-1">
        <h2 class="modal-title mb-0">Login</h2>
      </div>

      <div class="modal-body">

        <form *ngIf="loginFormBlk" [formGroup]="loginForm">

          <!-- <div class="form-group">
            <input type="text" class="form-control" formControlName="passengerName" placeholder="Name" required>
          </div> -->

          <div class="row mb-3">

            <div class="col-md-3 col-3" style="padding-right: 0px;">

              <div (click)="toggleCountryList()" class="selected-item">

                <div *ngIf="selectedCountry" style="padding-left: 5px; font-size: 11px;">
                  <img src="{{selectedCountry.countryFlag}}" alt="" />
                  {{selectedCountry.countryCode}}
                  <input type="hidden" id="allowDigit" value="{{selectedCountry.allowDigit}}">
                  <input type="hidden" id="countryCodeVal" value="{{selectedCountry.countryCode}}">
                </div>

                <ul *ngIf="countryListOpen || !selectedCountry" class="list" id="list">
                  <li *ngFor="let data of countryFlagListRes.listOfCountry" (click)="selectCountry(data)"
                    [class.selected]="data === selectedCountry">
                    <img src="{{data.countryFlag}}" alt="" />
                    {{data.countryCode}}
                    <input type="hidden" id="allowDigit" value="{{data.allowDigit}}">
                    <input type="hidden" id="countryCodeVal" value="{{data.countryCode}}">
                  </li>
                </ul>
              </div>

            </div>

            <div class="col-md-9 col-9">
              <input type="tel" class="form-control" formControlName="mobileNumber" maxlength="10"
                (keypress)="keyPressForNumber($event)" placeholder="Mobile Number" required autofocus>
            </div>

          </div>

          <!-- <div class="form-group">
            <input type="text" class="form-control" formControlName="email" placeholder="Email Id"
              (keypress)="validateEmail($event)" required>
          </div>
          <div *ngIf="isEmailInvalid" class="text-danger">
            Invalid email address.
          </div> -->

          <div class="form-group text-right">
            <!-- <label class="text-secondary skipText mr-2" data-dismiss="modal"> SKIP </label> -->
            <button *ngIf="mobileNumberSubmit" class="btn btn-success"
              (click)="getLoginRegistrationFun()">Submit</button>
            <button *ngIf="mobileNumberInProcess" class="btn btn-danger" (click)="getLoginRegistrationFun()">In
              Process...</button>
          </div>

        </form>


        <form *ngIf="otpFormBlk" [formGroup]="otpForm">
          <div class="form-group">
            <input type="text" class="form-control" formControlName="otpNumber" placeholder="Enter OTP" required autofocus>
          </div>

          <div class="form-group text-right">
            <span class="mr-5" style="cursor: pointer;" (click)="resendOTPFun()">Resend OTP</span>
            <!-- <label class="text-secondary skipText" data-dismiss="modal"> SKIP </label> -->
            <button type="submit" class="btn btn-primary" (click)="webOTPVerificationFun()">Confirm</button>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>

<app-visitor-city *ngIf="visitorCity" (visitorCityHide)="visitorCityHide($event)"></app-visitor-city>
<app-visitor-address *ngIf="pickup" [webAccessKey]="webAccessKey" [visitorCityName]="visitorCityName" (visitorAddressHide)="visitorAddressHide($event)"></app-visitor-address>
<app-visitor-area *ngIf="visitorArea" [visitorCityId]="visitorCityId" [visitorCityName]="visitorCityName" (visitorAreaHide)="visitorAreaHide($event)"></app-visitor-area>
<app-visitor-society *ngIf="visitorSociety" [visitorAreaId]="visitorAreaId" (visitorSocietyHide)="visitorSocietyHide($event)"></app-visitor-society>