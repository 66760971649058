import { Component, OnInit, Input, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.css']
})
export class CouponsComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();

  constructor(private fb: FormBuilder, 
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private ows: OnewayWebapiService) { 
      
    }

  userId: any; 
  isCorporateId: any;
  couponListBlk;
  ngOnInit(): void {
    //this.userId = "103594";
    this.userId = sessionStorage.getItem('userId');
    if(!this.userId){
      console.log("if");
      //this.router.navigate(['book/'+this.pickupCity+'/'+this.dropCity+'/'+this.carType]);
      this.router.navigate(['/']);
    }
    console.log(this.userId);

    this.isCorporateId = sessionStorage.getItem('isCorporateId');
    if(this.isCorporateId == 1){
      this.couponListBlk = true; 
      this.validateCorporateEmailBtnBlk = false;
      this.validateCorporateEmailBlk = false;
    } else {
      this.couponListBlk = false;
      this.validateCorporateEmailBtnBlk = true;
      // this.validateCorporateEmailBlk = true;
    }

    this.getWebAccessKeyFun();

  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        this.getListCouponCode();
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  validateCorporateEmailBtnBlk;
  listCouponCodeRes: any=[];
  corporateCoupon: any;
  otherCoupon: any;
  getListCouponCode(){

    //this.validateCorporateEmailBtnBlk = true;
    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      userId : this.userId
    };
    //console.log(this.requestData);

    this.ows.getListCouponCode(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.listCouponCodeRes = data;
      console.log(this.listCouponCodeRes);

      this.corporateCoupon = [];
      this.otherCoupon = [];

      if(this.listCouponCodeRes.status == 1){
        console.log(this.listCouponCodeRes.couponList.length);

        const couponListLength = this.listCouponCodeRes.couponList.length;
        for(var i=0; i<couponListLength; i++){
          if(this.listCouponCodeRes.couponList[i].isCorporateCoupon == 1){
            //this.validateCorporateEmailBtnBlk = false;
            //return;

            this.corporateCoupon.push({
              'couponCode': this.listCouponCodeRes.couponList[i].couponCode,
              'couponType': this.listCouponCodeRes.couponList[i].couponType,
              'discount': this.listCouponCodeRes.couponList[i].discount,
              'maxDiscount': this.listCouponCodeRes.couponList[i].maxDiscount,
              'title': this.listCouponCodeRes.couponList[i].title,
              'description': this.listCouponCodeRes.couponList[i].description,
              'iconPath': this.listCouponCodeRes.couponList[i].iconPath,
              'terms': this.listCouponCodeRes.couponList[i].terms,
              'isCorporateCoupon': this.listCouponCodeRes.couponList[i].isCorporateCoupon
            })

          } else {
            this.otherCoupon.push({
              'couponCode': this.listCouponCodeRes.couponList[i].couponCode,
              'couponType': this.listCouponCodeRes.couponList[i].couponType,
              'discount': this.listCouponCodeRes.couponList[i].discount,
              'maxDiscount': this.listCouponCodeRes.couponList[i].maxDiscount,
              'title': this.listCouponCodeRes.couponList[i].title,
              'description': this.listCouponCodeRes.couponList[i].description,
              'iconPath': this.listCouponCodeRes.couponList[i].iconPath,
              'terms': this.listCouponCodeRes.couponList[i].terms,
              'isCorporateCoupon': this.listCouponCodeRes.couponList[i].isCorporateCoupon
            })
          }
        }

        //if(this.corporateCoupon.length == 0){}

        // if(sessionStorage.getItem('corporateCouponValidation') == '1'){
        //   //this.corporateEmailValidationDoneBlk = true;
        //   this.validateCorporateEmailBtnBlk = false;
        //   this.validateCorporateEmailBlk = false;
        //   this.couponListBlk = true;
        // } else {
        //   this.validateCorporateEmailBtnBlk = true;
        // }

      } else {}

    },error =>{
      console.log("listCouponCodeRes error :",error);
    });

  }

  toggleDetails(discount: any) {
    discount.showDetails = !discount.showDetails;
  }

  CorporateForm = this.fb.group({
    corporateEmailId : ["", [Validators.required, Validators.email]],
    otpNumber : [""]
  })

  get f(){
    return this.CorporateForm.controls;
  }

  validateCorporateEmailBlk;
  emailIdScreen;
  otpScreen;
  inProcess;
  validateCorporateEmailShow(){
    this.validateCorporateEmailBtnBlk = false;
    this.validateCorporateEmailBlk = true;
    this.emailIdScreen = true;
    this.otpScreen = false;
    this.inProcess = false;
  }

  validateCorporateEmailHide(){
    this.validateCorporateEmailBtnBlk = true;
    this.validateCorporateEmailBlk = false;
    this.emailIdScreen = false;
    this.otpScreen = true;
    this.inProcess = true;
  }

  submitted = false;
  corporateEmailId: any;
  validateCorporateEmailRes: any=[];
  validateCorporateEmail(){
    this.validateCorporateEmailBtnBlk = false;
    this.submitted = true;

    if(this.CorporateForm.invalid){
      return false;
    }

    this.corporateEmailId = this.CorporateForm.controls['corporateEmailId'].value;

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      userId : this.userId,
      corporateEmailId : this.CorporateForm.controls['corporateEmailId'].value
    };
    console.log(this.requestData);
    //return false;

    this.ows.validateCorporateEmail(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.validateCorporateEmailRes = data;
      console.log(this.validateCorporateEmailRes);

      if(this.validateCorporateEmailRes.status == 1){
        console.log(this.validateCorporateEmailRes);
        this.emailIdScreen = false;
        this.otpScreen = true;  
      } else {
        this.emailIdScreen = true;
        this.otpScreen = false;
        alert(this.validateCorporateEmailRes.message);
      }

    },error =>{
      console.log("validateCorporateEmailRes error :",error);
    });

  }

  //corporateEmailValidationDoneBlk = false;
  validateCorporateEmailOtpRes: any=[];
  validateCorporateEmailOtp(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      userId : this.userId,
      corporateEmailId : this.corporateEmailId,
      otp : this.CorporateForm.controls['otpNumber'].value
    };
    console.log(this.requestData);

    this.ows.validateCorporateEmailOtp(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.validateCorporateEmailOtpRes = data;
      console.log(this.validateCorporateEmailOtpRes);

      if(this.validateCorporateEmailOtpRes.status == 1){
        console.log(this.validateCorporateEmailOtpRes);
        sessionStorage.setItem('corporateCouponValidation','1');
        sessionStorage.setItem('isCorporateId', '1');
      } else {
        sessionStorage.setItem('isCorporateId', '0');
        alert(this.validateCorporateEmailOtpRes.message);
      }
      //this.corporateEmailValidationDoneBlk = true;
      this.validateCorporateEmailBtnBlk = false;
      this.validateCorporateEmailBlk = false;
      
      window.location.reload();

    },error =>{
      console.log("validateCorporateEmailOtpRes error :",error);
    });

  }

}