<div class="container">

  <app-normal-corporate-login-bar></app-normal-corporate-login-bar>
  
  <div class="row">
    <div class="col-md-12 col-12 mt-2">
      <ul class="nav nav-tabs custom-tabs" id="myTab" role="tablist" style="border-bottom: 0px;">
        <li class="nav-item">
          <a class="nav-link" href="/upcommingTrip" role="tab">UpComming Trip</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/completedTrip" role="tab">Completed Trip</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" href="/cancelTrip" role="tab">Cancel Trip</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="row mt-2">

    <div *ngFor="let data of this.cancelRideData" class="col-md-4 col-12 mb-3">

      <mat-card style="overflow: hidden;">
        <mat-card-title>{{data.displayBookingId}}</mat-card-title>
        <mat-card-subtitle>{{data.tripType}}</mat-card-subtitle>
        <mat-card-content>
          <p>Pickup @ {{data.pickupDateTime}}</p>
        </mat-card-content>
        <mat-card-actions align="end">
          <button class="btn btn-success" (click)="bookingInfo(data.displayBookingId)">Info</button>
        </mat-card-actions>
      </mat-card>

    </div>

    <div class="col-md-2 col-2">
      <button *ngIf="loadMoreCancelled" class="btn btn-warning" (click)="getCancelRideFun(this.offset)">Load
        More</button>
    </div>

  </div>

</div>