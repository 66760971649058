import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { OnewayPartnerEnrolApiService } from '../../service/oneway-partner-enrol-api.service';
import { OnewayCustomerAddressService } from '../../service/oneway-customer-address.service';

@Component({
  selector: 'app-visitor-address-verify',
  templateUrl: './visitor-address-verify.component.html',
  styleUrls: ['./visitor-address-verify.component.css']
})
export class VisitorAddressVerifyComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @ViewChild('visitorCityMain') visitorCityMain: ElementRef;
  @ViewChild('visitorAddressMain') visitorAddressMain: ElementRef;
  @ViewChild('visitorAreaMain') visitorAreaMain: ElementRef;
  @ViewChild('visitorSocietyMain') visitorSocietyMain: ElementRef;
  @ViewChild('visitorTowerMain') visitorTowerMain: ElementRef;

  constructor( private fb: FormBuilder, 
               private router: Router,
               private route: ActivatedRoute,
               private ows: OnewayWebapiService,
               private opeas: OnewayPartnerEnrolApiService, 
               private ocas: OnewayCustomerAddressService,
               private titleService: Title,  
               private metaTagService: Meta) { }

  otpVerificationCompleted;
  otpVerificationPending;
  verificationPending;
  verificationDone;
  isAddressDisabled = true;
  isAreaDisabled = true;

  visitorCityBorder: boolean = false;
  visitorAddressBorder: boolean = false;
  visitorAreaBorder: boolean = false;
  visitorSocietyBorder: boolean = false;

  ngOnInit(): void {
    this.visitorDetail = true;
    this.getWebAccessKeyFun();
    this.getCountryFlagListFun();

    console.log("userId :: "+sessionStorage.getItem('userId'));

    if(sessionStorage.getItem('userId') == "" || sessionStorage.getItem('userId') == null){
      this.otpVerificationCompleted = false;
      this.otpVerificationPending = true;

      this.openModal();
    } else {
      this.otpVerificationCompleted = true;
      this.otpVerificationPending = false;

      //this.visitorAddressForm.controls['visitorName'].setValue(sessionStorage.getItem('passengerName'));
      this.visitorAddressForm.controls['visitorNumber'].setValue(sessionStorage.getItem('mobileNo'));

      this.visitorAddressForm.controls['visitorAddress'].disable();
      this.visitorAddressForm.controls['visitorAreaName'].disable();
      this.visitorAddressForm.controls['visitorSocietyName'].disable();
      this.visitorAddressForm.controls['visitorTowerName'].disable();
      this.visitorAddressForm.controls['visitorFlatNumber'].disable();

      this.visitorCityBorder = true;
      
      console.log(sessionStorage.getItem('visitorCityId'));
      if(sessionStorage.getItem('visitorCityId') != "" && sessionStorage.getItem('visitorCityId') != null){
        console.log("visitorCityId");
        this.visitorAddressForm.controls['visitorCityId'].setValue(sessionStorage.getItem('visitorCityId'));
        this.visitorAddressForm.controls['visitorCityName'].setValue(sessionStorage.getItem('visitorCityName')+" , "+sessionStorage.getItem('visitorStateName'));
        this.visitorAddressForm.controls['visitorCityLat'].setValue(sessionStorage.getItem('visitorCityLat'));
        this.visitorAddressForm.controls['visitorCityLong'].setValue(sessionStorage.getItem('visitorCityLong'));

        this.visitorCityId = sessionStorage.getItem('visitorCityId');
        this.visitorCityName = sessionStorage.getItem('visitorCityName');

        this.visitorAddressForm.controls['visitorAddress'].enable();
        //this.visitorAddressMain.nativeElement.focus();

        this.visitorCityBorder = false;
        this.visitorAddressBorder = true;
        this.visitorAreaBorder = false;
        this.visitorSocietyBorder = false;
      } 

      if(sessionStorage.getItem('visitorAddress') !="" && sessionStorage.getItem('visitorAddress') != null){
        this.visitorAddressForm.controls['visitorAddress'].setValue(sessionStorage.getItem('visitorAddress'));

        if(sessionStorage.getItem('visitorAreaName') == "" && sessionStorage.getItem('visitorSocietyName') ==""){
          this.getSocietyAreaDetailsByAddress(sessionStorage.getItem('visitorAddress'));
        }
        this.visitorAddressForm.controls['visitorAreaName'].enable();

        this.visitorCityBorder = false;
        this.visitorAddressBorder = false;
        this.visitorAreaBorder = true;
        this.visitorSocietyBorder = false;
        
      }

      console.log("visitorAreaId :: "+sessionStorage.getItem('visitorAreaId'));
      if(sessionStorage.getItem('visitorAreaId') != "" && sessionStorage.getItem('visitorAreaId') != "0"){
        console.log("Area If");
        this.visitorAddressForm.controls['visitorAreaId'].setValue(sessionStorage.getItem('visitorAreaId'));
        this.visitorAddressForm.controls['visitorAreaName'].setValue(sessionStorage.getItem('visitorAreaName'));

        this.visitorAreaId = sessionStorage.getItem('visitorAreaId'); 
        this.visitorAreaName = sessionStorage.getItem('visitorAreaName');

        if(sessionStorage.getItem('visitorAreaId') == null){
          this.visitorAddressForm.controls['visitorSocietyName'].disable();
        } else {
          this.visitorAddressForm.controls['visitorSocietyName'].enable();

          this.visitorCityBorder = false;
          this.visitorAddressBorder = false;
          this.visitorAreaBorder = false;
          this.visitorSocietyBorder = true;
        }
        
      }

      console.log("visitorSocietyId :: "+sessionStorage.getItem('visitorSocietyId'));
      console.log("visitorSocietyName :: "+sessionStorage.getItem('visitorSocietyName'));
      if(sessionStorage.getItem('visitorSocietyId') != "" || sessionStorage.getItem('visitorSocietyId') != "0"){
        console.log("visitorSocietyId");
        this.visitorAddressForm.controls['visitorSocietyId'].setValue(sessionStorage.getItem('visitorSocietyId'));
        this.visitorAddressForm.controls['visitorSocietyName'].setValue(sessionStorage.getItem('visitorSocietyName'));

        this.visitorSocietyId = sessionStorage.getItem('visitorSocietyId'); 
        this.visitorSocietyName = sessionStorage.getItem('visitorSocietyName');

        if(sessionStorage.getItem('visitorSocietyName') == null || sessionStorage.getItem('visitorSocietyName') == ""){
          console.log("visitorSocietyName if :: "+sessionStorage.getItem('visitorSocietyName'));
          this.visitorAddressForm.controls['visitorTowerName'].disable();
          this.visitorAddressForm.controls['visitorFlatNumber'].disable();
        } else {
          this.visitorAddressForm.controls['visitorTowerName'].enable();
          this.visitorAddressForm.controls['visitorFlatNumber'].enable();

          console.log("visitorSocietyName else :: "+sessionStorage.getItem('visitorSocietyName'));

          this.visitorCityBorder = false;
          this.visitorAddressBorder = false;
          this.visitorAreaBorder = false;
          this.visitorSocietyBorder = false;
        }

      }

    }  

    this.verificationPending = true;
    this.verificationDone = false;
    //document.getElementById("visitorCity").focus(); 

  }

  // Open the modal
  openModal() {

    var modal = document.getElementById('loginModal');

    if (modal) {
      // Add the 'show' class to the modal and remove 'd-none' class
      modal.classList.add('show');
      modal.style.display = 'block';

      this.loginFormBlk = true;
      this.otpFormBlk = false;
      this.mobileNumberSubmit = true;
      this.mobileNumberInProcess = false;

      // Trigger the modal's backdrop to show
      var backdrop = document.createElement('div');
      backdrop.classList.add('modal-backdrop', 'fade', 'show');
      document.body.appendChild(backdrop);
    }
  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        //this.getCancellationReasonFun();
      } else { }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  countryFlagListRes: any=[];
  countryFlagList;
  getCountryFlagListFun(){

    this.opeas.getCountryFlagList().subscribe((data : {})=>{
      this.countryFlagListRes = data;
      if(this.countryFlagListRes.status == 1){
        console.log(this.countryFlagListRes);
        //this.selectedCountry = this.countryFlagListRes.listOfCountry.find(country => country.countryCode === '91');
        this.selectedCountry = this.countryFlagListRes.listOfCountry[0];
        //console.log(this.selectedCountry);
        this.countryFlagList = true;
      } else { }

    },error =>{
      console.log("countryFlagListRes error :",error);
    });

  }

  //new added code
  countryListOpen = false; // Set it to false initially
  selectedCountry: any;

  toggleCountryList() {
    this.countryListOpen = !this.countryListOpen;
  }

  selectCountry(country: any) {
    this.selectedCountry = country;
    //console.log(this.selectedCountry);
    //this.countryListOpen = false;
  }
  //end new added code

  removeHyphensAndTitleCase(inputString) {
    // Split the string into words based on hyphens
    const words = inputString.split('-');
    
    // Capitalize the first letter of each word and join them with spaces
    const titleCaseString = words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  
    return titleCaseString;
  }

  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  isEmailInvalid: boolean = false;
  validateEmail(event: any) {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Check if the input matches the email pattern
    this.isEmailInvalid = !emailPattern.test(event.target.value);
  }

  loginForm = this.fb.group({
    // passengerName: ["",[Validators.required]],
    mobileNumber: ["",[Validators.required,Validators.minLength(10),Validators.maxLength(10),Validators.pattern("^[0-9]*$")]],
    // email: ["",[Validators.required]],
  });

  loginFormBlk;
  otpFormBlk;
  mobileNumberSubmit;
  mobileNumberInProcess;
  mobileNumberDisplay: any;
  loginRegistrationRes: any=[];
  hiddenInputValue: any;
  finalMobileNumber: any;
  finalMobileNumberPlus: any;
  getLoginRegistrationFun(){

    // if(this.loginForm.invalid){
    //   alert("Invalid Login Form");
    //   return false;
    // }

    this.mobileNumberSubmit = false;
    this.mobileNumberInProcess = true;

    const hiddenInput = document.getElementById('countryCodeVal') as HTMLInputElement | null;
    // Check if the element exists
    if(hiddenInput) {
      this.hiddenInputValue = hiddenInput.value;
      
    } else {
      alert("Please select country code");
      return false;
    }

    this.finalMobileNumber = this.hiddenInputValue+this.loginForm.controls['mobileNumber'].value;
    this.finalMobileNumberPlus = "+"+this.hiddenInputValue+this.loginForm.controls['mobileNumber'].value;
    
    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNo : this.loginForm.controls['mobileNumber'].value,
      countryCode : this.hiddenInputValue,
      //mobileNo : this.finalMobileNumberPlus,
      //passengerName : this.loginForm.controls['passengerName'].value,
      //email : this.loginForm.controls['email'].value
      passengerName : "",
      email : ""
    };
    console.log(this.requestData);
    //return false;

    this.ows.getLoginRegistration(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.loginRegistrationRes = data;
      console.log(this.loginRegistrationRes);      
      //this.feedbackMessage = this.cancellationReasonRes.message;

      if(this.loginRegistrationRes.status == 1){
        this.mobileNumberDisplay = this.loginForm.controls['mobileNumber'].value;
        this.mobileNumberSubmit = false;
        this.mobileNumberInProcess = false;

        this.loginFormBlk = false;
        this.otpFormBlk = true;

      } else {
        alert(this.loginRegistrationRes.message);
        this.mobileNumberSubmit = true;
        this.mobileNumberInProcess = false;

        this.loginFormBlk = true;
        this.otpFormBlk = false;
      }

    },error =>{
      console.log("loginRegistrationRes Error :",error);
    });

  }

  otpForm = this.fb.group({
    otpNumber: ["",[Validators.required]],
  });

  mobileNo: any;
  otpVerificationRes: any = [];
  webOTPVerificationFun(){

    if(this.otpForm.invalid){
      return false;
    }

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNo : this.loginForm.controls['mobileNumber'].value,
      //mobileNo : this.finalMobileNumberPlus,
      otp : this.otpForm.controls['otpNumber'].value,
      loginFrom : ""
    };
    console.log(this.requestData);

    this.ows.webOTPVerification(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.otpVerificationRes = data;
      console.log(this.otpVerificationRes);
      
      //this.feedbackMessage = this.cancellationReasonRes.message;

      if(this.otpVerificationRes.userId != ""){
        this.mobileNo = this.loginForm.controls['mobileNumber'].value;
        sessionStorage.setItem('mobileNo', this.loginForm.controls['mobileNumber'].value);
        //sessionStorage.setItem('mobileNo', this.finalMobileNumber);
        //sessionStorage.setItem('mobileNo', this.finalMobileNumberPlus);
        sessionStorage.setItem('userId', this.otpVerificationRes.userId);
        sessionStorage.setItem('customerEmailId', this.otpVerificationRes.customerEmailId);
        sessionStorage.setItem('passengerName', this.otpVerificationRes.passangerName);
        sessionStorage.setItem('countryCode', this.hiddenInputValue);

        //this.getUserWalletAmountFun(this.otpVerificationRes);

        this.addUpdateWebUserFun(this.otpVerificationRes, this.loginForm.controls['mobileNumber'].value,this.hiddenInputValue);
        //this.addUpdateWebUserFun(this.otpVerificationRes, this.finalMobileNumber);
        //this.addUpdateWebUserFun(this.otpVerificationRes, this.finalMobileNumberPlus);
        
      } else {
        sessionStorage.setItem('mobileNo', "");
        sessionStorage.setItem('userId', "");
        sessionStorage.setItem('customerEmailId', "");
        sessionStorage.setItem('passengerName', "");
      }

      //window.location.reload();

    },error =>{
      console.log("otpVerificationRes Error :",error);
    });

  }

  addUpdateWebUserRes: any=[];
  addUpdateWebUserFun(otpVerificationRes, mobileNumber, hiddenInputValue){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNumber : mobileNumber,
      countryCode : hiddenInputValue,
      customerName : otpVerificationRes.passangerName,
      emailId : otpVerificationRes.customerEmailId
    };
    console.log(this.requestData);

    this.ows.addUpdateWebUser(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.addUpdateWebUserRes = data;
      console.log("addUpdateWebUser :: "+JSON.stringify(this.addUpdateWebUserRes));
      //return false;
      //this.Message = this.addUpdateWebUserRes.message;
      // if(this.addUpdateWebUserRes.status == 1){} 
      // else {}

      window.location.reload();

    },error =>{
      console.log("addUpdateWebUserRes Error :",error);
    });

  }

  resendOTPRes: any=[];
  resendOTPFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNo : this.loginForm.controls['mobileNumber'].value,
      //mobileNo : this.finalMobileNumberPlus,
    };
    console.log(this.requestData);

    this.ows.resendOTP(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.resendOTPRes = data;
      console.log("resendOTP :: "+JSON.stringify(this.resendOTPRes));
      //this.Message = this.resendOTPRes.message;

      alert(this.resendOTPRes.message);

      // if(this.resendOTPRes.status == 0){
      // } else {}

    },error =>{
      console.log("resendOTPRes Error :",error);
    });

  }

  visitorAddressForm = this.fb.group({
    //visitorName: [""],
    visitorNumber: [""],
    visitorCityId: ["",[Validators.required]],
    visitorCityName: [""],
    visitorCityLat: [""],
    visitorCityLong: [""],
    visitorAddress: [""],
    visitorAreaId: [""],
    visitorAreaName: [""],
    visitorSocietyId: [""],
    visitorSocietyName: ["",[Validators.required]],
    visitorTowerName: ["",[Validators.required]],
    visitorFlatNumber: ["",[Validators.required]]
  });

  societyAreaDetailsByAddressRes: any=[];
  getSocietyAreaDetailsByAddress(addressVal){

    this.requestData = {
      address : addressVal
    };
    console.log(this.requestData);

    this.ocas.getSocietyAreaDetailsByAddress(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.societyAreaDetailsByAddressRes = data;
      
      if(this.societyAreaDetailsByAddressRes.status == 1){
        console.log(this.societyAreaDetailsByAddressRes);
        // this.listofAddressData = this.getAddressListRes.listofAddress;
        // console.log(this.listofAddressData.length);
        // this.listofAddressDataBlk = true;

        this.visitorAreaId = this.societyAreaDetailsByAddressRes.areaId; 
        this.visitorAreaName = this.societyAreaDetailsByAddressRes.areaName;
        this.visitorAddressForm.controls['visitorAreaId'].setValue(this.societyAreaDetailsByAddressRes.areaId);
        this.visitorAddressForm.controls['visitorAreaName'].setValue(this.societyAreaDetailsByAddressRes.areaName);
        
        if(sessionStorage.getItem('visitorAreaName') == ""){
          sessionStorage.setItem('visitorAreaId',this.societyAreaDetailsByAddressRes.areaId);
          sessionStorage.setItem('visitorAreaName',this.societyAreaDetailsByAddressRes.areaName);
        }
          
        this.visitorSocietyId = this.societyAreaDetailsByAddressRes.societyId;
        this.visitorSocietyName = this.societyAreaDetailsByAddressRes.societyName;
        this.visitorAddressForm.controls['visitorSocietyId'].setValue(this.societyAreaDetailsByAddressRes.societyId);
        this.visitorAddressForm.controls['visitorSocietyName'].setValue(this.societyAreaDetailsByAddressRes.societyName);

        if(sessionStorage.getItem('visitorSocietyName') == ""){
          sessionStorage.setItem('visitorSocietyId',this.societyAreaDetailsByAddressRes.societyId);
          sessionStorage.setItem('visitorSocietyName',this.societyAreaDetailsByAddressRes.societyName);
        }
        
      } else {

      }

    },error =>{
      console.log("societyAreaDetailsByAddressRes error :",error);
    });

  }

  saveUserSocietyDetailsRes: any=[];
  societyId: any;
  saveUserSocietyDetails(){

    if(this.visitorAddressForm.invalid){
      alert("Please fill data");
      return;
    }

    if(this.visitorAddressForm.controls['visitorSocietyId'].value == ""){
      this.societyId = 0;
    } else {
      this.societyId = this.visitorAddressForm.controls['visitorSocietyId'].value;
    }

    if(this.societyId > 0){
      this.requestData = {
        userId : sessionStorage.getItem('userId'),
        cityId : this.visitorAddressForm.controls['visitorCityId'].value,
        societyId : this.societyId,
        towerName : this.visitorAddressForm.controls['visitorTowerName'].value,
        flatNumber : this.visitorAddressForm.controls['visitorFlatNumber'].value,
        //areaId : "" //optional field
      };
    } else {
      this.requestData = {
        userId : sessionStorage.getItem('userId'),
        cityId : this.visitorAddressForm.controls['visitorCityId'].value,
        societyId : this.societyId,
        societyName : this.visitorAddressForm.controls['visitorSocietyName'].value,
        towerName : this.visitorAddressForm.controls['visitorTowerName'].value,
        flatNumber : this.visitorAddressForm.controls['visitorFlatNumber'].value,
        //areaId : "" //optional field
      };
    }
    
    console.log(this.requestData);
    //return false;

    this.ocas.saveUserSocietyDetails(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.saveUserSocietyDetailsRes = data;
      console.log(this.saveUserSocietyDetailsRes);

      if(this.saveUserSocietyDetailsRes.status == 1){
        this.verificationPending = false;
        this.verificationDone = true;
      } else {
        alert(this.saveUserSocietyDetailsRes.message);
        this.verificationPending = true;
        this.verificationDone = false;
      }

    },error =>{
      console.log("saveUserSocietyDetailsRes error :",error);
    });

  }

  visitorCity;
  visitorCityShow(){
    this.visitorDetail = false;
    this.visitorCity = true;
  }

  visitorCityId: any;
  visitorCityName: any;
  visitorCityHide(citySelectedData){
    this.visitorDetail = true;
    this.visitorCity = false;

    const keyToCheck = 'cityName';

    if(citySelectedData.hasOwnProperty(keyToCheck) && citySelectedData[keyToCheck] !== null && citySelectedData[keyToCheck] !== undefined && citySelectedData[keyToCheck] !== "") {
      this.visitorAddressForm.controls['visitorCityId'].setValue(citySelectedData.cityId);
      this.visitorAddressForm.controls['visitorCityName'].setValue(citySelectedData.cityName+" , "+ citySelectedData.stateName);
      this.visitorAddressForm.controls['visitorCityLat'].setValue(citySelectedData.cityLatitude);
      this.visitorAddressForm.controls['visitorCityLong'].setValue(citySelectedData.cityLongitude);

      this.visitorCityId = citySelectedData.cityId;
      this.visitorCityName = citySelectedData.cityName;
      console.log(this.visitorCityId);

      sessionStorage.setItem('visitorCityId',citySelectedData.cityId);
      sessionStorage.setItem('visitorCityName',citySelectedData.cityName);
      sessionStorage.setItem('visitorCityLat',citySelectedData.cityLatitude);
      sessionStorage.setItem('visitorCityLong',citySelectedData.cityLongitude);
      sessionStorage.setItem('visitorStateName',citySelectedData.stateName); 

      this.visitorAddressForm.controls['visitorAddress'].enable();
      
      this.visitorCityBorder = false;
      this.visitorAddressBorder = true;
      this.visitorAreaBorder = false;
      this.visitorSocietyBorder = false;
      //this.visitorAddressMain.nativeElement.focus();
      
    }

  }

  pickup;
  visitorDetail;
  visitorAddressShow(){
    this.pickup = true;
    this.visitorDetail = false;
  }

  visitorAddressHide(visitorData){
    this.pickup = false;
    this.visitorDetail = true;

    const keyToCheck = 'pickupAddress';
    //alert(typeof pickupData.pickupAddress);

    if(visitorData.hasOwnProperty(keyToCheck) && visitorData[keyToCheck] !== null && visitorData[keyToCheck] !== undefined && visitorData[keyToCheck] !== "") {
      this.visitorAddressForm.controls['visitorAddress'].setValue(visitorData.pickupAddress);

      sessionStorage.setItem('visitorAddress',visitorData.pickupAddress);
      sessionStorage.setItem('visitorAddressLat',visitorData.pickupAddressLat);
      sessionStorage.setItem('visitorAddressLong',visitorData.pickupAddressLong);

      this.visitorAddressForm.controls['visitorAreaName'].enable();
      
      this.visitorCityBorder = false;
      this.visitorAddressBorder = false;
      this.visitorAreaBorder = true;
      this.visitorSocietyBorder = false;

      this.getSocietyAreaDetailsByAddress(visitorData.pickupAddress);
      //this.getSocietyAreaDetailsByAddress('Kalyani Nagar');
    } 

  }

  visitorArea;
  visitorAreaShow(){
    this.visitorDetail = false;
    this.visitorArea = true;
  }

  visitorAreaId: any;
  visitorAreaName: any;
  visitorAreaHide(areaSelectedData){
    this.visitorDetail = true;
    this.visitorArea = false;

    const keyToCheck = 'areaName';

    if(areaSelectedData.hasOwnProperty(keyToCheck) && areaSelectedData[keyToCheck] !== null && areaSelectedData[keyToCheck] !== undefined && areaSelectedData[keyToCheck] !== "") {
      this.visitorAddressForm.controls['visitorAreaId'].setValue(areaSelectedData.areaId);
      this.visitorAddressForm.controls['visitorAreaName'].setValue(areaSelectedData.areaName);
      
      this.visitorAreaId = areaSelectedData.areaId; 
      this.visitorAreaName = areaSelectedData.areaName;

      sessionStorage.setItem('visitorAreaId',areaSelectedData.areaId);
      sessionStorage.setItem('visitorAreaName',areaSelectedData.areaName); 
      
      this.visitorAddressForm.controls['visitorSocietyName'].enable();

      this.visitorCityBorder = false;
      this.visitorAddressBorder = false;
      this.visitorAreaBorder = false;
      this.visitorSocietyBorder = true;

    }

  }  

  visitorSociety;
  visitorSocietyShow(){
    this.visitorDetail = false;
    this.visitorSociety = true;
  }

  visitorSocietyId: any;
  visitorSocietyName: any;
  visitorSocietyHide(societySelectedData){
    this.visitorDetail = true;
    this.visitorSociety = false;

    const keyToCheck = 'societyName';

    if(societySelectedData.hasOwnProperty(keyToCheck) && societySelectedData[keyToCheck] !== null && societySelectedData[keyToCheck] !== undefined && societySelectedData[keyToCheck] !== "") {
      this.visitorAddressForm.controls['visitorSocietyName'].setValue(societySelectedData.societyName);
      this.visitorAddressForm.controls['visitorSocietyId'].setValue(societySelectedData.societyId);

      this.visitorSocietyId = societySelectedData.societyId;
      this.visitorSocietyName = societySelectedData.societyName;

      sessionStorage.setItem('visitorSocietyId',societySelectedData.societyId);
      sessionStorage.setItem('visitorSocietyName',societySelectedData.societyName);

      this.visitorAddressForm.controls['visitorTowerName'].enable();
      this.visitorAddressForm.controls['visitorFlatNumber'].enable();

      this.visitorCityBorder = false;
      this.visitorAddressBorder = false;
      this.visitorAreaBorder = false;
      this.visitorSocietyBorder = false;

    }

  }

  visitorAddressFormReset(){
    this.visitorAddressForm.reset();
    this.verificationPending = true;
    this.verificationDone = false;

    sessionStorage.setItem('visitorCityId','');
    sessionStorage.setItem('visitorCityName','');
    sessionStorage.setItem('visitorCityLat','');
    sessionStorage.setItem('visitorCityLong','');
    sessionStorage.setItem('visitorStateName','');

    sessionStorage.setItem('visitorAddress','');
    sessionStorage.setItem('visitorAddressLat','');
    sessionStorage.setItem('visitorAddressLong','');

    sessionStorage.setItem('visitorAreaId','');
    sessionStorage.setItem('visitorAreaName',''); 

    sessionStorage.setItem('visitorSocietyId','');
    sessionStorage.setItem('visitorSocietyName',''); 

    this.visitorAddressForm.controls['visitorAddress'].disable();
    this.visitorAddressForm.controls['visitorAreaName'].disable();
    this.visitorAddressForm.controls['visitorSocietyName'].disable();

    this.visitorCityBorder = true;
    this.visitorAddressBorder = false;
    this.visitorAreaBorder = false;
    this.visitorSocietyBorder = false;

    this.visitorAddressForm.controls['visitorNumber'].setValue(sessionStorage.getItem('mobileNo'));

    //window.location.reload();
  }

}