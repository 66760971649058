import { Component, OnInit, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';

@Component({
  selector: 'app-corporate-login',
  templateUrl: './corporate-login.component.html',
  styleUrls: ['./corporate-login.component.css']
})
export class CorporateLoginComponent implements OnInit {

  apiProperties: APiProperties = new APiProperties();

  constructor(private fb: FormBuilder,
    private ows: OnewayWebapiService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta) { }

  userVerificationCnt;
  otpScreen;
  corporateMobileNumber;
  corporatePassengerName;
  corporateEmail;
  corporateEmailBlk;
  ngOnInit(): void {
    this.getWebAccessKeyFun();

    this.route.queryParams.subscribe(params => {
      // Access the values using the parameter names (uid, ced, pn)
      const uid = atob(params['uid']);
      const ced = atob(params['ced']);
      const pn = atob(params['pn']);
      const mn = atob(params['mn']);
  
      // Now you can use uid, ced, and pn in your component
      // console.log('UID:', uid);
      // console.log('CED:', ced);
      // console.log('PN:', pn);
      // console.log('MN:', mn);

      this.corporateMobileNumber = mn;
      this.corporatePassengerName = pn;

      if(ced != '') {
        this.corporateEmailBlk = true;
        this.corporateEmail = ced;
      } else {
        this.corporateEmailBlk = false;
        //console.log("else");
      }
      
    });

    // const userId = sessionStorage.getItem('userId');
    // if(userId == null || userId == undefined || userId == "") {
    //   this.otpScreen = false;
    // } else {
    //   this.router.navigate(['/upcommingTrip']);
    // }
  }

  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  isEmailInvalid: boolean = false;
  validateEmail(event: any) {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Check if the input matches the email pattern
    this.isEmailInvalid = !emailPattern.test(event.target.value);
  }

  requestData: any = {};
  webAccessKeyRes: any = [];
  webAccessKey: any;
  getWebAccessKeyFun() {

    this.requestData = {
      companyName: this.apiProperties.webCompanyName,
      clientID: this.apiProperties.webClientID,
      clientSecret: this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data: {}) => {
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1) {
        this.getLoginRegistrationFun();
      } else { }
      
    }, error => {
      console.log("webAccessKeyRes error :", error);
    });

  }

  loginRegistrationRes: any=[];
  getLoginRegistrationFun() {

    this.requestData = {
      userName: this.apiProperties.webCompanyName,
      accessKey: this.webAccessKey,
      mobileNo: this.corporateMobileNumber,
      passengerName: this.corporatePassengerName,
      email: this.corporateEmail 
    };
    //console.log(this.requestData);
    //return false;

    this.ows.getLoginRegistration(JSON.stringify(this.requestData)).subscribe((data: {}) => {
      this.loginRegistrationRes = data;
      //console.log(this.loginRegistrationRes);

      if(this.loginRegistrationRes.status == 1) {
        this.otpFormBlk = true;
      } else {
        alert(this.loginRegistrationRes.message);
        this.otpFormBlk = false;
      }

    }, error => {
      console.log("loginRegistrationRes Error :", error);
    });

  }

  otpForm = this.fb.group({
    otpNumber: ["", [Validators.required]],
  });

  mobileNo: any;
  otpVerificationRes: any = [];
  otpFormBlk;
  corporateOTPVerificationFun() {

    if(this.otpForm.invalid) {
      return false;
    }

    this.requestData = {
      userName: this.apiProperties.webCompanyName,
      accessKey: this.webAccessKey,
      mobileNo: this.corporateMobileNumber,
      otp: this.otpForm.controls['otpNumber'].value,
      loginFrom: "WEB"
    };
    //console.log(this.requestData);

    this.ows.corporateOTPVerification(JSON.stringify(this.requestData)).subscribe((data: {}) => {
      this.otpVerificationRes = data;
      //console.log(this.otpVerificationRes);
      //this.feedbackMessage = this.cancellationReasonRes.message;
      const keyToCheck = 'userId';
      const isKeyPresentInArray = this.otpVerificationRes.hasOwnProperty(keyToCheck);

      if(isKeyPresentInArray) {
        sessionStorage.setItem('corporateUserId', this.otpVerificationRes.userId);
        sessionStorage.setItem('corporateMobileNumber', this.corporateMobileNumber);
        sessionStorage.setItem('corporatePassengerName', this.otpVerificationRes.passangerName);
        sessionStorage.setItem('corporateEmail', this.otpVerificationRes.customerEmailId);
        sessionStorage.setItem('corporateCompanyId',this.otpVerificationRes.companyId);
        sessionStorage.setItem('corporateUser','1');

        sessionStorage.setItem('mobileNo', "");
        sessionStorage.setItem('userId', "");
        sessionStorage.setItem('customerEmailId', "");
        sessionStorage.setItem('passengerName', "");
        sessionStorage.setItem('countryCode', "");

        this.companyGstDetailsFun(this.otpVerificationRes.companyId);
        //this.router.navigate(['/upcommingTrip']);
      } else {
        alert(this.otpVerificationRes.message);
        sessionStorage.setItem('corporateUserId', "");
        sessionStorage.setItem('corporateMobileNumber', "");
        sessionStorage.setItem('corporatePassengerName', "");
        sessionStorage.setItem('corporateEmail', "");
        sessionStorage.setItem('corporateCompanyId', ""); 
        sessionStorage.setItem('corporateUser','0');
      }

    }, error => {
      console.log("otpVerificationRes Error :", error);
    });

  }

  companyGstDetailsRes: any = [];
  companyGstDetailsFun(companyIdVal) {

    this.requestData = {
      userName: this.apiProperties.webCompanyName,
      accessKey: this.webAccessKey,
      companyId: companyIdVal
    };
    //console.log(this.requestData);

    this.ows.companyGstDetails(JSON.stringify(this.requestData)).subscribe((data: {}) => {
      this.companyGstDetailsRes = data;
      //console.log(JSON.stringify(this.companyGstDetailsRes));
      //this.Message = this.companyGstDetailsRes.message;
      //alert(this.companyGstDetailsRes.message);

      if(this.companyGstDetailsRes.status == 1) {
        sessionStorage.setItem('gstCompanyName',this.companyGstDetailsRes.companyName);
        sessionStorage.setItem('gstNumber',this.companyGstDetailsRes.gstNumber);
      } else { }

      this.router.navigate(['/upcommingTrip']);

    }, error => {
      console.log("companyGstDetailsRes Error :", error);
    });

  }

}