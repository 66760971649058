import { Component, OnInit, Input, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';

@Component({
  selector: 'app-login-backup',
  templateUrl: './login-backup.component.html',
  styleUrls: ['./login-backup.component.css']
})
export class LoginBackupComponent implements OnInit {

  
  apiProperties : APiProperties = new APiProperties();

  constructor( private fb: FormBuilder, 
               private ows: OnewayWebapiService, 
               private titleService: Title,  
               private metaTagService: Meta) { }

               userVerificationCnt;
               tripCnt;
  mobileNumberScreen;
  mobileNumberSubmit;
  mobileNumberInProcess;
  otpScreen;
  ngOnInit(): void {

    this.getWebAccessKeyFun();

    console.log(sessionStorage.getItem('userId'));

    if(sessionStorage.getItem('userId') != null){
      this.userVerificationCnt = false;
      this.tripCnt = true; 
    } else {
      this.userVerificationCnt = true;
      this.tripCnt = false;
      this.mobileNumberScreen = true;
      this.mobileNumberSubmit = true;
      this.mobileNumberInProcess = false;
      this.otpScreen = false;
    } 
  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        //this.getCancellationReasonFun();
        
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  loginForm = this.fb.group({
    mobileNumber: ["",[Validators.required,Validators.minLength(10),Validators.maxLength(10),Validators.pattern("^[0-9]*$")]],
    otpNumber: [""]
  });

  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  mobileNumberDisplay: any;
  loginRegistrationRes: any=[];
  getLoginRegistrationFun(){

    if(this.loginForm.invalid){
      alert("Please Enter Valid Mobile Number");
      return false;
    }

    this.mobileNumberSubmit = false;
    this.mobileNumberInProcess = true;

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNo : this.loginForm.controls['mobileNumber'].value,
      passengerName : "",
      email : ""
    };
    console.log(this.requestData);

    this.ows.getLoginRegistration(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.loginRegistrationRes = data;
      console.log(this.loginRegistrationRes);
      
      //this.feedbackMessage = this.cancellationReasonRes.message;

      if(this.loginRegistrationRes.status == 1){
        this.mobileNumberDisplay = this.loginForm.controls['mobileNumber'].value;
        this.mobileNumberScreen = false;
        this.mobileNumberSubmit = false;
        this.mobileNumberInProcess = false;
        this.otpScreen = true;
      } else {
        alert(this.loginRegistrationRes.message);
        this.mobileNumberScreen = true;
        this.mobileNumberSubmit = true;
        this.mobileNumberInProcess = false;
        this.otpScreen = false;
      }

    },error =>{
      console.log("loginRegistrationRes Error :",error);
    });

  }

  otpVerificationRes: any = [];
  webOTPVerificationFun(){

    if(this.loginForm.invalid){
      return false;
    }

    sessionStorage.setItem('mobileNo', this.loginForm.controls['mobileNumber'].value);

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNo : this.loginForm.controls['mobileNumber'].value,
      otp : this.loginForm.controls['otpNumber'].value,
      loginFrom : ""
    };
    console.log(this.requestData);

    this.ows.webOTPVerification(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.otpVerificationRes = data;
      console.log(this.otpVerificationRes);
      //this.feedbackMessage = this.cancellationReasonRes.message;

      if(this.otpVerificationRes.userId != ""){
        sessionStorage.setItem('userId', this.otpVerificationRes.userId);
        sessionStorage.setItem('customerEmailId', this.otpVerificationRes.customerEmailId);
        
        this.userVerificationCnt = false;
        this.tripCnt = true; 
      } else {
        sessionStorage.setItem('userId', "");
        sessionStorage.setItem('customerEmailId', "");
        alert(this.otpVerificationRes.message);
        this.userVerificationCnt = true;
        this.tripCnt = false; 
      }

    },error =>{
      console.log("otpVerificationRes Error :",error);
    });

  }

}