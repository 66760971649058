<div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <h2>Login</h2>
        <form [formGroup]="loginForm" (ngSubmit)="submitLoginForm()">
          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" class="form-control" id="name" formControlName="name" required>
          </div>
          <div class="form-group">
            <label for="mobile">Mobile Number</label>
            <input type="tel" class="form-control" id="mobile" formControlName="mobile" required>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" formControlName="email" required>
          </div>
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
        
        <!-- OTP Verification Form -->
        <div *ngIf="showOtpForm">
          <hr>
          <h2>OTP Verification</h2>
          <form [formGroup]="otpForm" (ngSubmit)="submitOtpForm()">
            <div class="form-group">
              <label for="otp">OTP</label>
              <input type="text" class="form-control" id="otp" formControlName="otp" required>
            </div>
            <button type="submit" class="btn btn-primary">Verify OTP</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  