<div class="container">

    <div class="row mt-3" *ngIf="couponListBlk">

        <div class="col-md-3 col-0"></div>
        <div class="col-md-6 col-12">

            <h4 class="font-weight-bold mt-2 mb-1">Corporate Coupons</h4>

            <ng-container *ngFor="let discount of corporateCoupon">
                <div class="card shadow-sm mb-1">
                    
                    <div class="card-body pb-0">

                        <div class="row">
                            <div class="col-md-2 col-3">
                                <img *ngIf="discount.iconPath !=''" src="{{discount.iconPath}}" style="width:50px; height:50px;" />
                                <img *ngIf="discount.iconPath ==''" src="../../assets/img/oneway.png" style="width:50px; height:50px;" />
                            </div>

                            <div class="col-md-10 col-9 pl-0">
                                <p class="font-weight-bold mb-0">
                                    {{ discount.title }}
                                </p>
                                <label class="text-info small">
                                    {{ discount.description }}
                                </label>
                            </div>

                        </div>
                        
                        <div class="row">

                            <div class="col-md-2 col-3"></div>
                            <div class="col-md-4 col-4 pl-0">
                                <!-- <button class="btn btn-outline-light text-dark font-weight-bold">
                                    {{ discount.couponCode }}
                                </button> -->
                            </div>
                            <div class="col-md-6 col-5 text-right pt-3">
                                <label (click)="toggleDetails(discount)">View Details > </label>
                            </div>
                        </div>

                        <hr *ngIf="discount.showDetails">

                        <div class="col-md-12 col-12" *ngIf="discount.showDetails">

                            <ul class="pl-0">

                                <li *ngFor="let termsData of discount.terms">
                                    {{ termsData }}
                                </li> 
                            </ul>

                        </div>

                    </div>

                    <div class="card-footer py-2 text-center border-0">
                        <label class="text-info small mb-0" style="font-size: 13px; cursor: pointer;">
                            Coupon Code : {{ discount.couponCode }}
                        </label>
                    </div>

                </div>
            </ng-container>

            <!-- <p *ngIf="this.corporateCoupon?.length == 0"> 
                Thank you for choosing our services. <br>
                Currently, we don't have any corporate coupons available for your company. However, we value your business and would like to extend our corporate benefits to you. <br>
                Please reach out to us at <b>corporate@oneway.cab</b> to activate exclusive corporate discounts and offers. <br>
            </p> -->

            <p *ngIf="this.corporateCoupon?.length == 0"> 
                Thanks for verifying your corporate Email id.<br>
                OneWay.Cab has recently launched CEDP (Corporate Employee Discount Program), where you can unlock discounts and offers. <br>
                We have more than 20 Corporates registered as part of CEDP.<br>
                Unfortunately, your company is not listed in CEDP. <br><br>
                
                To be enrolled as part of CEDP, please reach us out at <b>corporate@oneway.cab</b><br>
            </p>

            <h4 class="font-weight-bold mt-2 mb-1">Other Coupons</h4>

            <ng-container *ngFor="let discount of otherCoupon">
                <div class="card shadow-sm mb-1">
                    
                    <div class="card-body pb-0">

                        <div class="row">
                            <div class="col-md-2 col-3">
                                <img *ngIf="discount.iconPath !=''" src="{{discount.iconPath}}" style="width:50px; height:50px;" />
                                <img *ngIf="discount.iconPath ==''" src="../../assets/img/oneway.png" style="width:50px; height:50px;" />
                            </div>

                            <div class="col-md-10 col-9 pl-0">
                                <p class="font-weight-bold mb-0">
                                    {{ discount.title }}
                                </p>
                                <label class="text-info small">
                                    {{ discount.description }}
                                </label>
                            </div>

                        </div>
                        
                        <div class="row">

                            <div class="col-md-2 col-3"></div>
                            <div class="col-md-4 col-4 pl-0">
                                <!-- <button class="btn btn-outline-light text-dark font-weight-bold">
                                    {{ discount.couponCode }}
                                </button> -->
                            </div>
                            <div class="col-md-6 col-5 text-right pt-3">
                                <label (click)="toggleDetails(discount)">View Details > </label>
                            </div>
                        </div>

                        <hr *ngIf="discount.showDetails">

                        <div class="col-md-12 col-12" *ngIf="discount.showDetails">

                            <ul class="pl-0">

                                <li *ngFor="let termsData of discount.terms">
                                    {{ termsData }}
                                </li> 
                            </ul>

                        </div>

                    </div>

                    <div class="card-footer py-2 text-center border-0">
                        <label class="text-info small mb-0" style="font-size: 13px; cursor: pointer;">
                            Coupon Code : {{ discount.couponCode }}
                        </label>
                    </div>

                </div>
            </ng-container>

            <p *ngIf="this.otherCoupon?.length == 0"> 
                Thank you for choosing our services. Currently, we don't have any coupons available.<br>
            </p>

            <div class="row mt-2">
                <div class="col-md-12 col-12 text-right">
                    <a href="/upcommingTrip" class="btn btn-secondary ml-3" style="font-size: 13px;">Next..</a>
                </div>
            </div>

        </div> 
        
    </div>

    <div class="row mt-3" *ngIf="validateCorporateEmailBtnBlk">

        <div class="col-md-12 col-12 mt-3">
            <p style="font-family:Arial,verdana;font-size:12px;color:#000000;padding:0px;margin:0;line-height:19pt">
                <font style="font-size:11pt">
                    <font face="Arial, Helvetica">
                        <font face="Arial, Helvetica">
                            <font>We are launching CEDP (<font color="#008bd2"><i>Corporate Employee
                                        Discount Program</i></font>)</font>
                        </font>
                    </font>
                    <font color="#000000" face="Arial, Helvetica">
                        <font color="#000000" face="Arial, Helvetica">
                            <font>. <br>Check if your company is eligible
                                for it, in 3 simple steps.</font>
                        </font>
                    </font>
                </font>
            </p>
        </div>

        <div class="col-md-12 col-12 mt-3 mb-4">
        
            <div class="row">

                <div class="col-md-4 col-12 mb-1">
                    <p align="center"
                        style="line-height:1.7; font-family:Arial,verdana; font-size:12px; color:#000000; padding:0px; margin:0; text-align:center">
                        <font color="#000000" face="Arial, Helvetica"
                            style="font-size: 12pt; font-family: Arial,Helvetica">
                            <img src="assets/img/login.png" style="width: 64px; height: 64px; vertical-align: middle">
                        </font>
                    </p>

                    <p
                        style="font-family:Arial,verdana; font-size:17px; color:#000000; padding:0px;margin:0; text-align:center">
                        Login and <b>
                            <font color="#008bd2"> Enter </font>
                        </b> your <br> Corporate email id.
                    </p>
                </div>

                <div class="col-md-4 col-12 mb-1">
                    <p align="center"
                        style="line-height:1.7;font-family:Arial,verdana;font-size:12px;color:#000000;padding:0px;margin:0;text-align:center">
                        <font color="#008bd2">
                            <img src="assets/img/verify.png" style="width:64px; height:64px; vertical-align:middle">
                        </font>
                    </p>

                    <p
                        style="font-family:Arial,verdana; font-size:17px; color:#000000; padding:0px;margin:0; text-align:center">
                        <b>
                            <font color="#008bd2">Verify</font>
                        </b> OTP received on<br> your Corporate email
                    </p>
                </div>

                <div class="col-md-4 col-12 mb-1">

                    <p align="center"
                        style="line-height:1.7;font-family:Arial,verdana;font-size:12px;color:#000000;padding:0px;margin:0;text-align:center">
                        <font face="Arial, Helvetica" style="font-size:12pt">
                            <font color="#000000" face="Arial, Helvetica"
                                style="color:rgb(0,0,0); font-size:12pt">
                                <img src="assets/img/unlock.png"
                                    style="width: 64px; height: 64px; vertical-align: middle">
                            </font>
                        </font>
                    </p>

                    <p
                        style="font-family:Arial,verdana; font-size:17px; color:#000000; padding:0px;margin:0; text-align:center">
                        Unlock your <b>
                            <font color="#008bd2">Exclusive</font>
                        </b> <br> Discount Code
                    </p>

                </div>

            </div>

        </div>

        <!-- <div class="col-md-12 col-12">
            <h3 class="intro-text">Introducing Our Corporate Discount Program in 3 Easy Steps</h3>
        </div>
        
        <div class="col-md-4 col-12 mb-1"> 
            <div class="card shadow border-0">
                <div class="card-body card-step">
                    <div class="icon">
                        <i class="fa fa-envelope"></i>
                    </div>
                    <div class="text">
                        <h5 class="card-title">Step 1</h5>
                        <p class="card-text">Enter your corporate email ID to get started.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-12 mb-1">
            <div class="card shadow border-0">
                <div class="card-body card-step">
                    <div class="icon">
                        <i class="fa fa-key"></i>
                    </div>
                    <div class="text">
                        <h5 class="card-title">Step 2</h5>
                        <p class="card-text"><b>Verify</b> OTP received on your Corporate email, to check if your company is part of CEDP.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-12 mb-3">       
            <div class="card shadow border-0">
                <div class="card-body card-step">
                    <div class="icon">
                        <i class="fa fa-check-circle"></i>
                    </div>
                    <div class="text">
                        <h5 class="card-title">Step 3</h5>
                        <p class="card-text">Upon Verification, if your company is part of CEDP - Unlock your Exclusive  Discount Code.</p>
                    </div>
                </div>
            </div>
        </div> 

        <div class="col-md-12 col-12">
            <p style="font-size: 16px;">Please verify your corporate status to check available corporate coupons.</p>
        </div> -->

        <div class="col-md-12 col-12 text-right mb-5">
            <button class="btn btn-success" (click)="validateCorporateEmailShow()">Validate Corporate Email</button>
            <a href="/upcommingTrip" class="btn btn-secondary ml-3" style="font-size: 13px;">Skip..</a>
        </div>

    </div>
        

    <div class="row mt-3" *ngIf="validateCorporateEmailBlk">
        
        <div class="col-md-3 col-0"></div>
        <div class="col-md-6 col-12">

            <div class="card shadow">

                <div class="card-body">
          
                  <form [formGroup]="CorporateForm">

                    <div *ngIf="emailIdScreen" class="row">

                        <div class="col-md-12 col-12">
                            <input type="text" class="form-control" formControlName="corporateEmailId" 
                                   placeholder="Please enter corporate email Id"
                                   [ngClass]="{ 'is-invalid': submitted && f.corporateEmailId.errors }">
                            
                            <div *ngIf="submitted && f.corporateEmailId.errors" class="invalid-feedback">
                                <div *ngIf="f.corporateEmailId.errors['required']">Email is required.</div>
                                <div *ngIf="f.corporateEmailId.errors['email']">Invalid email format.</div>
                            </div>
                        </div>

                        <div class="col-md-12 col-12 mt-2 text-right">
                            <button class="btn btn-secondary mr-2" style="font-size: 14px;" (click)="validateCorporateEmailHide()">Close</button>
                            <button class="btn btn-success" (click)="validateCorporateEmail()">Submit</button>
                        </div>
          
                    </div>

                    <div *ngIf="otpScreen" class="row">
                        <div class="col-md-12 col-12">
                            <input type="text" class="form-control" formControlName="otpNumber" placeholder="Please enter OTP" required>
                        </div>

                        <div class="col-md-12 col-12 mt-2 text-right">
                            <button type="submit" class="btn btn-primary" (click)="validateCorporateEmailOtp()">Confirm</button>
                        </div>
                    </div>

                    <div *ngIf="inProcess" class="row">
                        <div class="col-md-12 col-12 text-right">
                            <h4 class="btn btn-danger">In Process... </h4>
                        </div>
                    </div>
                    
                  </form>
          
                </div>
          
              </div>

        </div>

    </div>

    <!-- <div class="row mt-3" *ngIf="corporateEmailValidationDoneBlk">
        
        <div class="col-md-6 col-12">
            <div class="row">
                <div class="col-md-12 col-12">
                    <p style="font-size: 16px;">No corporate coupons are available for your account at this time.</p>
                </div> 
                <div class="col-md-12 col-12 text-right">
                    <a href="/upcommingTrip" class="btn btn-secondary ml-3" style="font-size: 13px;">Next..</a>
                </div>
            </div>
        </div>
   
    </div> -->

</div>