import { Component, OnInit, Input, Output, Inject, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
//import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-upcomming-trip',
  templateUrl: './upcomming-trip.component.html',
  styleUrls: ['./upcomming-trip.component.css']
})
export class UpcommingTripComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @ViewChild('cancellationReasonDialog') cancellationReasonDialog : TemplateRef<any>;
  @ViewChild('RefundDialog') RefundDialog : TemplateRef<any>;
  @ViewChild('RefundResponseDialog') RefundResponseDialog : TemplateRef<any>;
  @ViewChild('cancelRideDialog') cancelRideDialog : TemplateRef<any>;
  @ViewChild('closeBookModifyModal') closeBookModifyModal : ElementRef;

  constructor( private fb: FormBuilder, 
               private ows: OnewayWebapiService,
               private router: Router,
               private route: ActivatedRoute, 
               private titleService: Title,  
               private metaTagService: Meta,
               private dialog: MatDialog) { }

  cancellationReasonDialogRef: any;
  RefundResponseDialogRef: any;
  requestData: any ={};
  user = { name: 'Milan', city: 'Palanpur' };

  otherReasonInput;
  corporateUser: any;
  ngOnInit(): void {
    console.log("upcoming Trip");
    console.log("userId :: "+sessionStorage.getItem('userId'));
    this.otherReasonInput = false;

    this.pickupDatePass = "";
    this.pickupTimePass = "";

    console.log(sessionStorage.getItem('corporateUser'));
    console.log(sessionStorage.getItem('corporateUserId'));

    // const userId = sessionStorage.getItem('userId');
    // if(userId == null || userId == undefined || userId == "") {
    //   this.router.navigate(['/login']);
    // }
    this.corporateUser = sessionStorage.getItem('corporateUser');
    this.getWebAccessKeyFun();
  }

  webAccessKeyRes: any =[];
  webAccessKey: any;
  userName: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      this.userName = this.webAccessKeyRes.userName;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        this.getUpcommingRideFun(0);
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  upcommingRideData = [];
  upcommingRideRes: any=[];
  offset: any;
  loadMoreUpcomming;
  getUpcommingRideFun(offset){

    if(this.corporateUser == '1'){
      this.requestData = {
        userName: this.userName,
        accessKey: this.webAccessKey,
        mobileNo: sessionStorage.getItem('corporateMobileNumber'),
        onlyCorporateBooking : 1,
        offSet: offset //offset value like 0, 5, 10, 15, 25, 30,....
      };
    } else {
      this.requestData = {
        userName: this.userName,
        accessKey: this.webAccessKey,
        mobileNo: sessionStorage.getItem('mobileNo'),
        offSet: offset //offset value like 0, 5, 10, 15, 25, 30,....
      };
    }
    
    console.log(this.requestData);

    this.ows.getUpcommingRide(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.upcommingRideRes = data;

      if(this.upcommingRideRes.status == 1){
        this.offset = offset+5;
        console.log(this.offset);

        for(var i=0; i<this.upcommingRideRes.upcommingRide.length; i++){
          this.upcommingRideData.push(this.upcommingRideRes.upcommingRide[i]);
        }
        console.log(this.upcommingRideData);
        
        if(this.upcommingRideRes.upcommingRide.length < 5){
          this.loadMoreUpcomming = false;
        } else {
          this.loadMoreUpcomming = true;
        }

      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("upcommingRideRes error :",error);
    });

  }

  bookId: any;
  pickupDatePass: any;
  pickupTimePass: any;
  modifyTime;
  webModifyPickupTimeSet(bookingData){
    console.log(bookingData.pickupDateTime);
    this.bookId = bookingData.bookingId;

    this.modifyTime = true;

    var dateTimeSplit = bookingData.pickupDateTime.split(" ");
    console.log(dateTimeSplit[1]);
    this.pickupDatePass = dateTimeSplit[0];
    this.pickupTimePass = dateTimeSplit[1];

    this.bookingDetails(bookingData);
  }

  bookingDetailsRes: any=[];
  bookingDetailBlk;
  bookingDetails(bookingData){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      bookId : bookingData.displayBookingId
    };
    //console.log(this.requestData);

    this.ows.bookingDetails(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.bookingDetailsRes = data;
      //console.log(this.bookingDetailsRes);

      if(this.bookingDetailsRes.status == 1){
        this.bookingDetailBlk = true;
      } else {
        this.bookingDetailBlk = false;
      }

    },error =>{
      console.log("bookingDetailsRes error :",error);
    });

  }

  bookingModificationForm = this.fb.group({
    pickupDate: ["", [Validators.required]],
    pickupTime: ["", [Validators.required]]
  });

  get bmf(){
    return this.bookingModificationForm.controls;
  }

  webModifyPickupTimeRes: any=[];
  webModifyPickupTime(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      bookId: this.bookId,
      pickupDate: this.newDate,
      pickupTime: this.newTime
    };
    console.log(this.requestData);
    
    this.ows.webModifyPickupTime(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webModifyPickupTimeRes = data;
      console.log(this.webModifyPickupTimeRes);

      if(this.webModifyPickupTimeRes.status == 1){
        alert(this.webModifyPickupTimeRes.message);
      } else {
        alert(this.webModifyPickupTimeRes.message);
      }
      
      this.closeBookModifyModal.nativeElement.click();
      window.location.reload();
      //this.getUpcommingRideFun(0);
        
    },error =>{
      console.log("webModifyPickupTimeRes error :",error);
    });

  }

  newDate: any;
  changedDateStore(dateData){
    //console.log("newDate");
    //console.log(dateData.newDate);
    this.newDate = dateData.newDate;
  }

  newTime: any;
  changedTimeStore(timeData){
    //console.log("newTime");
    //console.log(timeData.newTime);
    this.newTime = timeData.newTime;
  }

  // openCityDialog(displayBookingId) {
  //   this.cancellationReasonDialogRef = this.dialog.open(this.cityDialog,
  //     { 
  //       data: {name: displayBookingId, city: 'Palanpur'}, 
  //       height: '350px', 
  //       width: '250px' });

  //   // this.cancellationReasonDialogRef.afterClosed().subscribe((result: User) => {
  //   //   console.log('The City dialog was closed.');
  //   //   console.log(result?.name + ' - ' + result?.city);
  //   // });

  // }

  cancellationReasonForm = this.fb.group({
    cancellationReason: ['', [Validators.required]],
    otherReasonInput: ['']
  });

  get f(){
    return this.cancellationReasonForm.controls;
  }

  cancellationReason: any;
  cancellationReasonNewRes: any=[];
  bookingId: any;
  displayBookingId: any;
  getCancellationReasonNewFun(bookingId,enterAnimationDuration,exitAnimationDuration,displayBookingId){

    this.otherReasonInput = false;
    this.cancellationReasonForm.controls['otherReasonInput'].setValue("");

    this.bookingId = bookingId;
    this.displayBookingId = displayBookingId;
    this.requestData = {
      userName: this.userName,
      accessKey: this.webAccessKey,
      bookId: bookingId
    };
    console.log(this.requestData);

    return this.ows.getCancellationReasonNew(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.cancellationReasonNewRes = data;

      if(this.cancellationReasonNewRes.status == 1){
        console.log(this.cancellationReasonNewRes);
        
        this.cancellationReasonNewRes.cancelReason.push({
          "reason": "Other",
          "reasonId": 0
        });

        this.cancellationReasonDialogRef = this.dialog.open(this.cancellationReasonDialog,
          { 
            data: this.cancellationReasonNewRes.cancelReason,
            enterAnimationDuration: enterAnimationDuration,
            exitAnimationDuration: exitAnimationDuration,
            width: '400px'
          });

      } else {
        //this.cancelRideRefundFun();
      }

    },error =>{
      console.log("getCancellationReasonNew error :",error);
    });

  }

  reasonText: any;
  reasonId: any;
  cancellationReasonSelectFun(data){

    if(data.value.reasonId == 0){
      this.otherReasonInput = true;
      this.reasonId = "";
    } else {
      this.otherReasonInput = false;
      this.reasonId = data.value.reasonId;
      this.reasonText = data.value.reason;
    }
    
  }

  closeCancellationReasonDialog() {
    this.cancellationReasonDialogRef.close();
  }

  refundDialogRef: any;
  validateCancelRideRes: any=[];
  totalRefundAmount: any;
  validateCancelRideFun(enterAnimationDuration,exitAnimationDuration){

    if(this.cancellationReasonForm.invalid){
      return false;
    }

    if(this.reasonId == ""){
        if(this.cancellationReasonForm.controls['otherReasonInput'].value == ""){
          alert("Enter Reason");
          return false;
        } else {
          this.reasonId = this.cancellationReasonForm.controls['otherReasonInput'].value;
        }
    }

    this.requestData = {
      userName: this.userName,
      accessKey: this.webAccessKey,
      bookId: this.bookingId
    };
    console.log(this.requestData);

    return this.ows.validateCancelRide(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.validateCancelRideRes = data;

      this.cancellationReasonDialogRef.close();

      if(this.validateCancelRideRes.status == 1){
        console.log(this.validateCancelRideRes);

        this.totalRefundAmount = parseInt(this.validateCancelRideRes.walletRefundAmount) + parseInt(this.validateCancelRideRes.onlineRefundAmount);
        
        if(this.totalRefundAmount > 0){
          this.refundDialogRef = this.dialog.open(this.RefundDialog,{ 
            data: this.validateCancelRideRes.cancelReason,
            enterAnimationDuration: enterAnimationDuration,
            exitAnimationDuration: exitAnimationDuration,
            width: '400px'
          });
        } else {
          //this.cancelRideRefundFun();
          this.cancelRideFun();
        }
        
      } else {
        alert(this.validateCancelRideRes.message);
      }

    },error =>{
      console.log("validateCancelRide error :",error);
    });

  }

  refundForm = this.fb.group({
    refundMode: ['', [Validators.required]],
  });

  get refundF(){
    return this.refundForm.controls;
  }

  refundSelectFun(data){
    //console.log(data.value);
  }

  closeRefundDialog() {
    this.refundDialogRef.close();
  }

  cancelRideRefundRes: any=[];
  cancelRideRefundFun(){

    if(this.corporateUser == '1'){
      var crfUserId = sessionStorage.getItem('corporateUserId');
    } else {
      var crfUserId = sessionStorage.getItem('userId');
    }

    this.requestData = {
      userName: this.userName,
      accessKey: this.webAccessKey,
      bookId: this.bookingId,
      userId: crfUserId,
      refundPaymentMode: this.refundForm.controls['refundMode'].value,
      cancel_reason: this.reasonId,
      cancel_from: "3" //3 for web
    };
    console.log(this.requestData);
    //return false;

    return this.ows.cancelRideRefund(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.cancelRideRefundRes = data;

      if(this.totalRefundAmount > 0){
        this.refundDialogRef.close();
      } else {
        this.cancellationReasonDialogRef.close();
      }
      
      this.RefundResponseDialogRef = this.dialog.open(this.RefundResponseDialog,{ 
        enterAnimationDuration: '500ms',
        exitAnimationDuration: '500ms',
        width: '400px'
      });

      // if(this.cancelRideRefundRes.status == 1){
      //   console.log(this.cancelRideRefundRes);
      // } else { }

    },error =>{
      console.log("cancelRideRefundRes error :",error);
    });

  }

  closeRefundResponseDialog(){
    this.RefundResponseDialogRef.close();
    window.location.reload();
  }
  
  cancelRideRes: any=[];
  cancelRideDialogRef: any;
  cancelRideFun(){

    if(this.corporateUser == '1'){
      var crfUserId = sessionStorage.getItem('corporateUserId');
    } else {
      var crfUserId = sessionStorage.getItem('userId');
    }

    this.requestData = {
      userName: this.userName,
      accessKey: this.webAccessKey,
      userId: crfUserId,
      bookingId: this.displayBookingId,
      cancel_reason: this.reasonId,
      cancel_from: "3" //3 for web
    };
    console.log(JSON.stringify(this.requestData));
    
    return this.ows.cancelRide(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.cancelRideRes = data;

      if(this.totalRefundAmount > 0){
        this.cancellationReasonDialogRef.close();
      } else {
        this.cancellationReasonDialogRef.close();
      }
    
      this.cancelRideDialogRef = this.dialog.open(this.cancelRideDialog,{ 
        enterAnimationDuration: '500ms',
        exitAnimationDuration: '500ms',
        width: '400px'
      });

      // if(this.cancelRideRefundRes.status == 1){
      //   console.log(this.cancelRideRefundRes);
      // } else { }

    },error =>{
      console.log("cancelRideRes error :",error);
    });

  }

  closeCancelRideDialog(){
    this.cancelRideDialogRef.close();
    window.location.reload();
  }

  bookingInfo(displayBookingId){
    var encodeBooking = btoa(displayBookingId);
    var bookingInfoURL = this.apiProperties.siteurlPHP+"/tripInfo/"+encodeBooking;
    window.open(bookingInfoURL, '_blank').focus();
  }

  bookingModify(displayBookingId){
    var encodeBooking = btoa(displayBookingId);
    var bookingModifyURL = this.apiProperties.siteurlPHP+"/modify/"+encodeBooking;
    window.open(bookingModifyURL, '_blank').focus();
  }

}