<div class="container-fluid">

    <div class="container">

        <div class="row mt-3">
            
            <div class="col-md-3 col-0"></div>
            
            <div class="col-md-6 col-12">

                <div class="row trip-payment-custom py-3">

                    <div class="col-md-12 col-12 text-center">
                        <img *ngIf="this.carType == 'hatchback'" src="assets/img/hatchback.png" style="width: 65px; height: 25px;">
                        <img *ngIf="this.carType == 'sedan'" src="assets/img/sedan.png" style="width: 65px; height: 25px;">
                        <img *ngIf="this.carType == 'suv'" src="assets/img/suv.png" style="width: 65px; height: 25px;">
                        <br>

                        <p style="font-size: 15px;">
                            {{this.pickupCity | titlecase}} to {{this.dropCity | titlecase}}
                        </p>

                    </div>

                    <div class="col-md-6 col-6 text-right" style="color:darkgray; ">
                        <i class="fa fa-calendar" aria-hidden="true"></i> {{this.pickupDate | date:'dd MMM, yyyy'}}
                    </div>

                    <div class="col-md-4 col-6" style="color:darkgray;">
                        <i class="fa fa-clock-o" aria-hidden="true"></i> {{this.pickupTime | time12hour}}
                    </div>

                    <div class="col-md-12 col-12">
                        <hr style="border: 1px solid #ffbf00e6;">
                    </div>

                    <div class="col-md-12 col-12 text-center">
                        <p class="mb-2" style="font-size: 15px;">
                            All Inclusive Fixed Fare
                        </p>

                        <h2 class="font-weight-bold">
                            <del *ngIf="discountAppliedBlk"> <i class="fa fa-rupee"></i> {{this.beforeCouponFinalAmount}} </del>
                            <i class="fa fa-rupee"></i> {{this.totalAmountPayByCustomer}}
                        </h2>

                        <!-- <h2 *ngIf="discountAppliedBlk" class="font-weight-bold">
                            <del> <i class="fa fa-rupee"></i> {{this.beforeCouponFinalAmount}} </del>
                            {{this.totalAmountPayByCustomer}}
                        </h2>

                        <h2 *ngIf="!discountAppliedBlk" class="font-weight-bold">
                            <i class="fa fa-rupee"></i> {{this.totalAmountPayByCustomer}}
                        </h2> -->

                        <p style="color:darkgray;">
                            <label> Fare <i class="fa fa-rupee"></i> {{this.baseFare}}</label>
                            <label *ngIf="this.tollTax > 0" style="display: block;">+ Toll Tax <i class="fa fa-rupee"></i> {{this.tollTax}}</label>
                            <label *ngIf="this.airportEntryCharge > 0">+ Airport Entry Charge <i class="fa fa-rupee"></i> {{this.airportEntryCharge}}</label>
                            <label *ngIf="this.interStatePermit > 0" style="display: block;">+ Inter-State Permit <i class="fa fa-rupee"></i> {{this.interStatePermit}}</label>
                            <label>+ GST (5.0%) <i class="fa fa-rupee"></i> {{this.gst}}</label>
                        </p>
                    </div>

                    <div *ngIf="!discountBlk" class="col-md-12 col-12 p-0">
                        <div class="d-flex px-3" style="background-color: #fff; cursor: pointer;" data-toggle="modal"
                            data-target="#couponModal">
                            <div class="p-1 align-self-center">
                                <img src="../../assets/img/discount.png" alt="Hatchback" class="align-self-center"
                                    style="width: 40px; height: 40px;">
                            </div>
                            <div class="p-1">
                                <h4 class="mb-0" style="color: #aaa; font-size: 12px;">Use Coupons</h4>
                                <label style="color:dodgerblue">
                                    Tap here to see list of coupon code
                                </label>
                            </div>
                            <div class="p-1 ml-auto align-self-center pr-2" style="color: darkgray; font-size: 50px;">
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="discountBlk" class="col-md-12 col-12 p-0">
                        <div class="d-flex px-3" style="background-color: #fff; cursor: pointer;">
                            <div class="p-1 align-self-center">
                                <img src="../../assets/img/discount.png" alt="Hatchback" class="align-self-center"
                                    style="width: 40px; height: 40px;">
                            </div>
                            <div class="p-1">
                                <h4 class="mb-0" style="color: #aaa; font-size: 12px;">Use Coupons</h4>
                                <label style="color:dodgerblue">
                                    Discount Applied successfully. You saved <i class="fa fa-rupee"></i> {{this.discountAmount}}
                                </label>
                            </div>
                            <div class="p-1 ml-auto align-self-center pr-2" (click)="removeCouponCodeFun()" style="color: green; font-size: 14px;">
                                Remove
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-12 text-center p-0">
                        <hr class="mb-0" style="border: 1px solid #ffbf00e6;">
                        <h3 class="pt-2 pb-2 mb-0" style="background-color: #fff;">Amount Payble Now : 
                            <i class="fa fa-rupee"></i> {{this.amountPaybleNow}}
                        </h3>
                        
                        <h3 *ngIf="isSaverCabBlk" class="pt-2 pb-2 mb-0" style="background-color: #fff;">Amount Payble to Driver : 
                            <i class="fa fa-rupee"></i> {{this.amountPaybleToDriver}}
                        </h3>
                        <hr class="mt-0 mb-0" style="border: 1px solid #ffbf00e6;">
                    </div>

                    <!-- <div *ngIf="!isSaverCabBlk" class="col-md-12 col-12 text-center p-0">
                        <hr class="mb-0" style="border: 1px solid #ffbf00e6;">
                        <h3 class="pt-2 pb-2 mb-0" style="background-color: #fff;">Amount Payble Now : 
                            <i class="fa fa-rupee"></i> {{this.totalAmountPayByCustomer}}
                        </h3>
                        <hr class="mt-0 mb-0" style="border: 1px solid #ffbf00e6;">
                    </div> -->

                    <div class="col-md-12 col-12 p-0">
                        <h4 class="py-2 px-4 mb-0" style="background-color: #ddd9d9;">
                            Select Payment Mode
                        </h4>
                    </div>

                    <div *ngIf="walletBlk" class="col-md-12 col-12" style="border-bottom: 1px solid #d1d2d199;">

                        <div class="d-flex">
                            <div class="p-2 mr-auto">
                                <h4 class="pt-2">
                                    OneWay Cash Wallet
                                </h4>
                            </div>
                            <div class="p-2">
                                <p class="mr-2 font-weight-bold pt-2">
                                    <i class="fa fa-rupee"></i> {{this.userWalletAmount}}
                                </p>
                            </div>
                            <div class="p-2">
                                <label class="toggle-container">
                                    <input type="checkbox" class="toggle-input" [checked]="walletChecked" (click)="onWalletClick($event,this.amountPaybleNow)">
                                    <span class="toggle-slider"></span>
                                </label>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-12 col-12 mt-2">
                        <div class="card shadow rounded-lg border-0">
                            <div class="card-body p-1">

                                <div class="row" *ngIf="!fullWalletPayment">

                                    <div *ngIf="this.countryCode == '91'" class="col-md-12 col-12">
                                        <div class="d-flex custom-payment-option" style="cursor: pointer;" (click)="paymentGatewayRedirect('upi')">
                                            <div class="p-1 mr-2 align-self-center">
                                                <i class="fa fa-credit-card-alt" style="font-size: 25px;"
                                                    aria-hidden="true"></i>
                                            </div>
                                            <div class="p-1">
                                                <h4 class="mb-0 pt-1 custom-payment-text">UPI</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-12 mb-1">
                                        <div class="d-flex custom-payment-option" style="cursor: pointer;" (click)="paymentGatewayRedirect('creditcard')">
                                            <div class="p-1 mr-2 align-self-center">
                                                <i class="fa fa-credit-card" style="font-size: 30px;"
                                                    aria-hidden="true"></i>
                                            </div>
                                            <div class="p-1">
                                                <h4 class="mb-0 pt-1 custom-payment-text">Credit Card</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="this.countryCode == '91'" class="col-md-12 col-12">
                                        <div class="d-flex custom-payment-option" style="cursor: pointer;" (click)="paymentGatewayRedirect('debitcard')">
                                            <div class="p-1 mr-2 align-self-center">
                                                <i class="fa fa-credit-card-alt" style="font-size: 25px;"
                                                    aria-hidden="true"></i>
                                            </div>
                                            <div class="p-1">
                                                <h4 class="mb-0 pt-1 custom-payment-text">Debit Card</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="this.countryCode == '91'" class="col-md-12 col-12 mb-1">
                                        <div class="d-flex custom-payment-option" style="cursor: pointer;" (click)="paymentGatewayRedirect('netbanking')">
                                            <div class="p-1 mr-2 align-self-center">
                                                <i class="fa fa-university" style="font-size: 30px;"
                                                    aria-hidden="true"></i>
                                            </div>
                                            <div class="p-1">
                                                <h4 class="mb-0 pt-1 custom-payment-text">Net Banking</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="this.countryCode == '91'" class="col-md-12 col-12 mb-1">
                                        <div class="d-flex custom-payment-option" style="cursor: pointer;" (click)="paymentGatewayRedirect('upi')">
                                            <div class="p-1 mr-2 align-self-center">
                                                <i class="fa fa-credit-card" style="font-size: 30px;"
                                                    aria-hidden="true"></i>
                                            </div>
                                            <div class="p-1">
                                                <h4 class="mb-0 pt-1 custom-payment-text">Wallets</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="payCashToDriver && this.countryCode == '91'" class="col-md-12 col-12 mb-1" (click)="updateWebPaymentTypeFun('1')" style="cursor: pointer;">
                                        <div class="d-flex custom-payment-option">
                                            <div class="p-1 mr-2 align-self-center">
                                                <i class="fa fa-credit-card" style="font-size: 30px;"
                                                    aria-hidden="true"></i>
                                            </div>
                                            <div class="p-1">
                                                <h4 class="mb-0 pt-1 custom-payment-text">Pay Cash To Driver</h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row" *ngIf="fullWalletPayment" (click)="updateWebPaymentTypeFun('3')" style="cursor: pointer;">
                                    <div class="col-md-12 col-12 mb-1">
                                        <div class="d-flex custom-payment-option">
                                            <div class="p-1 mr-2 align-self-center">
                                                <i class="fa fa-credit-card" style="font-size: 30px;"
                                                    aria-hidden="true"></i>
                                            </div>
                                            <div class="p-1">
                                                <h4 class="mb-0 pt-1 custom-payment-text">Pay Using Wallet</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>

</div>

<div class="modal fade" id="couponModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    
    <div class="modal-dialog" role="document">

        <div class="modal-content">

            <div class="modal-header border-bottom-0 pb-0">
                <h2 class="modal-title mb-0" id="exampleModalLabel">Discount Coupons</h2>
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button> -->
            </div>

            <div class="modal-body">

                <div class="row">

                        <div class="col-md-12 col-12 mb-2">
                            <div class="input-container">
                                <input type="text" [(ngModel)]="inputText" class="text-input" placeholder="Type coupon code here">
                                <button class="inside-button" (click)="saveText()">Apply</button>
                            </div>
                        </div>
                    
                        <div *ngFor="let discount of listCouponCodeRes.couponList" class="col-md-12 col-12 mb-1">

                            <div class="card shadow-sm">
                                
                                <div class="card-body pb-0">

                                    <div class="row">
                                        <div class="col-md-2 col-3">
                                            <img *ngIf="discount.iconPath !=''" src="{{discount.iconPath}}" style="width:50px; height:50px;" />
                                            <img *ngIf="discount.iconPath ==''" src="../../assets/img/oneway.png" style="width:50px; height:50px;" />
                                        </div>

                                        <div class="col-md-10 col-9 pl-0">
                                            <p class="font-weight-bold mb-0">
                                                {{ discount.title }}
                                            </p>
                                            <label class="text-info small">
                                                {{ discount.description }}
                                            </label>
                                        </div>

                                    </div>
                                    
                                    <div class="row">

                                        <div class="col-md-2 col-3"></div>
                                        <div class="col-md-4 col-4 pl-0">
                                            <button class="btn btn-outline-light text-dark font-weight-bold">
                                                {{ discount.couponCode }}
                                            </button>
                                        </div>
                                        <div class="col-md-6 col-5 text-right pt-3">
                                            <label (click)="toggleDetails(discount)">View Details > </label>
                                        </div>
                                    </div>

                                    <hr *ngIf="discount.showDetails">

                                    <div class="col-md-12 col-12" *ngIf="discount.showDetails">

                                        <ul class="pl-0">

                                            <li *ngFor="let termsData of discount.terms">
                                                {{ termsData }}
                                            </li> 
                                        </ul>

                                    </div>

                                </div>

                                <div class="card-footer py-2 text-center border-0">
                                    
                                    <label class="text-info small mb-0" style="font-size: 13px; cursor: pointer;"
                                    id="validateCouponCode" (click)="validateCouponCodeFun(discount.couponCode)">
                                        TAP TO APPLY
                                    </label>
                                </div>

                            </div>
                        </div>

                        <hr>

                        <div class="col-md-12 col-12 text-right">
                            <label class="text-secondary skipText" data-dismiss="modal"> SKIP </label>
                        </div>
                    
                </div>
              
            </div>

        </div>
    </div>

</div>