import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APiProperties } from '../../../app/class/api-properties';

@Component({
  selector: 'header-cmp',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  constructor(private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    // const userId = sessionStorage.getItem('userId');
    // if(userId == null || userId == undefined || userId == "") {
    //   this.router.navigate(['/login']);
    // }
  }


}
