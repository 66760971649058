import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { OnewayPartnerEnrolApiService } from '../../service/oneway-partner-enrol-api.service';
import { GoogleService } from '../../service/google.service';

@Component({
  selector: 'app-visitor-city',
  templateUrl: './visitor-city.component.html',
  styleUrls: ['./visitor-city.component.css']
})
export class VisitorCityComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @Output('visitorCityHide') visitorCityHide = new EventEmitter();
  @ViewChild('visitorCityCmp') visitorCityCmp: ElementRef;

  constructor( private fb: FormBuilder, 
               private router: Router,
               private route: ActivatedRoute,
               private ows: OnewayWebapiService, 
               private opeas: OnewayPartnerEnrolApiService,
               private gs: GoogleService,
               private titleService: Title,  
               private metaTagService: Meta) { }

  placeHolderTitle;             
  ngOnInit(): void {
    this.placeHolderTitle = "Search City";
    this.getWebAccessKeyFun();
  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        this.getPickupCityListPriorityFun();
      } else { }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  pickupCityListPriorityRes: any=[];
  listofAddressDataBlk;
  pickupCityNameList: any;
  getPickupCityListPriorityFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
    };
    //console.log(this.requestData);

    this.ows.getPickupCityListPriority(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.pickupCityListPriorityRes = data;
      console.log(this.pickupCityListPriorityRes);

      if(this.pickupCityListPriorityRes.status == 1){
        this.pickupCityNameList = this.pickupCityListPriorityRes["City Name"];
        console.log(this.pickupCityNameList);
        this.listofAddressDataBlk = true;

        this.visitorCityCmp.nativeElement.focus();
      } else {}

    },error =>{
      console.log("pickupCityListPriorityRes error :",error);
    });

  }
 
  searchTerm: string = '';
  search() {
    if(this.pickupCityListPriorityRes.status == 0){
      const searchTermVal = this.searchTerm.toLowerCase();
      //this.pickupAreaSearch(searchTermVal);
      this.listofAddressDataBlk = false;
    } else {

    this.pickupCityNameList = this.pickupCityListPriorityRes["City Name"].filter(item =>
      item.cityName.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
    console.log(this.pickupCityNameList.length);

    if(this.pickupCityNameList.length < 1){
      console.log(this.pickupCityNameList.length);
      //console.log(this.searchTerm.toLowerCase());
      const searchTermVal = this.searchTerm.toLowerCase();
      
      //this.pickupAreaSearch(searchTermVal);
      this.listofAddressDataBlk = false;
    } else {
      this.listofAddressDataBlk = true;
      //this.listofGoogleDataBlk = false;
    }
  
    }  

  }

  savePickupData(cityData){
    this.visitorCityHide.emit({
      'cityId' : cityData.cityId,
      'cityName' : cityData.cityName,
      'cityLatitude' : cityData.cityLatitude,
      'cityLongitude' : cityData.cityLongitude,
      'stateName' : cityData.stateName
    });
  }

  goBack(){
    this.visitorCityHide.emit({
      'cityId' : '',
      'cityName' : '',
      'cityLatitude' : '',
      'cityLongitude' : '',
      'stateName' : ''
    });
  }

}