import { Component, OnInit, Input, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { OnewayPartnerEnrolApiService } from '../../service/oneway-partner-enrol-api.service';
import { GoogleService } from '../../service/google.service';
declare var gtag;

@Component({
  selector: 'app-trip-info',
  templateUrl: './trip-info.component.html',
  styleUrls: ['./trip-info.component.css']
})
export class TripInfoComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();

  constructor(private fb: FormBuilder, 
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private ows: OnewayWebapiService, 
    private opeas: OnewayPartnerEnrolApiService, 
    private gs: GoogleService) { 
      gtag('event', 'conversion', {
        'send_to': 'AW-978047633/pKXtCPTd6nQQkaWv0gM',
        'transaction_id': ''
      });
    }

  bookingId: any;  
  displayBookingId: any;
  ngOnInit(): void {

    //const userId = "103594";
    const userId = sessionStorage.getItem('userId');
    if(userId == "" || userId == null || userId == undefined){
      console.log("if");
      //this.router.navigate(['book/'+this.pickupCity+'/'+this.dropCity+'/'+this.carType]);
      this.router.navigate(['book/ahmedabad/vadodara/hatchback']);
    }
    console.log(userId);

    // const displayBookingId = "50103495";
    // this.bookingId = "103479";
    // this.displayBookingId = "50103495";
    const displayBookingId = sessionStorage.getItem('displayBookingId');
    if(displayBookingId == "" || displayBookingId == null || displayBookingId == undefined){
      this.router.navigate(['tripFareType']);
    } else {
      this.bookingId = sessionStorage.getItem('bookingId');
      this.displayBookingId = sessionStorage.getItem('displayBookingId');
    }

    this.getCountryFlagListFun();
    this.getWebAccessKeyFun();

  }

  countryFlagListRes: any=[];
  countryFlagList;
  getCountryFlagListFun(){

    this.opeas.getCountryFlagList().subscribe((data : {})=>{
      this.countryFlagListRes = data;
      if(this.countryFlagListRes.status == 1){
        // console.log(this.countryFlagListRes);
        // this.selectedCountry = this.countryFlagListRes.listOfCountry[0];
        // console.log(this.selectedCountry);
        // this.countryFlagList = true;
      } else { }

    },error =>{
      console.log("countryFlagListRes error :",error);
    });

  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        this.bookingDetailsFun();
      } else {}


    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  bookingDetailsRes: any=[];
  countryFlag: any;
  bookingDetailsFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      bookId : this.displayBookingId
    };
    //console.log(this.requestData);

    this.ows.bookingDetails(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.bookingDetailsRes = data;
      console.log(this.bookingDetailsRes);
      if(this.bookingDetailsRes.status == 1){
        console.log(this.countryFlagListRes.listOfCountry.length);

        const countryFlagLength = this.countryFlagListRes.listOfCountry.length;
        for(var i=0; i<countryFlagLength; i++){
          if(this.bookingDetailsRes.countryCode == this.countryFlagListRes.listOfCountry[i].countryCode){
            this.countryFlag = this.countryFlagListRes.listOfCountry[i].countryFlag;
          }
        }

      } else {}

    },error =>{
      console.log("bookingDetailsRes error :",error);
    });

  }

}