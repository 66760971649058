<div class="container">
  
    <div class="row mt-4">

        <div class="col-md-12 col-12 mb-2">
            <div class="card border-0" style="background: #008bd2;">
                <div class="card-body py-2">
                    <div class="row" style="color:#ffd500;">
                        <div class="col-md-8 col-8 text-left">
                            <h3 class="mb-0">OneWay Cash Available</h3>
                        </div>
                        <div class="col-md-4 col-4 text-right">
                            <h3 class="mb-0">{{this.userWalletAmountRes.wallteAmount}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>        

  
        <div class="col-md-12 col-12">
            <div class="card">

              <div class="card-body">

                <div class="table-responsive">
      
                    <table class="table table-bordered table-striped">
                      
                      <thead>
                        <tr class="text-center">
                            <th>Date</th>
                            <th>Reason</th>
                            <th>Amount</th>
                          </tr>
                      </thead>
                      
                      <tbody class="text-center">
                        <tr *ngFor="let data of userWalletData">
                          <td>{{data.date}}</td>
                          <td>{{data.reason}}</td>
                          <td>{{data.amount}}</td>
                        </tr>
                      </tbody>
      
                    </table>
          
                </div>

                <div class="col-md-12 col-12 text-right pr-0">
                    <button *ngIf="loadMoreBtn" class="btn btn-warning" (click)="walletTransactionHistory(this.offset)">
                        Load More
                    </button>
                </div>

              </div>
              
            </div>
        
        </div>  
  
    </div>
    
</div>