<div class="container-fluid">

    <div class="container">

        <div *ngIf="accontDelectionRequest" class="row mt-3">

            <div class="col-md-12 col-12 text-center">
                <h2 class="align-self-center headingPage">
                    Delete My Account
                </h2>
            </div>

            <div class="col-md-12 col-12">

                <p class="plainTextPage">
                    We are sorry to hear that you would like to delete your account. If we can resolve your concerns and
                    help you stay, please give us a chance. Please mail at care@oneway.cab
                </p>

                <p class="plainTextPage">
                    In case you have made up your mind, we will process your request to delete the account. All
                    associated non-essential data would be deleted. Any data required from regulatory perspective would
                    be stored. Example Financial Information like Invoices etc.
                </p>

            </div>

        </div>

        <div *ngIf="accontDelectionRequest" class="row">
            <div class="col-md-6 col-12">

                <form [formGroup]='loginForm'>

                    <div *ngIf="mobileNumberScreen" class="form-group">

                        <label>Request you to please validate your Mobile Number:</label>
                        <input type="text" class="form-control" formControlName="mobileNumber"
                            placeholder="Enter Mobile Number"
                            maxlength="10"
                            (keypress)="keyPressForNumber($event)">
                    </div>

                    <button *ngIf="mobileNumberSubmit" class="btn btn-success" (click)="getLoginRegistrationFun()">Submit</button>

                    <button *ngIf="mobileNumberInProcess" class="btn btn-danger" (click)="getLoginRegistrationFun()">In Process...</button>

                    <div *ngIf="otpScreen" class="form-group">

                        <label for="otpNumber">Enter OTP sent on + 91 - {{mobileNumberDisplay}}</label>
                        <input type="text" class="form-control" formControlName="otpNumber" placeholder="Enter OTP"
                        maxlength="6"
                        (keypress)="keyPressForNumber($event)">

                    </div>

                    <button *ngIf="otpScreen" class="btn btn-success" (click)="webOTPVerificationFun()">Verify</button>

                </form>

            </div>

            
        </div>

        <div *ngIf="accontDelectionResponse" class="row mt-3">
            <div class="col-md-12 col-12">
                <h2>Account Deletion Request Submitted</h2>
                <p>
                    We have taken your account deletion request. Same will be processed within 2 Weeks.
                </p>
            </div>
        </div>

    </div>

</div>