<div class="container-fluid">

  <div class="container">

    <div class="row mt-3">

      <div class="col-md-3 col-0"></div>
      
      <div class="col-md-6 col-12">
        <div class="row trip-faretype-custom py-3">

          <div class="col-md-12 col-12 text-center">
              <h3>{{this.pickupCity | titlecase}} to {{this.dropCity | titlecase}}</h3>
          </div>

          <div *ngIf="carTypeBlk" class="col-md-12 col-12 text-center">

            <div class="card border-0">
              <div class="card-body p-0">
                <div class="tab-container">

                  <div *ngFor="let carTypeModel of this.webOnewayDetailRes.listofvalue; let i= index">
                    <i class="bi bi-info-circle info-icon" 
                        (click)="fetchCarModelDetailsAsPerCarTypeFun(this.webOnewayDetailRes.listofvalue[i])"></i>

                    <div class="tab" [ngClass]="{ 'active': activeTab === this.webOnewayDetailRes.listofvalue[i].CarType }" (click)="changeCabType(this.webOnewayDetailRes.listofvalue[i].CarType,this.webOnewayDetailRes.listofvalue[i])">
                      <div class="tab-circle">
                        <img src="../../assets/img/{{this.webOnewayDetailRes.listofvalue[i].CarType | lowercase}}.png" alt="Tab {{ oneBasedIndices[i] }}">
                        
                      </div>
                      <p [ngClass]="{ 'active-text': activeTab === this.webOnewayDetailRes.listofvalue[i].CarType }">{{carTypeModel.CarType}}</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          
          </div>

          <div class="col-md-6 col-6 top-border mt-3">
            <h3 class="my-1" style="color: #aba1a1; font-size: 14px;">
              Select Pickup Date
            </h3>

            <select class="form-control" (change)="onDateChange($event)" [(ngModel)]="this.pickupDate">
              <option *ngFor="let dateOption of dateOptions" [value]="dateOption.value">{{ dateOption.label }}</option>
            </select>

            <!-- <app-date-time-view></app-date-time-view> -->
            <!-- <app-date-selector></app-date-selector> -->

          </div>

          <div class="col-md-6 col-6 top-border mt-3">
            <h3 class="my-1" style="color: #aba1a1; font-size: 14px;">
              Select Pickup Time
            </h3>

            <!-- <app-time-selector></app-time-selector> -->

            <select class="form-control" (change)="onTimeChange($event)" [(ngModel)]="this.pickupTime">
              <option *ngFor="let timeData of this.routeWithDynamicTimeFareRes.dynamicFareTimeWise" [value]="timeData.time">
              {{ timeData.time | time12hour}}
              </option>
            </select>

          </div>

          <div class="col-md-12 col-12 top-border mt-3" >

            <p class="mt-2">Is Your Trip Confirmed ?</p>   

            <div class="card shadow mb-2" style="border: none;" id="flexiModal">

                <div *ngIf="beforeData" class="card-body py-2">
                    <p class="custom-label mb-1" style="font-size: 13px; font-weight: 500!important;"> 
                      Yes : Saver Fare
                    </p>
                    <ul class="mb-1 pl-3" style="color: #717070; font-size: 13px;">
                      <li>Minimum Advance Required : <i class="fa fa-rupee"></i> {{this.minimumAdvanceRequiredDisplay}}</li>
                      <li>Cancellation Charge : {{this.maximumCancellationCharge}}</li>
                    </ul>
                </div>

                <div *ngIf="afterData" class="card-body py-2">
                    <p class="custom-label mb-1" style="font-size: 13px; font-weight: 500!important;"> 
                      Yes : Saver Fare
                    </p>
                    <ul class="mb-1 pl-3" style="color: #717070; font-size: 13px;">
                      <li>Minimum Advance Required : <i class="fa fa-rupee"></i> {{this.minimumAdvanceRequiredDisplay}}</li>
                    </ul>
                    
                    <p class="custom-label mb-1" style="font-size: 13px; font-weight: 500!important;">
                      Cancellation Charge :
                    </p>
                    <ul class="mb-1 pl-3" style="color: #717070; font-size: 13px;">
                      <li>Before {{this.formattedDateDisplay}} : <i class="fa fa-rupee"></i> {{this.saverFareTypeFetchRes.minimumCancellationCharge}}</li>
                      <li>After {{this.formattedDateDisplay}} : <i class="fa fa-rupee"></i> {{this.maximumCancellationCharge}}</li>
                    </ul>
                </div>

                <div class="card-footer border-top-0 py-2" style="background-color: aliceblue;">
                  <div class="row">

                    <div class="col-md-6 col-6 text-left">
                      <p class="mt-2 font-weight-bold">
                        <i class="fa fa-rupee"></i> {{this.saverFare}}</p>
                    </div>

                    <div class="col-md-6 col-6 text-right">
                      <!-- <button class="btn btn-warning shadow" style="border-radius: 25px;" data-toggle="modal" data-target="#saverModal">
                        SELECT
                      </button> -->

                      <button class="btn btn-warning shadow" style="border-radius: 25px;" 
                              (click)="saverModalShow()">
                        SELECT
                      </button>

                    </div>
                    
                  </div>
                </div>
            </div>

            <div *ngIf="flexiFareBlk" class="card shadow mb-2" style="border: none;">
              <div class="card-body py-2">
                  <p class="custom-label mb-1" style="font-size: 13px; font-weight: 500!important;"> 
                    No : Flexi Fare
                  </p>
                  <ul class="mb-1 pl-3" style="color: #717070; font-size: 13px;">
                    <li> Minimum Advance Required : <i class="fa fa-rupee"></i> 0</li>
                    <li>Cancellation Charge : <i class="fa fa-rupee"></i> 0</li>
                  </ul>
              </div>
              <div class="card-footer border-top-0 py-2" style="background-color: aliceblue;">
                <div class="row">

                  <div class="col-md-6 col-6 text-left">
                    <p class="mt-2 font-weight-bold">
                      <i class="fa fa-rupee"></i> {{this.flexiFare}}</p>
                  </div>

                  <div *ngIf="flexiFareBtn" class="col-md-6 col-6 text-right">
                    <button *ngIf="flexiBtn" class="btn btn-warning shadow" style="border-radius: 25px;" (click)="dynamicWebBookRideOnewayFun('0',this.flexiFare,'flexi')">
                      SELECT
                    </button>

                    <button *ngIf="flexiInProgressBtn" class="btn btn-secondary shadow" style="border-radius: 25px;" disabled>
                      In Progress..
                    </button>

                  </div>
                  
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="carTypeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header">
        <h3 class="modal-title">{{this.carTypeDisplay}} include any of the following or similar cab models</h3>
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>

      <div class="modal-body">

        <!-- <div *ngFor="let _ of [1, 2, 3]" class="row"> -->
        <div *ngFor="let data of carModelDetailsAsPerCarTypeRes.list" class="row">
          <div class="col-md-12 col-12">
            <div class="media">
              <img src="{{data.carModelImage}}" alt="Hatchback" class="align-self-center mr-3"
                style="width: 100px; height: 50px;">
              <div class="media-body text-left">
                <h4 class="mb-0" style="color: #aaa; font-size: 12px;">{{data.carManufactureType}}</h4>
                <p style="font-size: 12px;">{{data.carModelType}} - {{data.carFuelType}}</p>
              </div>
            </div>
          </div>
          <hr style="width: 100%;">
        </div>

        <div class="row text-right">
           <div class="col-md-12 col-12">
            <label class="text-secondary skipText" data-dismiss="modal"> OK </label>
           </div>
        </div>
        
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="saverModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-header border-bottom-0 pb-0">
        <h3 class="modal-title mb-0">Payment Options</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">

        <div class="row">

          <div class="col-md-12 col-12">

            <div class="card shadow border-0 mb-2">
              <div class="card-body p-0">
                <div class="d-flex">
                  <div class="p-2 mr-auto">
                    <h4 class="mb-0" style="font-size: 14px;">
                      Pay Rs {{this.minimumAdvanceRequiredDisplay}}
                    </h4>
                    <p style="font-size: 12px; color: #aaa; ">
                      And Rest to Driver
                    </p>
                  </div>
                  <div class="px-2 pt-3">
                    <button *ngIf="saverPartBtn" class="btn btn-warning shadow px-4" style="border-radius: 25px;"  (click)="dynamicWebBookRideOnewayFun('1',this.minimumAdvanceRequiredDisplay,'saverPart')"> 
                      SELECT 
                    </button>

                    <button *ngIf="saverPartInProgressBtn" class="btn btn-secondary shadow" style="border-radius: 25px;" disabled>
                      In Progress..
                    </button>

                  </div>
                </div>
              </div>
            </div>
           
            <div class="card shadow border-0">
              <div class="card-body p-0">
                <div class="d-flex">
                  <div class="p-2 mr-auto">
                    <h4 class="mb-0" style="font-size: 14px;">
                      Pay Full Amount
                    </h4>
                    <p style="font-size: 12px; color: #aaa; ">
                      No Hassle of Paying to Driver
                    </p>
                  </div>

                  <div class="px-2 pt-3">
                    <button *ngIf="saverFullBtn" class="btn btn-warning shadow px-4" style="border-radius: 25px;"  (click)="dynamicWebBookRideOnewayFun('1',this.saverFare,'saverFull')"> 
                      SELECT 
                    </button>

                    <button *ngIf="saverFullInProgressBtn" class="btn btn-secondary shadow" style="border-radius: 25px;" disabled>
                      In Progress..
                    </button>

                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
        
      </div>

    </div>
  </div>
</div>