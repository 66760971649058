import { Injectable } from '@angular/core';
import { Observable,throwError,forkJoin } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { APiProperties } from '../../app/class/api-properties';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  apiProperties : APiProperties = new APiProperties();
  constructor(private http:HttpClient) { }

  //corsUrl = 'http://43.205.181.218:3040/';
  corsUrl = 'https://cors.oneway.cab/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  gacHttpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin':  '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    })
  }

  googlePlaceAutoComplete(searchText,location) : Observable<{}>{
    console.log('https://maps.googleapis.com/maps/api/place/autocomplete/json?input='+searchText+'&types=establishment&location='+location+'&radius=60000&strictbounds&components=country:in'+'&key='+this.apiProperties.googleKey);
    return this.http.post(this.corsUrl+'https://maps.googleapis.com/maps/api/place/autocomplete/json?input='+searchText+'&types=establishment&location='+location+'&radius=60000&strictbounds&components=country:in'+'&key='+this.apiProperties.googleKey, this.gacHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  googlePlaceAutoCompleteWithoutLatLong(searchText,location) : Observable<{}>{
    console.log('https://maps.googleapis.com/maps/api/place/autocomplete/json?input='+searchText+'&components=country:in'+'&key='+this.apiProperties.googleKey);
    return this.http.post(this.corsUrl+'https://maps.googleapis.com/maps/api/place/autocomplete/json?input='+searchText+'&components=country:in'+'&key='+this.apiProperties.googleKey, this.gacHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  googlePlaceDetail(placeId) : Observable<{}>{
    return this.http.post(this.corsUrl+'https://maps.googleapis.com/maps/api/place/details/json?place_id='+placeId+'&fields=address_component,geometry,adr_address,formatted_phone_number,international_phone_number,website&key='+this.apiProperties.googleKey, this.gacHttpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }

}