import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // Define your list of dates and times here
  dateTimes = [
    { date: '2023-08-22', time: '10:00 AM' },
    { date: '2023-08-23', time: '02:30 PM' },
    // ... more date-time objects
  ];

}
