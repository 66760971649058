import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule,DatePipe } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';

import { AppComponent } from './app.component';
import { FeedbackCompletedRideComponent } from './pages/feedback-completed-ride/feedback-completed-ride.component';
import { FeedbackInjourneyRideComponent } from './pages/feedback-injourney-ride/feedback-injourney-ride.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { FeedbackFirstRideComponent } from './pages/feedback-first-ride/feedback-first-ride.component';
import { CareerComponent } from './pages/career/career.component';
import { FeedbackCancelRideComponent } from './pages/feedback-cancel-ride/feedback-cancel-ride.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';
import { LoginComponent } from './pages/login/login.component';
import { UpcommingTripComponent } from './pages/upcomming-trip/upcomming-trip.component';
import { CompletedTripComponent } from './pages/completed-trip/completed-trip.component';
import { CancelTripComponent } from './pages/cancel-trip/cancel-trip.component';
import { TripDetailComponent } from './pages/trip-detail/trip-detail.component';
import { TripFaretypeComponent } from './pages/trip-faretype/trip-faretype.component';
import { PickupComponent } from './pages/pickup/pickup.component';
import { DropComponent } from './pages/drop/drop.component';
import { GstComponent } from './pages/gst/gst.component';
import { TestComponent } from './pages/test/test.component';
import { TestModalComponent } from './pages/test-modal/test-modal.component';
import { TripPaymentComponent } from './pages/trip-payment/trip-payment.component';
import { TripInfoComponent } from './pages/trip-info/trip-info.component';
import { DateTimeViewComponent } from './pages/date-time-view/date-time-view.component';
import { TimeSelectorComponent } from './pages/time-selector/time-selector.component';
import { TestLoginComponent } from './pages/test-login/test-login.component';
import { DateSelectorComponent } from './pages/date-selector/date-selector.component';
import { TripTempBookingComponent } from './pages/trip-temp-booking/trip-temp-booking.component';
import { Time12hourPipe } from './pipe/time12hour.pipe';
import { LoginBackupComponent } from './pages/login-backup/login-backup.component';
import { ModifyTimeComponent } from './pages/modify-time/modify-time.component';
import { CorporateLoginComponent } from './pages/corporate-login/corporate-login.component';
import { NormalCorporateLoginBarComponent } from './pages/normal-corporate-login-bar/normal-corporate-login-bar.component';
import { PassengerComponent } from './pages/passenger/passenger.component';
import { VisitorAddressVerifyComponent } from './pages/visitor-address-verify/visitor-address-verify.component';
import { VisitorAddressComponent } from './pages/visitor-address/visitor-address.component';
import { UserWalletComponent } from './pages/user-wallet/user-wallet.component';
import { VisitorCityComponent } from './pages/visitor-city/visitor-city.component';
import { VisitorAreaComponent } from './pages/visitor-area/visitor-area.component';
import { VisitorSocietyComponent } from './pages/visitor-society/visitor-society.component';
import { CouponsComponent } from './pages/coupons/coupons.component';

@NgModule({
  declarations: [
    AppComponent,
    FeedbackCompletedRideComponent,
    FeedbackInjourneyRideComponent,
    HeaderComponent,
    FooterComponent,
    FeedbackFirstRideComponent,
    CareerComponent,
    FeedbackCancelRideComponent,
    DeleteAccountComponent,
    LoginComponent,
    UpcommingTripComponent,
    CompletedTripComponent,
    CancelTripComponent,
    TripDetailComponent,
    TripFaretypeComponent,
    PickupComponent,
    DropComponent,
    GstComponent,
    TestComponent,
    TestModalComponent,
    TripPaymentComponent,
    TripInfoComponent,
    DateTimeViewComponent,
    TimeSelectorComponent,
    TestLoginComponent,
    DateSelectorComponent,
    TripTempBookingComponent,
    Time12hourPipe,
    LoginBackupComponent,
    ModifyTimeComponent,
    CorporateLoginComponent,
    NormalCorporateLoginBarComponent,
    PassengerComponent,
    VisitorAddressVerifyComponent,
    VisitorAddressComponent,
    UserWalletComponent,
    VisitorCityComponent,
    VisitorAreaComponent,
    VisitorSocietyComponent,
    CouponsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule,
    MatDialogModule,
    MatRadioModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
