


<div class="container-fluid">

    <div class="container" *ngIf="userVerificationCnt">

        <div class="row mt-3">
            
            <div class="col-md-3 col-0"></div>
            
            <div class="col-md-6 col-12">

                <div class="card shadow">

                    <div class="card-body">
              
                      <form [formGroup]="loginForm">

                        <div *ngIf="mobileNumberScreen" class="row mb-3">

                            <div class="col-md-12 col-12 mb-2">
                                <input type="text" class="form-control" formControlName="passengerName" placeholder="Name" required>
                            </div>

                            <!-- <div class="col-md-12 col-12">
                                <input type="text" class="form-control" formControlName="email" placeholder="Email Id" (keypress)="validateEmail($event)" required>

                                <div *ngIf="isEmailInvalid" class="text-danger">
                                    Invalid email address.
                                </div>

                            </div> -->

                            <div class="col-md-3 col-3" style="padding-right: 0px;">
                              
                                <div (click)="toggleCountryList()" class="selected-item">
              
                                      <div *ngIf="selectedCountry" style="padding-left: 5px; font-size: 11px;">
                                        
                                        <img src="{{selectedCountry.countryFlag}}" alt="" />
                                        {{selectedCountry.countryCode}}
                                        <input type="hidden" id="allowDigit" value="{{selectedCountry.allowDigit}}">
                                        <input type="hidden" id="countryCodeVal" value="{{selectedCountry.countryCode}}">
                                      </div>
              
                                      <ul *ngIf="countryListOpen || !selectedCountry" class="list" id="list">
                                        <li *ngFor="let data of countryFlagListRes.listOfCountry" (click)="selectCountry(data)"
                                            [class.selected]="data === selectedCountry">
                                          <img src="{{data.countryFlag}}" alt="" />
                                          {{data.countryCode}}
                                          <input type="hidden" id="allowDigit" value="{{data.allowDigit}}">
                                          <input type="hidden" id="countryCodeVal" value="{{data.countryCode}}">
                                        </li>
                                      </ul>
                                </div>
                                  
                            </div>
              
                            <div class="col-md-9 col-9">
                              <input type="tel" class="form-control" formControlName="mobileNumber" maxlength="10"
                              (keypress)="keyPressForNumber($event)" placeholder="Mobile Number" required>
                            </div>
              
                        </div>

                        <div *ngIf="mobileNumberScreen" class="form-group text-right">
                          <button *ngIf="mobileNumberSubmit" class="btn btn-success" (click)="getLoginRegistrationFun()">
                            Submit
                          </button>
                          <button *ngIf="mobileNumberInProcess" class="btn btn-danger" (click)="getLoginRegistrationFun()">
                            In Process...
                          </button>
                        </div>

                        <div *ngIf="corporateDataBlk" class="row">
                          <div class="col-md-12 col-12">
                            <label>Mobile Number : <b>{{this.corporateMobileNumber}}</b></label>
                          </div>
                          <div *ngIf="corporateEmailBlk" class="col-md-12 col-12">
                            <label>Email Id : <b>{{this.corporateEmail}}</b></label>
                          </div>
                        </div>

                        <div *ngIf="otpScreen" class="form-group">
                          <input type="text" class="form-control" formControlName="otpNumber" placeholder="Enter OTP" required>
                        </div>
              
                        <div *ngIf="otpScreen" class="form-group text-right">
                          <button type="submit" class="btn btn-primary" (click)="webOTPVerificationFun()">Confirm</button>
                        </div>
              
                      </form>
              
                    </div>
              
                  </div>

            </div>

        </div>

    </div>

    <!-- <div class="container" *ngIf="tripCnt">
        <div class="row">
            <div class="col-md-12 col-12">
                <mat-tab-group dynamicHeight>
                    
                    <mat-tab label="UpComming Trips"> 
                        <app-upcomming-trip *matTabContent></app-upcomming-trip>
                    </mat-tab>

                    <mat-tab label="Completed Trips"> 
                        <app-completed-trip *matTabContent></app-completed-trip>   
                    </mat-tab>
                    
                    <mat-tab label="Cancel Trip"> 
                        <app-cancel-trip *matTabContent></app-cancel-trip>
                    </mat-tab>

                  </mat-tab-group>
            </div>
        </div>
    </div> -->

</div>