import { Component, OnInit, Input, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';

@Component({
  selector: 'app-cancel-trip',
  templateUrl: './cancel-trip.component.html',
  styleUrls: ['./cancel-trip.component.css']
})
export class CancelTripComponent implements OnInit {

  apiProperties : APiProperties = new APiProperties();

  constructor( private fb: FormBuilder, 
               private ows: OnewayWebapiService, 
               private titleService: Title,  
               private metaTagService: Meta) { }

  requestData: any ={};
  corporateUser: any;
  ngOnInit(): void {
    console.log("completed Trip");
    this.corporateUser = sessionStorage.getItem('corporateUser');
    this.getWebAccessKeyFun();
  }

  webAccessKeyRes: any =[];
  webAccessKey: any;
  userName: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      this.userName = this.webAccessKeyRes.userName;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        this.offset = 5;
        this.getCancelRideFun(0);
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  cancelRideData = [];
  cancelRideRes: any=[];
  offset: any;
  loadMoreCancelled;
  getCancelRideFun(offset){

    if(this.corporateUser == '1'){
      this.requestData = {
        userName: this.userName,
        accessKey: this.webAccessKey,
        mobileNo: sessionStorage.getItem('corporateMobileNumber'),
        onlyCorporateBooking : 1,
        offSet: offset //offset value like 0, 5, 10, 15, 25, 30,....
      };
    } else {
      this.requestData = {
        userName: this.userName,
        accessKey: this.webAccessKey,
        mobileNo: sessionStorage.getItem('mobileNo'),
        offSet: offset //offset value like 0, 5, 10, 15, 25, 30,....
      };
    }
    console.log(this.requestData);

    this.ows.getCancelRide(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.cancelRideRes = data;

      if(this.cancelRideRes.status == 1){
        this.offset = offset+5;
        console.log(this.offset); 
        for(var i=0; i<this.cancelRideRes.cancelRide.length; i++){
          this.cancelRideData.push(this.cancelRideRes.cancelRide[i]);
        }
        console.log(this.cancelRideData);

        if(this.cancelRideRes.cancelRide.length < 5){
          this.loadMoreCancelled = false;
        } else {
          this.loadMoreCancelled = true;
        }

      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("cancelRideRes error :",error);
    });

  }

  bookingInfo(displayBookingId){
    var encodeBooking = btoa(displayBookingId);
    var bookingInfoURL = this.apiProperties.siteurlPHP+"/tripInfo/"+encodeBooking;
    window.open(bookingInfoURL, '_blank').focus();
  }

}