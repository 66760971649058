<div class="container">
  
  <app-normal-corporate-login-bar></app-normal-corporate-login-bar>

  <div class="row">
    <div class="col-md-12 col-12 mt-2">
      <ul class="nav nav-tabs custom-tabs" id="myTab" role="tablist" style="border-bottom: 0px;">
        <li class="nav-item">
          <a class="nav-link active" href="/upcommingTrip" role="tab">UpComming Trip</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/completedTrip" role="tab">Completed Trip</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/cancelTrip" role="tab">Cancel Trip</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="row mt-4">

    <div *ngFor="let data of this.upcommingRideData" class="col-md-4 col-12 mb-3">

      <mat-card style="overflow: hidden;">
        <mat-card-title>{{data.displayBookingId}}</mat-card-title>
        <mat-card-subtitle>{{data.tripType}}</mat-card-subtitle>
        <mat-card-content>
          <p>Pickup @ {{data.pickupDateTime | date: 'd MMM - h:mm a'}}</p>
        </mat-card-content>
        <mat-card-actions align="end">
          <button class="btn btn-danger mr-1"
            (click)="getCancellationReasonNewFun(data.bookingId,'500ms', '500ms',data.displayBookingId)">
            Cancel Trip
          </button>
          <button class="btn btn-warning mr-1" (click)="webModifyPickupTimeSet(data)" data-toggle="modal"
            data-target="#bookModifyModal">Modify</button>
          <button class="btn btn-success" (click)="bookingInfo(data.displayBookingId)">Info</button>

          <!-- <button mat-button (click)="getCancellationReasonNewFun('101738','500ms', '500ms','50101738')">Cancel Trip</button> -->
          <!-- <button mat-button (click)="getCancellationReasonNewFun('99596','500ms', '500ms')">Cancel Trip</button> -->
          <!-- <button class="btn btn-warning mr-1" (click)="bookingModify(data.displayBookingId)">Modify</button> -->

        </mat-card-actions>
      </mat-card>

    </div>

    <div class="col-md-12 col-12 text-right">
      <button *ngIf="loadMoreUpcomming" class="btn btn-warning" (click)="getUpcommingRideFun(this.offset)">Load
        More</button>
    </div>

  </div>

  <ng-template let-data #cancellationReasonDialog>
    <form [formGroup]="cancellationReasonForm">

      <div mat-dialog-content align="center">
        <h2 mat-dialog-title class="mb-0">Cancel Booking Form</h2>
        <hr>
      </div>

      <div mat-dialog-content>
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
          formControlName="cancellationReason" (change)="cancellationReasonSelectFun($event)">
          <mat-radio-button class="example-radio-button" *ngFor="let datadm of data" [value]="datadm">
            {{datadm.reason}}
          </mat-radio-button>
        </mat-radio-group>

        <mat-form-field *ngIf="otherReasonInput" class="example-full-width" appearance="fill">
          <input matInput formControlName="otherReasonInput" placeholder="Enter reason">
        </mat-form-field>

        <div *ngIf="this.cancellationReasonForm.get('cancellationReason').hasError('required')"
          class="error text-danger">
          Select Cancellation Reason
        </div>
      </div>

      <div mat-dialog-actions align="end">
        <button mat-button mat-button mat-raised-button color="warn"
          (click)="closeCancellationReasonDialog()">Cancel</button>
        <button mat-button mat-button mat-raised-button color="primary"
          (click)="validateCancelRideFun('500ms', '500ms')" cdkFocusInitial>Submit</button>
      </div>

    </form>
  </ng-template>

  <ng-template let-data #RefundDialog>
    <form [formGroup]="refundForm">

      <div mat-dialog-content align="center">
        <h2 mat-dialog-title class="mb-0">Cancellation Fee : Rs {{this.validateCancelRideRes.totalCancellationCharge}}
        </h2>
        <h2 mat-dialog-title class="mb-0">Refund Amount : Rs {{this.totalRefundAmount}}</h2>
        <hr>
      </div>

      <div mat-dialog-content>
        <p>Select Refund Options</p>
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
          formControlName="refundMode" (change)="refundSelectFun($event)">
          <mat-radio-button class="example-radio-button" value="2"> Wallet Recharge </mat-radio-button>
          <mat-radio-button class="example-radio-button" value="1"> Refund to original mode of payment
          </mat-radio-button>
        </mat-radio-group>

        <div *ngIf="refundForm.get('refundMode').hasError('required')" class="error text-danger">
          Select refund option
        </div>
      </div>

      <div mat-dialog-actions align="end">
        <button mat-button mat-raised-button color="warn" (click)="closeRefundDialog()">Cancel</button>
        <button mat-button mat-raised-button color="primary" (click)="cancelRideRefundFun()" cdkFocusInitial>Ok</button>
      </div>

    </form>
  </ng-template>

  <ng-template let-data #RefundResponseDialog>
    <div mat-dialog-content align="center">
      <h2 mat-dialog-title class="mb-0">{{this.cancelRideRefundRes.message}}</h2>
    </div>

    <div mat-dialog-actions align="end">
      <button mat-raised-button color="primary" (click)="closeRefundResponseDialog()" cdkFocusInitial>Close</button>
    </div>

  </ng-template>

  <ng-template let-data #cancelRideDialog>
    <div mat-dialog-content align="center">
      <h2 mat-dialog-title class="mb-0">{{this.cancelRideRes.message}}</h2>
    </div>

    <div mat-dialog-actions align="end">
      <button mat-raised-button color="primary" (click)="closeCancelRideDialog()" cdkFocusInitial>Close</button>
    </div>

  </ng-template>

  <div class="modal fade" id="bookModifyModal" #closeBookModifyModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-header pb-1 pt-1">
          <h2 class="modal-title mb-0">Modify Booking Information</h2>
        </div>

        <div class="modal-body">

          <div *ngIf="bookingDetailBlk" class="row">
            <div class="col-md-12 col-12">
              <p class="mb-1">Booking ID : <b>{{bookingDetailsRes.displayBookingId}}</b></p>
              <p class="mb-1">Pickup Date-Time : <b>{{bookingDetailsRes.pickupDateTime | date: 'd MMM - h:mm a'}}</b></p>
              <p class="mb-1">Cab Type : <b>{{bookingDetailsRes.carType}}</b></p>

              <!-- <p>Trip : <b>{{bookingDetailsRes.packageTypeText}}</b></p>
              <p>Route : <b>{{bookingDetailsRes.routeDetails}}</b></p>
              <p>Pick Up Address : <b>{{bookingDetailsRes.pickUpAddress}}</b></p>
              <p>Drop Address : <b>{{bookingDetailsRes.dropAddress}}</b></p> -->
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-12">
              <p class="mb-1" style="color: blue; letter-spacing: 1px;">Pick-up Date & Time can be modified if</p>
              <ul class="pl-4">
                <li class="fareLi">
                  <i>Fare has not changed</i>
                </li>
                <li class="fareLi">
                  <i>Driver has not been assigned for the trip</i>
                </li>
              </ul>
            </div>
          </div>

          <form [formGroup]="bookingModificationForm">

            <div class="row">

              <div class="col-md-12 col-12 mb-3">
                <app-modify-time *ngIf="modifyTime" [pickupDatePass]="pickupDatePass" [pickupTimePass]="pickupTimePass" (changedDateStore)="changedDateStore($event)" (changedTimeStore)="changedTimeStore($event)"></app-modify-time>
              </div>
              
              <div class="col-md-12 col-12 text-right">
                <button class="btn btn-secondary mr-2" data-dismiss="modal">Close</button>
                <button class="btn btn-success" (click)="webModifyPickupTime()">Submit</button>
                <!-- <button *ngIf="mobileNumberInProcess" class="btn btn-danger">In Process...</button> -->
              </div>

            </div>

          </form>

        </div>
      </div>
    </div>
  </div>

</div>