<div class="container-fluid">

    <div class="container">

        <div class="row mt-3">

            <div class="col-md-6 col-12">

                <div class="row">

                    <div class="col-md-12 col-12">
                        <button class="btn back-button mb-2" (click)="back('')" style="padding: 0px;">
                            <img src="../../assets/img/back-arrow.png" style="height: 25px; width: 25px; margin-right: 50px;">
                        </button>
                        Add Passenger Detail
                    </div>

                    <div class="col-md-12 col-12 mb-2">
                        <div class="card shadow" style="border: none;">
                            <div class="card-body">

                                <form [formGroup]="passengerForm">

                                    <div class="form-group">
                                        <input type="text" class="custom-input form-control" 
                                                formControlName="alternateName" 
                                                placeholder="Alternate Name" 
                                                autocomplete="off"
                                                [ngClass]="{ 'is-invalid': submitted && f.alternateName.errors }">
                                    </div>

                                    <div class="form-group">
                                        <input type="text" class="custom-input form-control" 
                                               formControlName="alternateNumber"  
                                               (keypress)="keyPressForNumber($event)"
                                               placeholder="Alternate Number" maxlength="10"
                                               [ngClass]="{ 'is-invalid': submitted && f.alternateNumber.errors }">
                                    </div>

                                    <div *ngIf="submitted && f.alternateNumber.errors" class="invalid-feedback">
                                        Alternate Number is required
                                    </div>
                                        
                                </form>

                            </div>

                        </div>
                    </div>

                </div>
                
                <div class="row">
                    <div class="form-group col-md-12 col-12 mb-0 text-right">
                        <button class="btn btn-secondary px-3 mr-2" (click)="back('')">close</button>
                        <button type="submit" class="btn btn-warning px-3" (click)="passengerDetailDone()">Save</button>
                    </div>
                </div>

            </div>

        </div>

    </div>

</div>