import { Component, OnInit, Input, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { OnewayPartnerEnrolApiService } from '../../service/oneway-partner-enrol-api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  apiProperties : APiProperties = new APiProperties();

  constructor( private fb: FormBuilder, 
               private router: Router,
               private route: ActivatedRoute,
               private ows: OnewayWebapiService,
               private opeas: OnewayPartnerEnrolApiService, 
               private titleService: Title,  
               private metaTagService: Meta) { }

  userVerificationCnt;
  tripCnt;
  mobileNumberScreen;
  mobileNumberSubmit;
  mobileNumberInProcess;
  otpScreen;
  corporateUser: any;
  corporateDataBlk;
  corporateMobileNumber: any;
  corporateEmail: any;
  corporateEmailBlk;
  ngOnInit(): void {

    this.getWebAccessKeyFun();

    // console.log("login");
    // return;

    console.log(sessionStorage.getItem('userId'));
    //return;

    this.route.queryParams.subscribe(params => {
      // Access the values using the parameter names (uid, ced, pn)
      const userId = atob(params['uid']);
      const mn = atob(params['mn']);
  
      sessionStorage.setItem('userId',userId);
      sessionStorage.setItem('mobileNo',mn);

      console.log('UID:', userId);
    });
    //return;

    const userId = sessionStorage.getItem('userId');
    if(userId == null || userId == undefined || userId == "") {
      this.userVerificationCnt = true;
      //this.tripCnt = false;
      this.mobileNumberScreen = true;
      this.mobileNumberSubmit = true;
      this.mobileNumberInProcess = false;
      this.otpScreen = false;

      this.corporateUser = sessionStorage.getItem('corporateUser');
      if(this.corporateUser == '1'){
        this.corporateMobileNumber = sessionStorage.getItem('corporateMobileNumber');
        this.corporateEmail = sessionStorage.getItem('corporateEmail');
        this.mobileNumberScreen = false;
        this.corporateDataBlk = true;
        this.otpScreen = true;

        if(this.corporateEmail != '') {
          this.corporateEmailBlk = true;
        } else {
          this.corporateEmailBlk = false;
          console.log("else");
        }

      } 

    } else {
      this.userVerificationCnt = false;
      //this.tripCnt = true;

      //this.router.navigate(['/upcommingTrip']);
      this.router.navigate(['/coupons']);
 
    }

    this.getCountryFlagListFun();
  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        //this.getLoginRegistrationFun();
        if(this.corporateUser == '1'){
          this.getLoginRegistrationFun();
        }
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  countryFlagListRes: any=[];
  countryFlagList;
  getCountryFlagListFun(){

    this.opeas.getCountryFlagList().subscribe((data : {})=>{
      this.countryFlagListRes = data;
      if(this.countryFlagListRes.status == 1){
        console.log(this.countryFlagListRes);
        //this.selectedCountry = this.countryFlagListRes.listOfCountry.find(country => country.countryCode === '91');
        this.selectedCountry = this.countryFlagListRes.listOfCountry[0];
        //console.log(this.selectedCountry);
        this.countryFlagList = true;
      } else { }

    },error =>{
      console.log("countryFlagListRes error :",error);
    });

  }

  //new added code
  countryListOpen = false; // Set it to false initially
  selectedCountry: any;

  toggleCountryList() {
    this.countryListOpen = !this.countryListOpen;
  }

  selectCountry(country: any) {
    this.selectedCountry = country;
    //console.log(this.selectedCountry);
    //this.countryListOpen = false;
  }

  loginForm = this.fb.group({
    passengerName: ["",[Validators.required]],
    mobileNumber: ["",[Validators.required,Validators.minLength(10),Validators.maxLength(10),Validators.pattern("^[0-9]*$")]],
    //email: ["",[Validators.required]],
    otpNumber: [""]
  });

  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  mobileNumberDisplay: any;
  loginRegistrationRes: any=[];
  hiddenInputValue: any;
  getLoginRegistrationFun(){

    if(this.corporateUser == '1'){
      //this.mobileNo = sessionStorage.getItem('corporateMobileNumber');
    }else {
      if(this.loginForm.invalid){
        alert("Please Enter Valid Data");
        return false;
      }
      //this.mobileNo = this.loginForm.controls['mobileNumber'].value;

      const hiddenInput = document.getElementById('countryCodeVal') as HTMLInputElement | null;
      // Check if the element exists
      if(hiddenInput) {
        this.hiddenInputValue = hiddenInput.value; 
      } else {
        alert("Please select country code");
        return false;
      }

      this.mobileNumberSubmit = false;
      this.mobileNumberInProcess = true;

    }

    if(this.corporateUser == '1'){
      this.requestData = {
        userName: this.apiProperties.webCompanyName,
        accessKey: this.webAccessKey,
        mobileNo: sessionStorage.getItem('corporateMobileNumber'),
        passengerName: sessionStorage.getItem('corporatePassengerName'),
        email: sessionStorage.getItem('corporateEmail')
      };
    } else {
      this.requestData = {
        userName : this.apiProperties.webCompanyName,
        accessKey : this.webAccessKey,
        mobileNo : this.loginForm.controls['mobileNumber'].value,
        countryCode : this.hiddenInputValue,
        passengerName : this.loginForm.controls['passengerName'].value,
        email : ""
      };
    }
    console.log(this.requestData);

    this.ows.getLoginRegistration(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.loginRegistrationRes = data;
      console.log(this.loginRegistrationRes);
      
      //this.feedbackMessage = this.cancellationReasonRes.message;

      if(this.loginRegistrationRes.status == 1){
        this.mobileNumberDisplay = this.loginForm.controls['mobileNumber'].value;
        this.mobileNumberScreen = false;
        this.mobileNumberSubmit = false;
        this.mobileNumberInProcess = false;
        this.otpScreen = true;
      } else {
        alert(this.loginRegistrationRes.message);
        if(this.corporateUser == '1'){
          this.mobileNumberScreen = false;
          this.mobileNumberSubmit = false;
          this.mobileNumberInProcess = false;
          this.otpScreen = false;
        } else {
          this.mobileNumberScreen = true;
          this.mobileNumberSubmit = true;
          this.mobileNumberInProcess = false;
          this.otpScreen = false;
        }
        
      }

    },error =>{
      console.log("loginRegistrationRes Error :",error);
    });

  }

  otpVerificationRes: any = [];
  mobileNo: any;
  webOTPVerificationFun(){

    if(this.corporateUser == '1'){
      this.mobileNo = sessionStorage.getItem('corporateMobileNumber');
    }else {
      if(this.loginForm.invalid){
        return false;
      }
      this.mobileNo = this.loginForm.controls['mobileNumber'].value;
    }
    
    //sessionStorage.setItem('mobileNo', this.loginForm.controls['mobileNumber'].value);
    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNo : this.mobileNo,
      otp : this.loginForm.controls['otpNumber'].value,
      loginFrom : ""
    };
    console.log(this.requestData);

    this.ows.webOTPVerification(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.otpVerificationRes = data;
      console.log(this.otpVerificationRes);
      //this.feedbackMessage = this.cancellationReasonRes.message;

      if(this.otpVerificationRes.userId != ""){
        
        
        sessionStorage.setItem('mobileNo', this.mobileNo);
        sessionStorage.setItem('userId', this.otpVerificationRes.userId);
        sessionStorage.setItem('customerEmailId', this.otpVerificationRes.customerEmailId);
        sessionStorage.setItem('passengerName', this.otpVerificationRes.passangerName);
        sessionStorage.setItem('countryCode', this.hiddenInputValue);
        sessionStorage.setItem('isCorporateId', this.otpVerificationRes.isCorporateId);

        sessionStorage.setItem('corporateUserId', "");
        sessionStorage.setItem('corporateMobileNumber', "");
        sessionStorage.setItem('corporatePassengerName', "");
        sessionStorage.setItem('corporateEmail', "");
        sessionStorage.setItem('corporateCompanyId', ""); 
        sessionStorage.setItem('corporateUser','0');

        // this.getUserWalletAmountFun(this.otpVerificationRes);
        this.addUpdateWebUserFun(this.otpVerificationRes, this.loginForm.controls['mobileNumber'].value,this.hiddenInputValue);
        
        this.userVerificationCnt = false;
        //this.tripCnt = true; 
      } else {
        
        sessionStorage.setItem('mobileNo', "");
        sessionStorage.setItem('userId', "");
        sessionStorage.setItem('customerEmailId', "");
        //sessionStorage.setItem('passengerName', "");
        sessionStorage.setItem('countryCode', "");

        alert(this.otpVerificationRes.message);
        this.userVerificationCnt = true;
        //this.tripCnt = false; 
      }

    },error =>{
      console.log("otpVerificationRes Error :",error);
    });

  }

  addUpdateWebUserRes: any=[];
  addUpdateWebUserFun(otpVerificationRes, mobileNumber, hiddenInputValue){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      mobileNumber : mobileNumber,
      countryCode : hiddenInputValue,
      customerName : otpVerificationRes.passangerName,
      emailId : otpVerificationRes.customerEmailId
    };
    console.log(this.requestData);

    this.ows.addUpdateWebUser(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.addUpdateWebUserRes = data;
      console.log("addUpdateWebUser :: "+JSON.stringify(this.addUpdateWebUserRes));
      
      //this.router.navigate(['/upcommingTrip']);
      this.router.navigate(['/coupons']);
      //window.location.reload();

    },error =>{
      console.log("addUpdateWebUserRes Error :",error);
    });

  }

}