import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { GoogleService } from '../../service/google.service';

interface City {
  name: string;
  description: string;
}

@Component({
  selector: 'app-pickup',
  templateUrl: './pickup.component.html',
  styleUrls: ['./pickup.component.css']
})
export class PickupComponent implements OnInit {
  @Input() webAccessKey;
  @Output('pickupHide') pickupHide = new EventEmitter();

  apiProperties : APiProperties = new APiProperties();

  constructor( private fb: FormBuilder, 
               private route: ActivatedRoute,
               private ows: OnewayWebapiService, 
               private gs: GoogleService,
               private titleService: Title,  
               private metaTagService: Meta) { }

  ngAfterViewInit() {
    const inputElement = document.querySelector('.search-input') as HTMLInputElement;
    inputElement.setAttribute('autocomplete', 'new-password'); // Use a non-standard value
  }

  pickupCity: any;
  dropCity: any;
  carType: any;
  placeHolderTitle: any;
  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.carType = params['carType'];
    });

    this.pickupCity = sessionStorage.getItem('pickupCity');
    this.dropCity = sessionStorage.getItem('dropCity');

    console.log(this.pickupCity);
    console.log(this.dropCity);

    //this.searchResults = this.sampleData; // Initialize searchResults with all sampleData items
    this.placeHolderTitle = "Pick-up Address in "+this.pickupCity;

    this.getAddressListFun();
    //this.pickupAreaSearch();
    //this.googlePlaceDetailFun('ChIJC1FJcxG_5zsRcV0HQ4qLFMg');

      // console.log(sessionStorage.getItem('pickupAddress'));
      // console.log(sessionStorage.getItem('pickupAddressLat'));
      // console.log(sessionStorage.getItem('pickupAddressLong'));
      // console.log("pickup");

  }

  requestData: any={};
  getAddressListRes: any=[];
  listofAddressData: any=[];
  listofAddressDataBlk;
  getAddressListFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      cityName : this.pickupCity,
	    mobileNo : sessionStorage.getItem('mobileNo')
    };
    console.log(this.requestData);

    this.ows.getAddressList(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.getAddressListRes = data;
      
      if(this.getAddressListRes.status == 1){
        this.listofAddressData = this.getAddressListRes.listofAddress;
        console.log(this.listofAddressData.length);
        //this.getCancellationReasonFun();
        this.listofAddressDataBlk = true;
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("getAddressListRes error :",error);
    });

  }

  searchResults: City[] = [];
  searchTerm: string = '';
  search() {
    if(this.getAddressListRes.status == 0){
      const searchTermVal = this.searchTerm.toLowerCase();
      this.pickupAreaSearch(searchTermVal);
      this.listofAddressDataBlk = false;
    } else {
    this.listofAddressData = this.getAddressListRes.listofAddress.filter(item =>
      item.pickupAddress.toLowerCase().includes(this.searchTerm.toLowerCase())
    );

    //console.log(this.listofAddressData.length);

    if(this.listofAddressData.length < 1){
      console.log(this.listofAddressData.length);
      //console.log(this.searchTerm.toLowerCase());
      const searchTermVal = this.searchTerm.toLowerCase();
      
      this.pickupAreaSearch(searchTermVal);

      this.listofAddressDataBlk = false;
    } else {
      this.listofAddressDataBlk = true;
      this.listofGoogleDataBlk = false;
    }
  
    }  

  }

  searchText: any;
  googleAutoRes: any=[];
  listofGoogleDataBlk;
  pickupAreaSearch(searchTermVal){
    //console.log(x.target.value); 
    //this.searchText = searchText.target.value;

    this.searchText = searchTermVal;
    const location = sessionStorage.getItem('pickupCityLat')+","+sessionStorage.getItem('pickupCityLong');
    
    return this.gs.googlePlaceAutoComplete(this.searchText,location).subscribe((data : {}) => {
      this.googleAutoRes = data;
      // console.log("areaSearch:"+JSON.stringify(this.googleAutoRes));
      // console.log(this.googleAutoRes.predictions[0].place_id);

      this.listofGoogleDataBlk = true;
      
    },error => console.log(error));

  }

  paceId: any;
  googlePlaceDetailRes: any=[];
  googlePlaceDetailFun(place_id,description,main_text,secondary_text){
    
    //console.log(x.target.value); 
    //this.searchText = searchText.target.value;
    this.paceId = place_id;
    //console.log("paceId :: "+this.paceId);
    
    return this.gs.googlePlaceDetail(this.paceId).subscribe((data : {}) => {
      this.googlePlaceDetailRes = data;
      // console.log("googlePlaceDetail:"+JSON.stringify(this.googlePlaceDetailRes));
      // console.log(this.googlePlaceDetailRes.result.geometry.location);
      const locationLatLng = this.googlePlaceDetailRes.result.geometry.location;

      this.saveAddressMasterFun(description,main_text,secondary_text,locationLatLng);
      
    },error => console.log(error));

  }

  saveAddressMasterRes: any=[];
  saveAddressMasterFun(description,main_text,secondary_text,locationLatLng){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      cityId : sessionStorage.getItem('pickupCityId'),
      userId : sessionStorage.getItem('userId'),
      pickupAddress : description,
      primaryAddress : main_text,
      secondaryAddress : secondary_text,
      pickupCityLat : locationLatLng.lat,
      pickupCityLong : locationLatLng.lng
    };
    console.log(this.requestData);
    //return false;

    this.ows.saveAddressMaster(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.saveAddressMasterRes = data;
      console.log(this.saveAddressMasterRes);
      
      if(this.saveAddressMasterRes.status == 1){
       
        const pickupData = {
          'pickupAddress' : description,
          'pickCityLat' : locationLatLng.lat,
          'pickCityLong' : locationLatLng.lng
        }

        this.savePickupData(pickupData);
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("saveAddressMasterRes error :",error);
    });

  }

  savePickupData(pickupData){

    console.log(pickupData.pickupAddress);
    console.log(pickupData.pickCityLat);
    console.log(pickupData.pickCityLong);
    //return false;

    this.pickupHide.emit({
      'pickupAddress' : pickupData.pickupAddress,
      'pickupAddressLat' : pickupData.pickCityLat,
      'pickupAddressLong' : pickupData.pickCityLong
    });
  }

  goBack(){
    this.pickupHide.emit({
      'pickupAddress' : '',
      'pickupAddressLat' : '',
      'pickupAddressLong' : ''
    });
  }

  // searchResults: City[] = [];
  // searchTerm: string = '';
  // search() {
  //   this.searchResults = this.sampleData.filter(item =>
  //     item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
  //   );
  // }

  // sampleData: City[] = [
  //   { name: 'New York', description: 'The city that never sleeps' },
  //   { name: 'Los Angeles', description: 'The entertainment capital of the world' },
  //   { name: 'Chicago', description: 'The Windy City' },
  //   { name: 'Miami', description: 'Sun, beaches, and art deco' },
  //   { name: 'Seattle', description: 'Home of the Space Needle' },
  //   { name: 'San Francisco', description: 'Golden Gate Bridge and tech hub' }
  // ];

}