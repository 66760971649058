import { Component, OnInit, Input, Output, Inject, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APiProperties } from '../../../app/class/api-properties';

@Component({
  selector: 'app-normal-corporate-login-bar',
  templateUrl: './normal-corporate-login-bar.component.html',
  styleUrls: ['./normal-corporate-login-bar.component.css']
})
export class NormalCorporateLoginBarComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  constructor(private router: Router,
    private route: ActivatedRoute,) { }

  normalBookingBtn;
  corporateBookingBtn;
  bookingTitle: any;
  ngOnInit(): void {
    const corporateUser = sessionStorage.getItem('corporateUser');
    if(corporateUser == '1'){
       this.normalBookingBtn = true;
       this.corporateBookingBtn = false;
       this.bookingTitle = "Corporate Booking";
    } else {
      this.normalBookingBtn = false;
      this.corporateBookingBtn = true;
      this.bookingTitle = "Normal Booking";
    }
  }

  normalLoginFun(){
    this.router.navigate(['/login']);
  }

  corporateLoginFun(){
    const uid = btoa(sessionStorage.getItem('userId'));
    const ced = btoa(sessionStorage.getItem('customerEmailId'));
    const pn = btoa(sessionStorage.getItem('passengerName'));
    const mn = btoa(sessionStorage.getItem('mobileNo'));

    // const checkUrl = this.apiProperties.siteurlAngular+"/corporateLogin?uid="+uid+"&ced="+ced+"&pn="+pn+"&mn="+mn;
    // console.log(checkUrl);
    // return false;
    // this.router.navigate(['/corporateLogin'+'?uid='+uid]);

    const url = '/corporateLogin';
    const queryParams = { uid, ced, pn, mn };
    this.router.navigate([url], { queryParams });

    //window.open(url, '_blank');

  }

  logoutUser(userType){

    if(userType == 'normalUser'){
      //console.log("normalUser");
      sessionStorage.setItem('mobileNo', "");
      sessionStorage.setItem('userId', "");
      sessionStorage.setItem('customerEmailId', "");
      sessionStorage.setItem('passengerName', "");
      sessionStorage.setItem('countryCode', "");
  
      var bookingInfoURL = this.apiProperties.siteurlPHP;
      window.location.href = bookingInfoURL;
      //window.open(bookingInfoURL, '_blank').focus();
      //this.router.navigate(['/login']);
    }

    if(userType == 'corporateUser'){
      //console.log("corporateUser");

      const uid = btoa(sessionStorage.getItem('corporateUserId'));
      const ced = btoa(sessionStorage.getItem('corporateEmail'));
      const pn = btoa(sessionStorage.getItem('corporatePassengerName'));
      const mn = btoa(sessionStorage.getItem('corporateMobileNumber'));

      // const checkUrl = this.apiProperties.siteurlAngular+"/corporateLogin?uid="+uid+"&ced="+ced+"&pn="+pn+"&mn="+mn;
      // console.log(checkUrl);
      // return false;
      
      sessionStorage.setItem('corporateUserId', "");
      sessionStorage.setItem('corporateMobileNumber', "");
      sessionStorage.setItem('corporatePassengerName', "");
      sessionStorage.setItem('corporateEmail', "");
      sessionStorage.setItem('corporateCompanyId', ""); 
      sessionStorage.setItem('corporateUser','0');

      const url = '/corporateLogin';
      const queryParams = { uid, ced, pn, mn };
      this.router.navigate([url], { queryParams });
      
    }
    
  }

}