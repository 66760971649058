<div class="container-fluid">

    <div class="container">

        <div class="row mt-3">

            <div class="col-md-6 col-12">

                <div class="row">

                    <div class="col-md-12 col-12">
                        <div class="card shadow">
                            <div class="card-body">

                                <div class="form-row">
                                    <div class="form-group col-md-12 col-12">

                                        <div class="search-header">
                                            <button class="btn" (click)="goBack()" style="padding: 0px;">
                                                <img src="../../assets/img/back-arrow.png" style="height: 25px; width: 25px; margin-right: 10px;">
                                            </button>
                                            <input type="text" [(ngModel)]="searchTerm" (input)="search()" class="search-input" placeholder="{{placeHolderTitle | titlecase}}" autocomplete="off">
                                          </div>

                                          <div *ngIf="listofAddressDataBlk" class="search-results">
                                            <div *ngFor="let result of listofAddressData" class="result-item">
                                              <div class="map-marker"></div>
                                              <div class="result-details" (click)="saveDropData(result)" style="cursor: pointer;">
                                                <div class="result-description">{{ result.pickupAddress }}</div>
                                              </div>
                                            </div>
                                          </div>

                                          <div *ngIf="listofGoogleDataBlk" class="search-results">
                                            <div *ngFor="let gData of googleAutoRes.predictions; index as i" class="result-item">
                                              <div class="map-marker"></div>
                                              <div class="result-details" (click)="googlePlaceDetailFun(gData.place_id,gData.description,gData.structured_formatting.main_text,gData.structured_formatting.secondary_text)" style="cursor: pointer;">
                                                <div class="result-title">{{ gData.structured_formatting.main_text }}</div>
                                                <div class="result-description">{{ gData.structured_formatting.secondary_text }}</div>
                                              </div>
                                            </div>
                                          </div>

                                          <!-- <div *ngIf="searchResults.length > 0" class="search-results">
                                            <div *ngFor="let result of searchResults" class="result-item">
                                              <div class="map-marker"></div>
                                              <div class="result-details">
                                                <div class="result-title">{{ result.name }}</div>
                                                <div class="result-description">{{ result.description }}</div>
                                              </div>
                                            </div>
                                          </div> -->
                                          
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>

</div>