import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time12hour'
})
export class Time12hourPipe implements PipeTransform {
  transform(value: string): string {
    // Implement the transformation here to convert the time to 12-hour format
    // Example implementation:
    const timeParts = value.split(':');
    let hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);

    let period = 'AM';
    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        hours -= 12;
      }
    }

    return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
  }
}

