import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';

@Component({
  selector: 'app-passenger',
  templateUrl: './passenger.component.html',
  styleUrls: ['./passenger.component.css']
})
export class PassengerComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @Input() passengerDataChild;
  @Output('passengerHide') passengerHide = new EventEmitter();
  
  constructor(private fb: FormBuilder, 
              private ows: OnewayWebapiService) { }

  submitted;
  ngOnInit(): void {
    this.submitted = false;
    this.getWebAccessKeyFun();
    if(this.passengerDataChild){
      console.log(this.passengerDataChild);
      this.passengerForm.controls['alternateName'].setValue(this.passengerDataChild.alternateName);
      this.passengerForm.controls['alternateNumber'].setValue(this.passengerDataChild.alternateNumber);
    }

    if(sessionStorage.getItem('alternateName') !=""){
      this.passengerForm.controls['alternateName'].setValue(sessionStorage.getItem('alternateName'));
    }

    if(sessionStorage.getItem('alternateNumber') !=""){
      this.passengerForm.controls['alternateNumber'].setValue(sessionStorage.getItem('alternateNumber'));
    }

  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        //this.getLastTwoGstDetailsFun();
        
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  passengerForm = this.fb.group({
    alternateName : ["",Validators.required],
    alternateNumber : ["",Validators.required]
  });

  get f(){
    return this.passengerForm.controls;
  }

  keyPressForNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  passengerDataDone: any;
  passengerDetailDone(){

    this.submitted = true;

    if(this.passengerForm.invalid){
      return false;
    }

    this.passengerDataDone = {
      alternateName: this.passengerForm.controls['alternateName'].value,
      alternateNumber: this.passengerForm.controls['alternateNumber'].value
    }
    
    this.passengerDetailSave(this.passengerDataDone);
  }

  gstDataStoreTemp: any;
  passengerDetailSave(passengerData){
    sessionStorage.setItem('alternateName',passengerData.alternateName);
    sessionStorage.setItem('alternateNumber',passengerData.alternateNumber);

    this.passengerForm.controls['alternateName'].setValue(passengerData.alternateName);
    this.passengerForm.controls['alternateNumber'].setValue(passengerData.alternateNumber);

    this.back(passengerData);
  }

  back(passengerDataPass){
    this.passengerHide.emit(passengerDataPass);
  }

}
