<div class="container-fluid">

    <div class="container">

        <div class="row mt-3">

            <div class="col-md-3 col-0"></div>
            
            <div class="col-md-6 col-12">

                <div class="row trip-info-custom py-3">

                    <div class="col-md-12 col-12 text-center mb-3">
                        <img src="assets/img/hatchback.png" style="width: 65px; height: 25px;">
                        <br>
                        <h3 class="font-weight-bold mb-0" style="letter-spacing: 1px;">Booking Confirmed!</h3>
                        <label class="mb-1">{{this.bookingDetailsRes.routeDetails}}</label>
                        <p class="mb-1">Booking ID {{this.bookingDetailsRes.displayBookingId}}</p>
                        <p class="mb-1">You will receive driver and cab details</p>
                        <label class="mb-1">2 hour before the pick-up time.</label>
                    </div>

                    <div class="col-md-12 col-12">
                        <div class="card shadow">
                            <div class="card-body">

                                <form>

                                    <div class="form-row">

                                        <div class="form-group col-md-12 col-12">
                                            <label class="grey-label" for="inputName">Passenger Name</label>
                                            <p class="custom-input">
                                                {{this.bookingDetailsRes.guestName}}
                                            </p>
                                        </div>

                                        <div class="form-group col-md-2 col-4">
                                            <span style="display: flex;">
                                                <!-- <img  class="mt-2" src="../../assets/img/flag-india.jpg" alt="Indian Flag" style="width: 45px; height: 30px;"> -->
                                                <!-- <label class="ml-2 mt-3"> +91</label> -->
                                                <img  class="mt-2" src="{{this.countryFlag}}" alt="Flag" style="width: 45px; height: 30px;">
                                                <label class="ml-2 mt-3">{{this.bookingDetailsRes.countryCode}}</label>
                                            </span>
                                        </div>

                                        <div class="form-group col-md-10 col-8">
                                            <label class="grey-label" for="inputMobile">Mobile Number</label>
                                            <p class="custom-input">
                                                {{this.bookingDetailsRes.mobileNumber}}
                                            </p>
                                        </div>

                                        <div class="form-group col-md-12 col-12">
                                            <label class="grey-label" for="inputMobile">Date & Time Of Pickup</label>
                                            <p class="custom-input">
                                                {{this.bookingDetailsRes.pickupDateTime | date:'dd, MMM yyyy hh:mm a'}}
                                            </p>
                                            <!-- <input type="text" class="custom-input form-control custom-height" id="inputMobile" Value="23, Aug 2023 16:45"> -->
                                        </div>

                                        <div class="form-group col-md-12 col-12">
                                            <label class="grey-label" for="inputMobile">Pickup From</label> <br>
                                            <label class="custom-input">
                                                {{this.bookingDetailsRes.pickUpAddress}}
                                            </label>
                                        </div>

                                        <div class="form-group col-md-12 col-12">
                                            <label class="grey-label" for="inputMobile">Drop Off At</label> <br>
                                            <p class="custom-input">
                                                {{this.bookingDetailsRes.dropAddress}}
                                            </p>
                                        </div>

                                        <div class="form-group col-md-12 col-12">
                                            <label>Total Ride Amount : Rs {{this.bookingDetailsRes.totalAmount}}</label> <br/>
                                            <label>Paid Via OneWayCash Wallet : Rs {{this.bookingDetailsRes.oneWayWallet}}</label> <br/>
                                            <label>Pre-Paid Online : Rs. {{this.bookingDetailsRes.onlineAmount}}</label>
                                        </div>

                                    
                                    </div>

                                </form>

                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>


</div>