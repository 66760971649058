import { Component, OnInit, Input, Output } from '@angular/core';
declare var $: any; // Declare the jQuery variable
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { OnewayNodeService } from '../../service/oneway-node.service';
import { GoogleService } from '../../service/google.service';

@Component({
  selector: 'app-trip-payment',
  templateUrl: './trip-payment.component.html',
  styleUrls: ['./trip-payment.component.css'],
  providers: [DatePipe]
})
export class TripPaymentComponent implements OnInit {

  apiProperties : APiProperties = new APiProperties();

  constructor(private fb: FormBuilder, 
              private route: ActivatedRoute,
              private router: Router,
              private datePipe: DatePipe,
              private ows: OnewayWebapiService, 
              private ons: OnewayNodeService,
              private gs: GoogleService,
              private titleService: Title,  
              private metaTagService: Meta) { }

  pickupCity: any;
  dropCity: any;
  carType: any;
  pickupDate: any;
  pickupTime: any;
  sampleData: any;

  userWalletAmount: any;
  fareBreakup: any;
  baseFare: any;
  tollTax: any;
  airportEntryCharge: any;
  interStatePermit: any;
  tollTaxFull: any;
  gst: any;
  totalAmountPayDisplayOnly: any;
  totalAmountPayByCustomer: any;
  isSaverCabBlk;
  amountPaybleNow: any;
  amountPaybleToDriver: any;
  walletBlk;
  payCashToDriver;
  discountBlk;
  countryCode;
  saverPartialAmountPayFull: any;
  ngOnInit(): void {

    if(sessionStorage.getItem('userId') == "" || sessionStorage.getItem('userId') == null){
      this.router.navigate(['book/ahmedabad/vadodara/hatchback']);
      return;
    }

    //console.log("country code :: "+sessionStorage.getItem('countryCode'));
    //return;

    this.countryCode = sessionStorage.getItem('countryCode');
    //this.countryCode = '1';

    this.pickupCity = sessionStorage.getItem('pickupCity');
    this.dropCity = sessionStorage.getItem('dropCity');
    this.carType = sessionStorage.getItem('carType');
    this.pickupDate = sessionStorage.getItem('pickupDate');
    this.pickupTime = sessionStorage.getItem('pickupTime');
    this.userWalletAmount = sessionStorage.getItem('userWalletAmount');

    // console.log(this.userWalletAmount);
    // console.log(sessionStorage.getItem('totalAmountPayByCustomer'));
    // console.log(sessionStorage.getItem('amountPaybleNow'));

    // console.log("fareBreakup :: " +sessionStorage.getItem('fareBreakup'));
    
    const fareBreakup = sessionStorage.getItem('fareBreakup');
    const bookingFareBreakup = JSON.parse(fareBreakup);
    //console.log("bookingFareBreakup :: "+JSON.stringify(bookingFareBreakup));
    //this.baseFare = bookingFareBreakup['Base Fare'];

    const tollTaxExists = "Toll Tax" in bookingFareBreakup;
    if(tollTaxExists) {
      console.log("Toll Tax key is available.");
      this.tollTax = bookingFareBreakup['Toll Tax'];
    } else {
      console.log("Toll Tax key is not available.");
      this.tollTax = 0;
    }

    const airportEntryChargeExists = "Airport Entry Charge" in bookingFareBreakup;
    if(airportEntryChargeExists) {
      this.airportEntryCharge = bookingFareBreakup['Airport Entry Charge'];
    } else {
      this.airportEntryCharge = 0;
    }

    const interStatePermitExists = "Inter-State Permit" in bookingFareBreakup;
    if(interStatePermitExists) {
      this.interStatePermit = bookingFareBreakup['Inter-State Permit'];
    } else {
      this.interStatePermit = 0;
    }

    //this.tollTax = bookingFareBreakup['Toll Tax'];
    //this.airportEntryCharge = bookingFareBreakup['Airport Entry Charge'];
    //this.interStatePermit = bookingFareBreakup['Inter-State Permit'];
    this.tollTaxFull = parseInt(this.tollTax+this.airportEntryCharge+this.interStatePermit);

    // console.log(this.tollTax+""+this.airportEntryCharge+""+this.interStatePermit);
    // console.log(this.tollTaxFull);

    //this.gst = bookingFareBreakup['GST (5.0%)'];

    if(sessionStorage.getItem('gst') != ""){
      this.gst = sessionStorage.getItem('gst');
    } else {
      this.gst = bookingFareBreakup['GST (5.0%)'];
    }

    if(sessionStorage.getItem('baseFare') != ""){
      this.baseFare = sessionStorage.getItem('baseFare');
    } else {
      this.baseFare = bookingFareBreakup['Base Fare'];
    }

    // if(sessionStorage.getItem('isAssuredCab') == '1'){
    //     if(sessionStorage.getItem('flexiSaver') == 'saverFull'){  
    //       //Full Saver Payment
    //       //this.amountPaybleNow = amountPaybleNow+this.tollTaxFull+this.gst;

    //       this.isSaverCabBlk = true;
    //       this.payCashToDriver = false; 
    //       this.walletBlk = true;

    //       this.amountPaybleNow = this.totalAmountPayByCustomer;
    //       this.amountPaybleToDriver = this.totalAmountPayByCustomer-this.amountPaybleNow;

    //       console.log("wallet Not Checked");

    //       if(sessionStorage.getItem('walletChecked') == '1'){

    //         console.log("walletChecked");
    //         this.amountPaybleNow = amountPaybleNow;
    //         this.amountPaybleToDriver = 0;
    //       }

    //     } else {
    //       //Part Saver Payment

    //       console.log("Part Saver Payment");
    //       this.isSaverCabBlk = true;
    //       this.payCashToDriver = false;
    //       this.walletBlk = false;

    //       this.amountPaybleNow = amountPaybleNow;
    //       this.amountPaybleToDriver = this.totalAmountPayByCustomer-this.amountPaybleNow;
    //     }
    // } else {
    //     console.log("Flexi Booking");
    //     this.amountPaybleNow = this.totalAmountPayByCustomer;

    //     if(sessionStorage.getItem('walletChecked') == '1'){
    //       this.amountPaybleNow = amountPaybleNow;
    //     }

    //     this.walletBlk = true;
    //     this.isSaverCabBlk = false;
    //     this.payCashToDriver = true;
    // }

    //console.log(this.amountPaybleToDriver);

    if(sessionStorage.getItem('walletChecked') == '1'){
      this.walletChecked = true;
    } else {
      this.walletChecked = false;
    }

    if(sessionStorage.getItem('fullWalletPayment') == '1'){
      this.fullWalletPayment = true;
    } else {
      this.fullWalletPayment = false;
    }
      
    this.totalAmountPayByCustomer = sessionStorage.getItem('totalAmountPayByCustomer');
    const amountPaybleNow = parseInt(sessionStorage.getItem('amountPaybleNow'));
    
    if(sessionStorage.getItem('flexiSaver') == 'flexi'){
      console.log("Flexi Booking");
      
      this.amountPaybleNow = this.totalAmountPayByCustomer;

      if(sessionStorage.getItem('walletChecked') == '1'){
        //this.amountPaybleNow = amountPaybleNow;

        this.amountPaybleNow = this.totalAmountPayByCustomer - parseInt(this.userWalletAmount);

        if(this.amountPaybleNow < 0){

          this.userWalletAmount = Math.abs(this.amountPaybleNow);
          this.amountPaybleNow = 0;
          this.fullWalletPayment = true;

          // sessionStorage.setItem('amountPaybleNow','0');
          // sessionStorage.setItem('fullWalletPayment','1');   

        } else {
          
          this.userWalletAmount = 0;
          this.fullWalletPayment = false;

          // sessionStorage.setItem('amountPaybleNow',this.amountPaybleNow); 
          // sessionStorage.setItem('fullWalletPayment','0');
        
        }

      } else {

        this.amountPaybleNow = this.totalAmountPayByCustomer;
        this.userWalletAmount = sessionStorage.getItem('userWalletAmount');
      }

      this.discountCalculationFun();

      this.isSaverCabBlk = false;
      this.payCashToDriver = true;
      this.walletBlk = true;
    }

    if(sessionStorage.getItem('flexiSaver') == 'saverFull'){
      console.log("Full Saver Payment");
      //this.amountPaybleNow = amountPaybleNow+this.tollTaxFull+this.gst;

      this.amountPaybleNow = this.totalAmountPayByCustomer;
      this.amountPaybleToDriver = this.totalAmountPayByCustomer-this.amountPaybleNow;

      if(sessionStorage.getItem('walletChecked') == '1'){
        console.log("walletChecked");
        //this.amountPaybleNow = amountPaybleNow;
        this.amountPaybleToDriver = 0;

        this.amountPaybleNow = this.totalAmountPayByCustomer - parseInt(this.userWalletAmount);

        if(this.amountPaybleNow < 0){
          this.userWalletAmount = Math.abs(this.amountPaybleNow);
          this.amountPaybleNow = 0;
          this.fullWalletPayment = true;
          // sessionStorage.setItem('amountPaybleNow','0');
          // sessionStorage.setItem('fullWalletPayment','1');   

        } else {
          
          this.userWalletAmount = 0;
          this.fullWalletPayment = false;

          // sessionStorage.setItem('amountPaybleNow',this.amountPaybleNow); 
          // sessionStorage.setItem('fullWalletPayment','0');
        
        }

      }

      this.discountCalculationFun();

      this.isSaverCabBlk = true;
      this.payCashToDriver = false;
      this.walletBlk = true;

    } 
    
    if(sessionStorage.getItem('flexiSaver') == 'saverPart'){
      console.log("Part Saver Payment");

      this.amountPaybleNow = amountPaybleNow;
      this.amountPaybleToDriver = this.totalAmountPayByCustomer-this.amountPaybleNow;
      console.log("hello :: "+this.amountPaybleToDriver);

      if(sessionStorage.getItem('walletChecked') == '1'){
          console.log("walletChecked");
          //this.amountPaybleToDriver = 0;

          this.saverPartialAmountPayFull = this.amountPaybleNow;
          this.amountPaybleNow = this.amountPaybleNow - parseInt(this.userWalletAmount);

          if(this.amountPaybleNow < 0){
            this.userWalletAmount = Math.abs(this.amountPaybleNow);
            this.amountPaybleNow = 0;
            this.fullWalletPayment = true;
          } else {
            this.userWalletAmount = 0;
            this.fullWalletPayment = false;
          }
      }
      
      this.discountCalculationFun();

      this.isSaverCabBlk = true;
      this.payCashToDriver = false;
      this.walletBlk = true;
    }

    //this.discountCalculationFun();

    console.log(sessionStorage.getItem('bookingId'));
    console.log(sessionStorage.getItem('displayBookingId'));
    console.log(sessionStorage.getItem('fareId'));

    //this.SampleDataGenerate();
    this.getWebAccessKeyFun();
  }

  discountCalculationFun(){
    console.log("couponCode :: "+sessionStorage.getItem('couponCode'));
    const dDiscountCode = sessionStorage.getItem('couponCode');
    if((dDiscountCode == "") || (dDiscountCode == null) || (dDiscountCode == undefined)){
      console.log("if");
      this.discountBlk = false;
      this.discountAppliedBlk = false;  
    } else {
      console.log("else");
      this.discountBlk = true;
      this.discountAppliedBlk = true;

      this.discountAmount = sessionStorage.getItem('discountAmount');
      this.beforeCouponFinalAmount = sessionStorage.getItem('beforeCouponFinalAmount');

      //this.amountPaybleToDriver = this.amountPaybleToDriver - this.discountAmount;
    }
  }

  userWalletAmountRes: any=[];
  getUserWalletAmountFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      userId : sessionStorage.getItem('userId'),
    };
    console.log(this.requestData);

    this.ows.getUserWalletAmount(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.userWalletAmountRes = data;
      console.log("userWalletAmountRes :: "+JSON.stringify(this.userWalletAmountRes));
      //this.Message = this.userWalletAmountRes.message;

      if(this.userWalletAmountRes.status == 1){
        sessionStorage.setItem('userWalletAmount',this.userWalletAmountRes.wallteAmount);
      }
      // else {}

    },error =>{
      console.log("userWalletAmountRes Error :",error);
    });

  }

  fullWalletPayment;
  walletChecked;
  onWalletClick(event: Event,amountPaybleNow) {
    // You can access the checkbox element and its state like this:
    const checkbox = event.target as HTMLInputElement;
    if(checkbox.checked) {
      
      console.log('Checkbox is checked');
      sessionStorage.setItem('walletChecked','1');
      
      window.location.reload();

      // this.amountPaybleNow = parseInt(amountPaybleNow) - parseInt(this.userWalletAmount);
      // //this.walletChecked = true;

      // console.log(this.amountPaybleNow);
      // //return false;

      // if(this.amountPaybleNow < 0){

      //   this.userWalletAmount = Math.abs(this.amountPaybleNow);

      //   this.amountPaybleNow = 0;
      //   sessionStorage.setItem('amountPaybleNow','0');
      //   sessionStorage.setItem('fullWalletPayment','1');
      //   this.fullWalletPayment = true;
      // } else {
        
      //   this.userWalletAmount = 0;

      //   sessionStorage.setItem('amountPaybleNow',this.amountPaybleNow); 
      //   sessionStorage.setItem('fullWalletPayment','0');
      //   this.fullWalletPayment = false;
      // }  

    } else {
      
      console.log('Checkbox is unchecked');
      sessionStorage.setItem('walletChecked','0');

      window.location.reload();

      // console.log(typeof amountPaybleNow);
      // console.log(typeof this.userWalletAmount);
      // this.fullWalletPayment = false;

      // console.log(parseInt(amountPaybleNow));

      // this.userWalletAmount = sessionStorage.getItem('userWalletAmount');
      // this.amountPaybleNow = sessionStorage.getItem('amountPaybleNow'); 
      // //this.amountPaybleNow = parseInt(amountPaybleNow) + parseInt(this.userWalletAmount);

      // sessionStorage.setItem('amountPaybleNow',this.amountPaybleNow);
      // sessionStorage.setItem('fullWalletPayment','0');

    }
  }

  requestData: any ={};
  webAccessKeyRes: any =[];
  webAccessKey: any;
  getWebAccessKeyFun(){

    this.requestData = {
      companyName : this.apiProperties.webCompanyName,
      clientID : this.apiProperties.webClientID,
      clientSecret : this.apiProperties.webClientSecret,
    };
    //console.log(this.requestData);

    this.ows.getWebAccessKey(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webAccessKeyRes = data;
      this.webAccessKey = this.webAccessKeyRes.accessKey;
      //console.log("webAccessKey :", this.webAccessKey);

      if(this.webAccessKeyRes.status == 1){
        this.getUserWalletAmountFun();
        this.getListCouponCodeFun();
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("webAccessKeyRes error :",error);
    });

  }

  listCouponCodeRes: any=[];
  getListCouponCodeFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      userId : sessionStorage.getItem('userId')
    };
    //console.log(this.requestData);

    this.ows.getListCouponCode(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.listCouponCodeRes = data;
      console.log(this.listCouponCodeRes);

      if(this.listCouponCodeRes.status == 1){
        //this.webOnewayDetailFun(this.pickupDate);
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("listCouponCodeRes error :",error);
    });

  }

  validateCouponCodeRes: any=[];
  discountAmount: any;
  beforeCouponFinalAmount: any;
  discountAppliedBlk: any;
  basePlusTax: any;
  validateCouponCodeFun(couponCode){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      userId : sessionStorage.getItem('userId'),
      bookId : sessionStorage.getItem('bookingId'),
      fareId : sessionStorage.getItem('fareId'),
      tripType : "1",
      finalAmount : "",
      couponCode : couponCode
    };
    console.log(this.requestData);
    //return false;

    this.ows.validateCouponCode(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.validateCouponCodeRes = data;
      console.log(this.validateCouponCodeRes);
      //return false;

      if(this.validateCouponCodeRes.status == 1){

        this.discountAmount = this.validateCouponCodeRes.discountAmount;
        this.beforeCouponFinalAmount = this.validateCouponCodeRes.beforeCouponFinalAmount;
        
        //this.amountPaybleNow = this.validateCouponCodeRes.bookingTotalAmount;

        this.baseFare = this.validateCouponCodeRes.bookingFareBreakup['Base Fare'];
        sessionStorage.setItem('baseFare',this.baseFare);

        this.tollTaxFull = parseInt(this.tollTax+this.airportEntryCharge+this.interStatePermit);
        this.basePlusTax = parseInt(this.baseFare + this.tollTaxFull);
        this.gst = Math.ceil((this.basePlusTax * 5) / 100);
        sessionStorage.setItem('gst',this.gst);

        //this.totalAmountPayByCustomer = this.validateCouponCodeRes.bookingTotalAmount;
        this.totalAmountPayByCustomer = this.baseFare + this.tollTaxFull + this.gst;
        
        sessionStorage.setItem('couponCode',couponCode);
        sessionStorage.setItem('discountAmount',this.discountAmount);
        sessionStorage.setItem('beforeCouponFinalAmount',this.beforeCouponFinalAmount);
        sessionStorage.setItem('totalAmountPayByCustomer',this.totalAmountPayByCustomer);
        //sessionStorage.setItem('amountPaybleNow',this.amountPaybleNow);

        // console.log("beforeCouponFinalAmount :: "+this.beforeCouponFinalAmount);
        // console.log("totalAmountPayByCustomer :: "+this.totalAmountPayByCustomer);
        // console.log("amountPaybleNow ::"+this.amountPaybleNow);
        //return;
        
  
        // this.discountBlk = true;
        // this.discountAppliedBlk = true;

        // if(sessionStorage.getItem('isAssuredCab') == '1'){
 
        //   const amountPaybleNow = sessionStorage.getItem('amountPaybleNow');
 
        //   this.amountPaybleNow = amountPaybleNow;
        //   this.amountPaybleToDriver = this.totalAmountPayByCustomer - this.amountPaybleNow;
        // }

        // this.amountPaybleToDriver = 0;

        

      } else {

        sessionStorage.setItem('couponCode','');
        sessionStorage.setItem('discountAmount','');
        sessionStorage.setItem('beforeCouponFinalAmount','');

        // this.discountBlk = false;
        // this.discountAppliedBlk = false;
      }

      window.location.reload();
      $('#couponModal').modal('hide');

    },error =>{
      console.log("validateCouponCodeRes error :",error);
    });

  }

  removeCouponCodeRes: any=[];
  removeCouponCodeFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      userId : sessionStorage.getItem('userId'),
      bookId : sessionStorage.getItem('bookingId'),
      couponCode : sessionStorage.getItem('couponCode')
    };
    console.log(this.requestData);
    //return false;

    this.ows.removeCouponCode(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.removeCouponCodeRes = data;
      console.log(this.removeCouponCodeRes);
      //return false;

      if(this.removeCouponCodeRes.status == 1){
        
        //this.discountAmount = this.removeCouponCodeRes.discountAmount;
        //this.beforeCouponFinalAmount = this.removeCouponCodeRes.beforeCouponFinalAmount;
        //this.amountPaybleNow = this.removeCouponCodeRes.bookingTotalAmount;

        this.baseFare = this.removeCouponCodeRes.bookingFareBreakup['Base Fare'];
        sessionStorage.setItem('baseFare',this.baseFare);

        this.tollTaxFull = parseInt(this.tollTax+this.airportEntryCharge+this.interStatePermit);
        this.basePlusTax = this.baseFare + this.tollTaxFull;
        this.gst = Math.ceil((this.basePlusTax * 5) / 100);
        sessionStorage.setItem('gst',this.gst);

        //this.totalAmountPayByCustomer = this.removeCouponCodeRes.bookingTotalAmount;
        this.totalAmountPayByCustomer = this.baseFare + this.tollTaxFull + this.gst;

        sessionStorage.setItem('couponCode','');
        sessionStorage.setItem('discountAmount','');
        //sessionStorage.setItem('beforeCouponFinalAmount','');
        sessionStorage.setItem('totalAmountPayByCustomer',this.totalAmountPayByCustomer);
        //sessionStorage.setItem('amountPaybleNow',this.amountPaybleNow);

        this.discountBlk = false;
        this.discountAppliedBlk = false;
 
      } else { }

      window.location.reload();

    },error =>{
      console.log("removeCouponCodeRes error :",error);
    });

  }

  // SampleDataGenerate(){
  //   this.sampleData = [
  //     {
  //       "Title": "Downtown Express Discount",
  //       "Description": "Get 20% off on rides to downtown areas.",
  //       "CouponCode": "DTX20",
  //       "CouponDetails": "Valid for rides to designated downtown locations. 20% discount applied to total fare."
  //     },
  //     {
  //       "Title": "Airport Transfer Special",
  //       "Description": "Flat $10 off on rides to the airport.",
  //       "CouponCode": "FLY10",
  //       "CouponDetails": "Applicable for rides to the airport. $10 discount will be automatically deducted from the fare."
  //     },
  //     {
  //       "Title": "Weekend Getaway Deal",
  //       "Description": "Save 15% on rides for weekend trips outside the city.",
  //       "CouponCode": "WEEKEND15",
  //       "CouponDetails": "Valid for rides to destinations outside the city limits on weekends. Discount will be applied at checkout."
  //     },
  //     {
  //       "Title": "City Explorer Pass",
  //       "Description": "Buy 2 rides, get 1 ride free for sightseeing within the city.",
  //       "CouponCode": "EXPLORE",
  //       "CouponDetails": "Purchase 2 rides and use the code to get the 3rd ride free. Perfect for exploring various attractions in the city."
  //     },
  //     {
  //       "Title": "Late Night Special",
  //       "Description": "50% off on rides after 10:00 PM.",
  //       "CouponCode": "LATE50",
  //       "CouponDetails": "Valid for rides taken after 10:00 PM. 50% discount applied during late-night hours."
  //     }
  //   ];
  // }

  toggleDetails(discount: any) {
    discount.showDetails = !discount.showDetails;
  }

  // onWalletClick(event: Event,amountPaybleNow) {
  //   // You can access the checkbox element and its state like this:
  //   const checkbox = event.target as HTMLInputElement;
  //   if (checkbox.checked) {
      
  //     console.log('Checkbox is checked');

  //     this.amountPaybleNow = parseInt(amountPaybleNow) - parseInt(this.userWalletAmount);
  //     //this.walletChecked = true;

  //     console.log(this.amountPaybleNow);
  //     //return false;

  //     if(this.amountPaybleNow < 0){

  //       this.userWalletAmount = Math.abs(this.amountPaybleNow);

  //       this.amountPaybleNow = 0;
  //       sessionStorage.setItem('amountPaybleNow','0');
  //       sessionStorage.setItem('fullWalletPayment','1');
  //       this.fullWalletPayment = true;
  //     } else {
        
  //       this.userWalletAmount = 0;

  //       sessionStorage.setItem('amountPaybleNow',this.amountPaybleNow); 
  //       sessionStorage.setItem('fullWalletPayment','0');
  //       this.fullWalletPayment = false;
  //     }

  //     sessionStorage.setItem('walletChecked','1');   

  //   } else {
      
  //     console.log('Checkbox is unchecked');

  //     // console.log(typeof amountPaybleNow);
  //     // console.log(typeof this.userWalletAmount);
  //     this.fullWalletPayment = false;

  //     console.log(parseInt(amountPaybleNow));

  //     this.userWalletAmount = sessionStorage.getItem('userWalletAmount');
  //     this.amountPaybleNow = sessionStorage.getItem('amountPaybleNow'); 
  //     //this.amountPaybleNow = parseInt(amountPaybleNow) + parseInt(this.userWalletAmount);

  //     sessionStorage.setItem('amountPaybleNow',this.amountPaybleNow);
  //     sessionStorage.setItem('walletChecked','0');
  //     sessionStorage.setItem('fullWalletPayment','0');
  //   }
  // }

  inputText: string = '';
  saveText() {
    // You can implement your save logic here
    console.log("Saved text:", this.inputText);
    //this.inputText = ''; // Clear the input after saving

    this.validateCouponCodeFun(this.inputText);
  }

  isWalletUsed: any;
  redeemAmount: any;
  walletType: any;
  updateWebPaymentTypeRes: any=[];
  updateWebPaymentTypeFun(paymentType){

    if(sessionStorage.getItem('walletChecked') == '1'){
      this.walletType = 1;
      this.isWalletUsed = 1;
      if(paymentType == '3'){
        this.redeemAmount = this.totalAmountPayByCustomer;
        if(sessionStorage.getItem('flexiSaver') == "saverPart"){
          this.redeemAmount = this.saverPartialAmountPayFull;
        }
        //console.log("if");
      } else {
        this.redeemAmount = sessionStorage.getItem('userWalletAmount');
        //console.log("else");
      }
      
    }else{
      this.walletType = 0;
      this.isWalletUsed = 0;
      this.redeemAmount = 0;
    }

    //console.log(sessionStorage.getItem('flexiSaver'));
    //console.log(this.saverPartialAmountPayFull);

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      userId : sessionStorage.getItem('userId'),
      bookId : sessionStorage.getItem('bookingId'),
      paymentType : paymentType,
      walletType : this.walletType,
      isWalletUsed : this.isWalletUsed,
      redeemAmount : this.redeemAmount
    };
    console.log(this.requestData);
    //return false;

    this.ows.updateWebPaymentType(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.updateWebPaymentTypeRes = data;
      console.log(this.updateWebPaymentTypeRes);

      if(this.updateWebPaymentTypeRes.status == 1){
        this.webWalletRedeemStatusFun();
      } else {
        //alert();
      }

    },error =>{
      console.log("updateWebPaymentTypeRes error :",error);
    });

  }

  webWalletRedeemStatusRes: any =[];
  webWalletRedeemStatusFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      bookId : sessionStorage.getItem('bookingId')
    };
    console.log(this.requestData);

    this.ows.webWalletRedeemStatus(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.webWalletRedeemStatusRes = data;
      console.log(this.webWalletRedeemStatusRes);

      if(this.webWalletRedeemStatusRes.status == 1){
        this.router.navigate(['/tripInfo']);
      } else {}

    },error =>{
      console.log("webWalletRedeemStatusRes error :",error);
    });

  }

  paymentGatewayRedirect(enforcePaymethod){
    // const url = "http://localhost/onewaycab/payuNg/payuNg.php";
    // this.router.navigate([url]);

    // console.log(sessionStorage.getItem('displayBookingId'));
    // return false;

    // 50102201

    // console.log(this.amountPaybleNow);
    // return false;

    if(sessionStorage.getItem('walletChecked') == '1'){
      this.walletType = 1;
      this.isWalletUsed = 1;
      this.redeemAmount = sessionStorage.getItem('userWalletAmount');
      //this.walletUsed = 1;
    }else{
      this.walletType = 0;
      this.isWalletUsed = 0;
      this.redeemAmount = 0;
    }

    console.log(this.redeemAmount);
    console.log(this.walletType);

    // Required to call updateWebPaymentTypeFun() if online+wallet | cash+wallet booking 

    const bid = btoa(sessionStorage.getItem('displayBookingId'));
    const uid = btoa(sessionStorage.getItem('userId'));
    const amt = btoa(this.amountPaybleNow);
    const enfp = btoa(enforcePaymethod);
    const wta = btoa(this.redeemAmount);
    const wu = btoa(this.walletType);

    
    window.location.href = this.apiProperties.siteurlPHP+"/payuNg/payuNg.php?bid="+bid+"&uid="+uid+"&amt="+amt+"&wta="+wta+"&wu="+wu+"&enfp="+enfp;
    //window.open(this.apiProperties.siteurlPHP+"/payuNg/payuNg.php?bid="+bid+"&uid="+uid+"&amt="+amt+"&wta="+wta+"&wu="+wu+"&enfp="+enfp,'_blank');

  }

}