<div class="container">
<div *ngIf="fdFirstRideBlk" class="row mt-2">

    <div class="col-md-4 col-12 mb-2">
        <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
            <div class="card-body">
                <p class="mb-0"><b>Booking ID: </b>{{this.bookingFeedbackInfoRes.displayBookId}}</p>
            </div>
        </div>
    </div>

    <!-- <div class="col-md-4 col-12">
        <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
            <div class="card-body">
                <p class="mb-0"><b>Trip Type: </b>{{this.bookingFeedbackInfoRes.tripType}}</p>
            </div>
        </div>
    </div> -->

    <div class="col-md-4 col-12 mb-2">
        <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none;">
            <div class="card-body">
                <p class="mb-0"><b>Route: </b>{{this.bookingFeedbackInfoRes.route}}</p>
            </div>
        </div>
    </div>

</div>

<div *ngIf="fdFirstRideBlk" class="row mb-2">

    <div class="col-md-12 col-12">
        <div class="card" style="box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%); border: 0 none; border-radius: 5px;">
            <div class="card-body">
    
                <div class="row">
    
                    <div class="col-md-12 col-12 mt-3">
                        <p>Thanks for trusting OneWay.Cab for your travel.</p>
                    </div>
                
                    <div class="col-md-12 col-12 mt-3">
                        <p>What did you like about us ?</p>
                
                        <div class="row">
                            <div class="col-md-8 col-12 d-flex flex-wrap">
                                <div *ngFor="let data of fdfrReasonList; index as i">
                                    <button id="{{data.feedbackServiceReasonId}}fdfr" 
                                            class="btn btn-outline-primary mr-2 mt-2"
                                            (click)="feedbackReasonFun(data.feedbackServiceReasonId,'fdfr')">
                                        {{data.feedbackServiceReason}}
                                    </button>
                                </div> 
                                <button type="button" class="btn btn-outline-warning mr-2 mt-2"
                                        id="1fdfr"
                                        (click)="feedbackReasonFun(1,'fdfr')">
                                        Other
                                </button>

                                <!-- <button type="button" class="btn btn-outline-warning mr-2 mt-2"
                                        id="fdfrMainOther"
                                        (click)="otherBtnFun('fdfrMainOther')">
                                        Other
                                </button> -->
                            </div>

                            <div class="col-md-4 col-12" *ngIf="fdfrMainInt">
                                <input type="text" id="fdfrMainInt" 
                                       class="form-control mt-2" 
                                       placeholder="Enter Remarks" value="">
                            </div>

                        </div>
                
                    </div>

                    <div class="col-md-12 col-12 mt-3">
                        <p>Where did you come to know About us?</p>
                
                        <div class="row">

                            <div class="col-md-8 col-12 d-flex flex-wrap">

                                <div *ngFor="let gData of fdGoogleReasonList; index as i">
                                    <button id="{{gData.feedbackServiceReasonId}}fdg" 
                                            class="btn btn-outline-primary mr-2 mt-2"
                                            (click)="fdGoogleReasonFun(gData.feedbackServiceReasonId,'fdg')">
                                        {{gData.feedbackServiceReason}}
                                    </button>
                                </div>
                                
                                <button type="button" class="btn btn-outline-primary mr-2 mt-2"
                                        id="1fdg"
                                        (click)="fdGoogleReasonFun(1,'fdg')">
                                        other
                                </button>

                            </div>

                            <div class="col-md-4 col-12" *ngIf="fdfrOtherInt">
                                <input type="text" id="fdfrOtherInt" 
                                       class="form-control mt-2" 
                                       placeholder="Enter Reference Remarks" value="">
                            </div>

                        </div>
                
                    </div>
                
                
                    <div class="col-md-12 col-12 text-right mt-3">
                        <button class="btn btn-warning" (click)="insertFirstRideFeedbackFun()">
                            Submit
                        </button>
                    </div>
    
                </div>
    
            </div>
        </div>
    </div>
</div>

<div *ngIf="fdFirstRideErrorBlk" class="row mt-3 mb-3">

    <div class="col-md-12 col-12">
        <div class="alert alert-success">{{this.feedbackMessage}}</div>
    </div>
    
</div>
</div>