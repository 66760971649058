export class APiProperties {  
    
    siteurlAngular: any;
    siteurlPHP: any;
    
    webCompanyName: any;
    webClientID: any;
    webClientSecret: any;
    
    nodeOnewayClientID: any;
    nodeOnewayClientSecret: any;

    webApiURL: any;
    nodeOnewayApiURL: any;

    onewayPartnerEnrolApiURL: any;
    onewayCustomerAddressApiUrl: any;

    googleKey: any;
    //googleKeyStaging = "AIzaSyA94JCjK3FoX6I735Ls1gD4AjEUbtJ_37E";

    constructor() {

        if((window.location.hostname == 'stagingmy.oneway.cab') || (window.location.hostname == 'localhost')){
        //if((window.location.hostname == 'my.oneway.cab')){

            this.siteurlAngular = "https://stagingmy.oneway.cab";
            this.siteurlPHP = "https://stagingweb.oneway.cab";
            
            this.googleKey = 'AIzaSyBeFU4DH73-WAD-gap3ffMdPhZj5T-Viuo';
            
            if(window.location.hostname == 'localhost'){
                this.siteurlPHP = "http://localhost/onewaycab";
                this.siteurlAngular = "http://localhost:4201";
            }
            
            this.webCompanyName = "Web";
            this.webClientID = "mobapp";
            this.webClientSecret = "mobappsecret";
            
            this.nodeOnewayClientID = "mobapp";
            this.nodeOnewayClientSecret = "mobappsecret";

            this.webApiURL = 'https://stagingwebapi.oneway.cab/third/';
            this.nodeOnewayApiURL = "https://stagingnode.oneway.cab/";

            this.onewayPartnerEnrolApiURL = "https://stagingpartnerenrolapi.oneway.cab/rest/V1/";
            this.onewayCustomerAddressApiUrl = "https://stagingcustomeraddress.oneway.cab/rest/";

        } else {

            this.siteurlAngular = "https://my.oneway.cab";
            this.siteurlPHP = "https://oneway.cab";

            this.googleKey = 'AIzaSyBeFU4DH73-WAD-gap3ffMdPhZj5T-Viuo';

            this.webCompanyName = "Web";
            this.webClientID = "webapp";
            this.webClientSecret = "XTdI790c598u21C";

            this.nodeOnewayClientID = "LiveRH8FeT7Atz";
            this.nodeOnewayClientSecret = "LivejtefdfgvNYb56qAH";

            this.webApiURL = "https://webapi.oneway.cab/third/";
            this.nodeOnewayApiURL = "https://node.oneway.cab/";

            this.onewayPartnerEnrolApiURL = "https://partnerenrolapi.oneway.cab/rest/V1/";
            this.onewayCustomerAddressApiUrl = "https://customeraddress.oneway.cab/rest/";
        }

    }    
}