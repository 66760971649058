import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { APiProperties } from '../../../app/class/api-properties';
import { OnewayWebapiService } from '../../service/oneway-webapi.service';
import { GoogleService } from '../../service/google.service';

interface City {
  name: string;
  description: string;
}

@Component({
  selector: 'app-visitor-address',
  templateUrl: './visitor-address.component.html',
  styleUrls: ['./visitor-address.component.css']
})
export class VisitorAddressComponent implements OnInit {
  apiProperties : APiProperties = new APiProperties();
  @Input() webAccessKey;
  @Input() visitorCityName;
  @Output('visitorAddressHide') visitorAddressHide = new EventEmitter();
  @ViewChild('visitorAddressCmp') visitorAddressCmp: ElementRef;
  
  constructor( private fb: FormBuilder, 
               private route: ActivatedRoute,
               private ows: OnewayWebapiService, 
               private gs: GoogleService,
               private titleService: Title,  
               private metaTagService: Meta) { }

  ngAfterViewInit() {
    const inputElement = document.querySelector('.search-input') as HTMLInputElement;
    inputElement.setAttribute('autocomplete', 'new-password'); // Use a non-standard value
  }

  carType: any;
  placeHolderTitle: any;
  ngOnInit(): void {
    this.placeHolderTitle = "Search Address";
    //this.placeHolderTitle = "Search Address in "+this.visitorCityName;
    console.log("Search Address in "+this.visitorCityName);
    this.getAddressListFun();
  }

  requestData: any={};
  getAddressListRes: any=[];
  listofAddressData: any=[];
  listofAddressDataBlk;
  getAddressListFun(){

    this.requestData = {
      userName : this.apiProperties.webCompanyName,
      accessKey : this.webAccessKey,
      cityName : this.visitorCityName,
	    mobileNo : sessionStorage.getItem('mobileNo')
    };
    console.log(this.requestData);

    this.ows.getAddressList(JSON.stringify(this.requestData)).subscribe((data : {})=>{
      this.getAddressListRes = data;
      
      if(this.getAddressListRes.status == 1){
        this.listofAddressData = this.getAddressListRes.listofAddress;
        console.log(this.listofAddressData.length);
        this.listofAddressDataBlk = true;

        this.visitorAddressCmp.nativeElement.focus();
      } else {
        //this.displayBookingDetails = false;
      }

    },error =>{
      console.log("getAddressListRes error :",error);
    });

  }

  searchResults: City[] = [];
  searchTerm: string = '';
  search() {
    if(this.getAddressListRes.status == 0){
      const searchTermVal = this.searchTerm.toLowerCase();
      this.pickupAreaSearch(searchTermVal);
      this.listofAddressDataBlk = false;
    } else {
    this.listofAddressData = this.getAddressListRes.listofAddress.filter(item =>
      item.pickupAddress.toLowerCase().includes(this.searchTerm.toLowerCase())
    );

    //console.log(this.listofAddressData.length);

    if(this.listofAddressData.length < 1){
      console.log(this.listofAddressData.length);
      //console.log(this.searchTerm.toLowerCase());
      const searchTermVal = this.searchTerm.toLowerCase();
      
      this.pickupAreaSearch(searchTermVal);

      this.listofAddressDataBlk = false;
    } else {
      this.listofAddressDataBlk = true;
      this.listofGoogleDataBlk = false;
    }
  
    }  

  }

  searchText: any;
  googleAutoRes: any=[];
  listofGoogleDataBlk;
  pickupAreaSearch(searchTermVal){
    //console.log(x.target.value); 
    //this.searchText = searchText.target.value;

    this.searchText = searchTermVal;
    const location = sessionStorage.getItem('visitorCityLat')+","+sessionStorage.getItem('visitorCityLat');
    
    return this.gs.googlePlaceAutoCompleteWithoutLatLong(this.searchText,location).subscribe((data : {}) => {
      this.googleAutoRes = data;
      // console.log("areaSearch:"+JSON.stringify(this.googleAutoRes));
      // console.log(this.googleAutoRes.predictions[0].place_id);

      this.listofGoogleDataBlk = true;
      
    },error => console.log(error));

  }

  paceId: any;
  googlePlaceDetailRes: any=[];
  googlePlaceDetailFun(place_id,description,main_text,secondary_text){
    
    //console.log(x.target.value); 
    //this.searchText = searchText.target.value;
    this.paceId = place_id;
    //console.log("paceId :: "+this.paceId);
    
    return this.gs.googlePlaceDetail(this.paceId).subscribe((data : {}) => {
      this.googlePlaceDetailRes = data;
      // console.log("googlePlaceDetail:"+JSON.stringify(this.googlePlaceDetailRes));
      // console.log(this.googlePlaceDetailRes.result.geometry.location);
      const locationLatLng = this.googlePlaceDetailRes.result.geometry.location;

      const pickupData = {
        'pickupAddress' : description,
        'pickCityLat' : locationLatLng.lat,
        'pickCityLong' : locationLatLng.lng
      }

      this.savePickupData(pickupData);

      //this.saveAddressMasterFun(description,main_text,secondary_text,locationLatLng);
      
    },error => console.log(error));

  }


  savePickupData(pickupData){

    console.log(pickupData.pickupAddress);
    console.log(pickupData.pickCityLat);
    console.log(pickupData.pickCityLong);
    //return false;

    this.visitorAddressHide.emit({
      'pickupAddress' : pickupData.pickupAddress,
      'pickupAddressLat' : pickupData.pickCityLat,
      'pickupAddressLong' : pickupData.pickCityLong
    });
  }

  goBack(){
    this.visitorAddressHide.emit({
      'pickupAddress' : '',
      'pickupAddressLat' : '',
      'pickupAddressLong' : ''
    });
  }

}
